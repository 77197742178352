<template>
  <component-page :code-view="codeView">
    <template #description>
      Поле для свободного ввода любых текстовых и числовых данных
    </template>
    <template #component>
      <r-input
        :key="props.focusLabel"
        v-model="model"
        v-bind="props"
      />
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="readonly"
          v-model="props.readonly"
        />
        <r-switcher
          title="isClear"
          v-model="props.isClear"
        />
        <r-switcher
          title="rounded"
          v-model="props.rounded"
        />
        <r-switcher
          title="error"
          v-model="props.error"
        />
        <r-switcher
          title="focusLabel"
          v-model="props.focusLabel"
        />
        <r-switcher
          title="isClearModelString"
          v-model="props.isClearModelString"
        />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Type"
            v-model="props.type"
            :items="dataType"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Size"
            v-model="props.size"
            :items="dataSize"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="TypeInput"
            v-model="props.typeInput"
            :items="dataTypeInput"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="BeforeIcon"
            :icon="props.beforeIcon"
            v-model="props.beforeIcon"
            :items="dataIcons"
            is-search
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="AfterIcon"
            :icon="props.afterIcon"
            v-model="props.afterIcon"
            :items="dataIcons"
            is-search
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Label"
            v-model="props.label"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="ErrorMessage"
            v-model="props.errorMessage"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="AutocompleteInput"
            v-model="props.autocompleteInput"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="maxLength"
            typeInput="number"
            v-model="props.maxLength"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
    </template>
    <template #examples>
      <InputPhone />
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';
import InputPhone from '@/components/examples/Input/InputPhone.vue';

@Component({
  components: { InputPhone, ComponentPage },
})
export default class Input extends Vue {
  model = null

  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">val</span>',
      typeData: '<span class="matrix--text">string | number</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Модель компонента',
    },
    {
      nameProp: '<span class="rocky--text">type</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"default"</span>',
      description: 'Тип отображения компонента',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"eluno"</span>',
      description: 'Размер компонента',
    },
    {
      nameProp: '<span class="rocky--text">label</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Описание поля',
    },
    {
      nameProp: '<span class="rocky--text">typeInput</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"text"</span>',
      description: 'Тип поля input',
    },
    {
      nameProp: '<span class="rocky--text">maxLength</span>',
      typeData: '<span class="matrix--text">string | number</span>',
      defaultValue: '<span class="matrix--text">undefined</span>',
      description: 'Максимальное количество символов для ввода',
    },
    {
      nameProp: '<span class="rocky--text">autocompleteInput</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="rocky--text">"new-password"</span>',
      description: 'автоподставление браузерное для поля ввода',
    },
    {
      nameProp: '<span class="rocky--text">readonly</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Блокировка компонента',
    },
    {
      nameProp: '<span class="rocky--text">isClear</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">true</span>',
      description: 'Кнопка действия для очистки модели',
    },
    {
      nameProp: '<span class="rocky--text">beforeIcon</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Название иконки для вывода слева от области ввода',
    },
    {
      nameProp: '<span class="rocky--text">afterIcon</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Название иконки для вывода справа от области ввода',
    },
    {
      nameProp: '<span class="rocky--text">error</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Входной параметр для определение ошибки валидации и подсветка компонента',
    },
    {
      nameProp: '<span class="rocky--text">errorMessage</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Текст сообщения о ошибки',
    },
    {
      nameProp: '<span class="rocky--text">rounded</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Скругление контента компонента',
    },
    {
      nameProp: '<span class="rocky--text">focusLabel</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Автоматически фокуситься в input',
    },
    {
      nameProp: '<span class="rocky--text">isClearModelString</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Возврат пустую строку вместо null',
    },
    {
      nameProp: '<span class="rocky--text">isTabIndex</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">true</span>',
      description: 'Флаг для отработки табуляции или отключение контрола для клавиатурного поиска',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">focus</span>',
      description: 'Событие при фокусе на компонент',
    },
    {
      nameEvent: '<span class="rocky--text">blur</span>',
      description: 'Событие при потери фокуса',
    },
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Событие возвращает введеное значение',
    },
    {
      nameEvent: '<span class="rocky--text">eventBeforeIcon</span>',
      description: 'Возвращает события клика по левой иконки',
    },
    {
      nameEvent: '<span class="rocky--text">eventAfterIcon</span>',
      description: 'Возвращает события клика по правой иконки',
    },
    {
      nameEvent: '<span class="rocky--text">clearModel</span>',
      description: 'Возвращает события клика по кнопке очистки поля',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">content</span>',
      description: 'Слот для контенте слева от поля ввода',
    },
    {
      nameSlot: '<span class="rocky--text">after</span>',
      description: 'Слот для контенте слева от кнопки очистки или иконки',
    },
  ]

  props = {
    type: 'default',
    label: 'label',
    typeInput: 'text',
    maxLength: null,
    readonly: false,
    isClear: true,
    beforeIcon: null,
    afterIcon: null,
    error: false,
    errorMessage: null,
    rounded: false,
    autocompleteInput: 'new-password',
    size: 'eluno',
    focusLabel: false,
    isClearModelString: true,
  }

  dataType = [
    {
      id: 'default',
      title: 'default',
    },
    {
      id: 'map',
      title: 'map',
    },
  ]

  dataSize = [
    {
      id: 'eluno',
      title: 'eluno',
    },
    {
      id: 'lanassa',
      title: 'lanassa',
    },
  ]

  dataTypeInput = [
    {
      id: 'text',
      title: 'text',
    },
    {
      id: 'password',
      title: 'password',
    },
    {
      id: 'number',
      title: 'number',
    },
  ]

  // eslint-disable-next-line class-methods-use-this
  get dataIcons() {
    return [{
      id: null,
      title: 'Без иконки',
      icon: 'block',
    },
    ...getIconName().map((el) => ({
      id: el,
      title: el,
      icon: el,
    }))];
  }

  get codeView() {
    return generateCodeToString('input', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
