<template>
  <component-page :code-view="codeView">
    <template #description>
      Иконки
    </template>
    <template #component>
      <r-color-picker
        v-model="model"
        v-bind="props"
      />
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
          <r-switcher
            title="isClear"
            v-model="props.isClear"
          />
          <r-switcher
            title="disabled"
            v-model="props.disabled"
          />
          <r-switcher
            title="error"
            v-model="props.error"
          />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="Title"
            v-model="props.title"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class ColorPicker extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">null</span>',
      description: 'Модель компонента',
    },
    {
      nameProp: '<span class="rocky--text">typeModel</span>',
      typeData: '<span class="matrix--text">hex | name</span>',
      defaultValue: '<span class="alien--text">name</span>',
      description: 'Какой тип данных для цвета будет возвращаться в модель',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">null</span>',
      description: 'Титл для описание контролера',
    },
    {
      nameProp: '<span class="rocky--text">isClear</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Включить возможность сброса цвета',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Блокировка компонента',
    },
    {
      nameProp: '<span class="rocky--text">error</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Состояние ошибки при валидации компонента',
    },
  ]

  model = null

  props = {
    title: null,
    isClear: false,
    disabled: false,
    error: false,
  }

  get codeView() {
    return generateCodeToString('color-picker', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
