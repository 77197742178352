<template>

  <div class="pt-2 component">
    <r-row class="mb-6">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="parmigiano troy--text">
          Отступы между элементами интерфейса задаются количеством микромодулей равных 4&nbsp;px.
        </span>
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <svg width="100%" height="97" viewBox="0 0 621 97" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMid meet">
          <rect width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="155.25" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="155.25" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="155.25" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="155.25" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="155.25" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="77.625" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="77.625" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="77.625" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="77.625" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="77.625" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="232.875" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="232.875" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="232.875" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="232.875" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="232.875" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="38.8125" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="38.8125" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="38.8125" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="38.8125" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="38.8125" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="194.062" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="194.062" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="194.062" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="194.062" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="194.062" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="116.438" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="116.438" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="116.438" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="116.438" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="116.438" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="271.688" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="271.688" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="271.688" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="271.688" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="271.688" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="19.4062" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="19.4062" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="19.4062" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="19.4062" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="19.4062" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="174.656" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="174.656" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="174.656" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="174.656" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="174.656" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="97.0312" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="97.0312" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="97.0312" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="97.0312" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="97.0312" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="252.281" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="252.281" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="252.281" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="252.281" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="252.281" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="58.2188" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="58.2188" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="58.2188" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="58.2188" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="58.2188" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="213.469" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="213.469" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="213.469" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="213.469" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="213.469" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="135.844" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="135.844" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="135.844" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="135.844" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="135.844" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="291.094" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="291.094" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="291.094" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="291.094" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="291.094" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="9.70312" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="9.70312" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="9.70312" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="9.70312" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="9.70312" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="164.953" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="164.953" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="164.953" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="164.953" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="164.953" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="87.3281" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="87.3281" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="87.3281" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="87.3281" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="87.3281" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="242.578" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="242.578" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="242.578" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="242.578" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="242.578" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="48.5156" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="48.5156" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="48.5156" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="48.5156" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="48.5156" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="203.766" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="203.766" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="203.766" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="203.766" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="203.766" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="126.141" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="126.141" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="126.141" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="126.141" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="126.141" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="281.391" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="281.391" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="281.391" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="281.391" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="281.391" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="29.1094" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="29.1094" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="29.1094" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="29.1094" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="29.1094" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="184.359" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="184.359" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="184.359" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="184.359" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="184.359" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="106.734" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="106.734" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="106.734" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="106.734" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="106.734" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="261.984" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="261.984" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="261.984" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="261.984" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="261.984" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="67.9219" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="67.9219" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="67.9219" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="67.9219" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="67.9219" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="223.172" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="223.172" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="223.172" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="223.172" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="223.172" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="145.547" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="145.547" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="145.547" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="145.547" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="145.547" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="300.797" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="300.797" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="300.797" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="300.797" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="300.797" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="155.25" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="155.25" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="155.25" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="155.25" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="155.25" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="77.625" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="77.625" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="77.625" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="77.625" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="77.625" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="232.875" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="232.875" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="232.875" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="232.875" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="232.875" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="38.8125" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="38.8125" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="38.8125" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="38.8125" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="38.8125" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="194.062" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="194.062" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="194.062" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="194.062" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="194.062" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="116.438" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="116.438" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="116.438" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="116.438" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="116.438" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="271.688" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="271.688" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="271.688" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="271.688" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="271.688" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="19.4062" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="19.4062" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="19.4062" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="19.4062" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="19.4062" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="174.656" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="174.656" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="174.656" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="174.656" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="174.656" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="97.0312" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="97.0312" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="97.0312" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="97.0312" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="97.0312" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="252.281" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="252.281" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="252.281" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="252.281" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="252.281" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="58.2188" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="58.2188" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="58.2188" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="58.2188" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="58.2188" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="213.469" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="213.469" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="213.469" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="213.469" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="213.469" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="135.844" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="135.844" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="135.844" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="135.844" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="135.844" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="291.094" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="291.094" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="291.094" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="291.094" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="291.094" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="9.70312" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="9.70312" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="9.70312" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="9.70312" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="9.70312" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="164.953" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="164.953" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="164.953" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="164.953" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="164.953" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="87.3281" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="87.3281" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="87.3281" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="87.3281" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="87.3281" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="242.578" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="242.578" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="242.578" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="242.578" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="242.578" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="48.5156" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="48.5156" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="48.5156" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="48.5156" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="48.5156" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="203.766" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="203.766" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="203.766" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="203.766" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="203.766" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="126.141" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="126.141" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="126.141" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="126.141" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="126.141" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="281.391" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="281.391" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="281.391" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="281.391" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="281.391" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="29.1094" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="29.1094" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="29.1094" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="29.1094" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-rocky)"/>
          <rect x="29.1094" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="184.359" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="184.359" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="184.359" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="184.359" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="184.359" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="106.734" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="106.734" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="106.734" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="106.734" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="106.734" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="261.984" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="261.984" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="261.984" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="261.984" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="261.984" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="67.9219" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="67.9219" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="67.9219" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="67.9219" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="67.9219" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="223.172" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="223.172" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="223.172" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="223.172" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="223.172" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="145.547" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="145.547" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="145.547" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="145.547" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="145.547" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="300.797" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="300.797" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="300.797" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="300.797" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="300.797" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="310.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="310.5" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="310.5" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="310.5" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="310.5" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="465.75" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="465.75" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="465.75" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="465.75" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="465.75" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="388.125" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="388.125" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="388.125" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="388.125" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="388.125" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="543.375" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="543.375" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="543.375" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="543.375" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="543.375" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="349.312" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="349.312" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="349.312" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="349.312" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="349.312" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="504.562" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="504.562" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="504.562" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="504.562" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="504.562" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="426.938" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="426.938" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="426.938" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="426.938" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="426.938" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="582.188" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="582.188" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="582.188" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="582.188" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="582.188" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="329.906" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="329.906" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="329.906" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="329.906" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="329.906" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="485.156" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="485.156" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="485.156" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="485.156" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="485.156" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="407.531" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="407.531" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="407.531" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="407.531" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="407.531" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="562.781" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="562.781" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="562.781" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="562.781" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="562.781" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="368.719" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="368.719" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="368.719" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="368.719" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="368.719" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="523.969" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="523.969" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="523.969" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="523.969" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="523.969" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="446.344" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="446.344" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="446.344" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="446.344" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="446.344" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="601.594" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="601.594" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="601.594" y="38.8" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="601.594" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="601.594" y="58.2" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="320.203" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="320.203" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="320.203" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="320.203" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="320.203" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="475.453" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="475.453" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="475.453" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="475.453" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="475.453" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="397.828" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="397.828" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="397.828" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="397.828" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="397.828" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="553.078" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="553.078" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="553.078" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="553.078" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="553.078" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="359.016" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="359.016" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="359.016" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="359.016" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="359.016" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="514.266" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="514.266" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="514.266" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="514.266" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="514.266" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="436.641" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="436.641" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="436.641" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="436.641" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="436.641" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="591.891" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="591.891" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="591.891" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="591.891" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="591.891" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="339.609" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="339.609" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="339.609" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="339.609" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="339.609" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="494.859" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="494.859" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="494.859" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="494.859" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="494.859" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="417.234" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="417.234" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="417.234" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="417.234" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="417.234" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="572.484" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="572.484" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="572.484" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="572.484" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="572.484" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="378.422" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="378.422" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="378.422" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="378.422" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="378.422" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="533.672" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="533.672" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="533.672" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="533.672" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="533.672" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="456.047" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="456.047" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="456.047" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="456.047" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="456.047" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="611.297" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="611.297" y="77.6001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="611.297" y="38.8" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="611.297" y="19.3999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="611.297" y="58.2" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="310.5" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="310.5" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="310.5" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="310.5" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="310.5" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="465.75" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="465.75" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="465.75" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="465.75" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="465.75" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="388.125" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="388.125" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="388.125" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="388.125" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="388.125" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="543.375" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="543.375" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="543.375" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="543.375" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="543.375" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="349.312" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="349.312" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="349.312" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="349.312" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="349.312" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="504.562" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="504.562" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="504.562" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="504.562" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="504.562" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="426.938" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="426.938" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="426.938" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="426.938" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="426.938" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="582.188" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="582.188" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="582.188" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="582.188" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="582.188" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="329.906" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="329.906" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="329.906" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="329.906" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="329.906" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="485.156" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="485.156" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="485.156" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="485.156" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="485.156" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="407.531" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="407.531" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="407.531" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="407.531" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="407.531" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="562.781" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="562.781" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="562.781" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="562.781" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="562.781" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="368.719" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="368.719" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="368.719" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="368.719" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="368.719" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="523.969" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="523.969" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="523.969" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="523.969" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="523.969" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="446.344" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="446.344" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="446.344" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="446.344" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="446.344" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="601.594" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="601.594" y="87.3" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="601.594" y="48.5" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="601.594" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="601.594" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-amelie)"/>
          <rect x="320.203" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="320.203" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="320.203" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="320.203" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="320.203" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="475.453" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="475.453" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="475.453" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="475.453" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="475.453" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="397.828" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="397.828" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="397.828" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="397.828" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="397.828" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="553.078" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="553.078" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="553.078" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="553.078" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="553.078" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="359.016" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="359.016" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="359.016" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="359.016" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="359.016" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="514.266" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="514.266" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="514.266" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="514.266" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="514.266" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="436.641" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="436.641" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="436.641" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="436.641" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="436.641" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="591.891" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="591.891" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="591.891" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="591.891" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="591.891" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="339.609" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="339.609" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="339.609" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="339.609" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="339.609" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="494.859" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="494.859" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="494.859" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="494.859" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="494.859" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="417.234" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="417.234" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="417.234" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="417.234" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="417.234" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="572.484" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="572.484" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="572.484" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="572.484" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="572.484" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="378.422" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="378.422" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="378.422" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="378.422" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="378.422" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="533.672" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="533.672" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="533.672" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="533.672" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="533.672" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="456.047" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="456.047" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="456.047" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="456.047" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="456.047" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="611.297" y="9.69995" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="611.297" y="87.3" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="611.297" y="48.5" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="611.297" y="29.1001" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
          <rect x="611.297" y="67.8999" width="9.70312" height="9.7" fill="var(--rir-avengers)"/>
        </svg>
      </r-col>
    </r-row>
    <r-row class="mb-12">
      <r-col
        class="troy--text feta"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        m — margin (внешний отступ)<br/>
        p — padding (внутренний)<br/><br/><br/>

        a — all<br/>
        x — left-right<br/>
        y — top-bottom<br/>
        t — top<br/>
        l — left<br/>
        b — bottom<br/>
        r — right<br/><br/><br/>

        0, 1, 2, 3, 4... — количество микромодулей по 4 px<br/><br/><br/>

        Опционально:<br/>
        widest, wide, middle, narrow — в каком размерном диапазоне применяется
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        class="burrata"
        :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        Пример
      </r-col>
    </r-row>
    <r-row class="mb-8">
      <r-col
        :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        <div class="offset__example">
          <span class="burrata mr-6">m</span>
          <r-select
            class="mr-4"
            label="Тип"
            :items="offsetTypes"
            v-model="mType"
          />
          <r-select
            class="mr-10"
            label="Размер"
            :items="offsetSize"
            v-model="mSize"
          />
          <span class="burrata mr-6">p</span>
          <r-select
            class="mr-4"
            label="Тип"
            :items="offsetTypes"
            v-model="pType"
          />
          <r-select
            class="mr-10"
            label="Размер"
            :items="offsetSize"
            v-model="pSize"
          />
        </div>
      </r-col>
    </r-row>

    <r-row>
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <div class="dangal--bg d-flex">
          <div
            class="dangal--bg"
            style="width: 100%;"
            :class="[`m${mType}-${mSize}`]">
            <div
              :class="[`p${pType}-${pSize}`]"
              style="height: 40px;  box-sizing: content-box">

              <div
                class="trainspotting--bg d-flex align-items-center justify-content-center"
                style="
            width: 100%; height: 100%;">
                Content</div>
            </div>
          </div>
        </div>
      </r-col>
    </r-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Offsets extends Vue {
  mType = 'a'

  mSize = '2'

  pType = 'a'

  pSize = '2'

  offsetTypes = ['a', 'x', 'y', 't', 'l', 'b', 'r']

  offsetSize = Array.from(Array(20).keys()).map((k) => k.toString())
}
</script>

<style lang="scss">
.offset{
  &__example{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & .RSelect__input{
      width: 120px;
    }
  }
}

.example{}
</style>
