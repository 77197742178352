<template>
  <component-page :code-view="codeView" :color-preview-block="getColor">
    <template #description>
      Компонент визуализирующий загрузку данных и запуск новых процессов
    </template>
    <template #component>
      <r-spinner
        :key="props.size"
        v-bind="props"
      />
    </template>
    <template #props>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Size"
            v-model="props.size"
            :items="dataSize"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Color"
            v-model="props.color"
            :items="dataColor"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Spinner extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"nelida"</span>',
      description: 'Размер компонента',
    },
    {
      nameProp: '<span class="rocky--text">color</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"rocky"</span>',
      description: 'Цвет компонента',
    },
  ]

  props = {
    size: 'nelida',
    color: 'rocky',
  }

  dataSize = [
    {
      id: 'synallis',
      title: 'synallis',
    },
    {
      id: 'diomis',
      title: 'diomis',
    },
    {
      id: 'nelida',
      title: 'nelida',
    },
    {
      id: 'phonia',
      title: 'phonia',
    },
    {
      id: 'melaenia',
      title: 'melaenia',
    },
  ]

  dataColor = [
    {
      id: 'rocky',
      title: 'rocky',
    },
    {
      id: 'white',
      title: 'white',
    },
  ]

  get codeView() {
    return generateCodeToString('spinner', this.props);
  }

  get getColor() {
    return this.props.color === 'white' ? 'black' : 'white';
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
