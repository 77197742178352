<template>

  <div class="pt-2 component">
    <r-row class="mb-10">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="parmigiano troy--text">
          Значения opacity и их имена
        </span>
      </r-col>
    </r-row>
    <r-row class="mb-12">
      <r-col
        v-for="opacity in opacities"
        :key="opacity.token"
        :cols="{ widest: 2, wide: 2, middle: 2, narrow: 2 }">
        <div
          class="d-flex flex-direction-column"
        >
          <span class="rocky--text burrata mb-2">{{opacity.token}}</span>
          <span class="troy--text feta">{{opacity.value}}</span>
        </div>
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        class="burrata"
        :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        Пример
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        :cols="{ widest: 4, wide: 4, middle: 3, narrow: 6 }">
        <r-select
          label="Тип"
          :items="opacities"
          id-value="token"
          title-value="token"
          v-model="opacityData"
        />
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        :cols="{ widest: 4, wide: 4, middle: 3, narrow: 6 }">
        <div
          style="height: 64px"
          class="rocky--bg"
          :class="[opacityData]">
        </div>
      </r-col>
    </r-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import opacities from '@/data/opacities.json';

@Component
export default class Opacities extends Vue {
  opacities = opacities

  opacityData = 'odo'
}
</script>
