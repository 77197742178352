<template>
  <section class="modal">
    <r-row class="mb-8">
      <r-col
        class="d-flex justify-content-center"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }"
        :offset="{ widest: 2, wide: 2, middle: 0, narrow: 0 }"
      >
        <r-uploader
          v-model="model"
          title="Загрузка файлов"
          sub-title="Проверка ошибки при загрузки"
          :max-size="10"
        />
      </r-col>
    </r-row>
  </section>
</template>

<script>

export default {
  name: 'UploaderModalComponent',
  data: () => ({
    model: [],
  }),
};
</script>

<style lang="scss" scoped>
.modal{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
