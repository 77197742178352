<template>
  <examples-block
    gitUrl="src/components/examples/Modal/CallbackCloseAndBack.vue"
    title="Модальное окно с обработкой callback закрытия"
  >
    <r-button
      title="Отрыть модальное окно"
      @click="openModal"
    />
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';
import CallbackCloseAndBackModal from '@/components/examples/Modal/modalView/CallbackCloseAndBackModal.vue';
import ConfirmModal from '@/components/examples/Modal/modalView/ConfirmModal.vue';

export default {
  name: 'CallbackCloseAndBack',
  components: { ExamplesBlock },
  methods: {
    openModal() {
      this.$rir.modal.open(CallbackCloseAndBackModal, {}, {
        isCallbackAfterClose: this.callbackModalClose,
      });
    },
    async callbackModalClose() {
      const res = await this.$rir.modal.open(ConfirmModal, {}, {
        isClose: false,
      });
      return res;
    },
  },
};
</script>
