<template>
  <component-page :code-view="codeView">
    <template #description>
      Интерактивный компонент для загрузки файлов на&nbsp;сервер с&nbsp;устройства пользователя
      кликом по&nbsp;компоненту или перетягиванием в&nbsp;его область
    </template>
    <template #component>
      <r-uploader
        v-model="model"
        v-bind="props"
        :accept="['application/pdf', 'image/jpeg', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
        :callback="uploadFile"
      />
    </template>
    <template #props>
      <r-row class="mb-6" gap-hidden>
        <r-switcher
          title="disabled"
          v-model="props.disabled"
          />
        <r-switcher
          title="error"
          v-model="props.error"
        />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Title"
            v-model="props.title"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="SubTitle"
            v-model="props.subTitle"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="MaxFiles"
            v-model="props.maxFiles"
            type-input="number"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="MaxSize, Mbyte"
            v-model="props.maxSize"
            type-input="number"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="ErrorMessage"
            v-model="props.errorMessage" />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>

        <template v-slot:contentTable="{ item }">
          <template v-if="item.id === 'callback'">
            <span>Переданная функция может возвращать resolve ответ в следующем формате или пустым:</span>
            <br><br>
            <div class="pa-2 amelie--bg rajol">
              { <br>
              &nbsp;&nbsp;&nbsp;error: boolean<br>
              &nbsp;&nbsp;&nbsp;errorMessage: string | null<br>
              &nbsp;&nbsp;&nbsp;file: FileLoad['file']<br>
              &nbsp;&nbsp;&nbsp;success: boolean<br>
              }
            </div>
            <span>Все положительно загруженные файлы будут записываться в модель :value</span>
            <br><br>
          </template>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
    <template #examples>
      <uploader-and-file />
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import UploaderAndFile from '@/components/examples/Uploader/UploaderAndFile.vue';

@Component({
  components: { UploaderAndFile, ComponentPage },
})
export default class Uploader extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">FileLoad[\'file\'][]</span>',
      defaultValue: '<span class="alien--text">[]</span>',
      description: 'Модель компонента',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Параметр для блокировки компонента',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Текст описание поля',
    },
    {
      nameProp: '<span class="rocky--text">subTitle</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Дополнительное описание поля',
    },
    {
      nameProp: '<span class="rocky--text">maxFiles</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="rocky--text">5</span>',
      description: 'максимальное количество файлов',
    },
    {
      nameProp: '<span class="rocky--text">maxSize</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="rocky--text">1</span>',
      description: 'максимальное объем файлов (в МБ)',
    },
    {
      nameProp: '<span class="rocky--text">accept</span>',
      typeData: '<span class="matrix--text">array</span>',
      defaultValue: '<span class="rocky--text">[]</span>',
      description: 'Массив разрешенных форматов по формату MIME',
    },
    {
      id: 'callback',
      nameProp: '<span class="rocky--text">callback</span>',
      typeData: '<span class="matrix--text">Async Function</span>',
      defaultValue: '<span class="mummy--text">() => ({})</span>',
      description: 'Входной параметр асихроной функции для обработки загрузки файлов и возврата ответа',
      slotCollapse: true,
    },
    {
      nameProp: '<span class="rocky--text">callbackError</span>',
      typeData: '<span class="matrix--text">Function</span>',
      defaultValue: '<span class="mummy--text">(list: FileLoad[]) => void</span>',
      description: 'Входной параметр для получение списка ошибок файлов',
    },
    {
      nameProp: '<span class="rocky--text">error</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Входной параметр для определение ошибки валидации и подсветка компонента',
    },
    {
      nameProp: '<span class="rocky--text">errorMessage</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Текст ошибки при активном свойстве error в область subTitle',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
  ]

  model = []

  props = {
    disabled: false,
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    maxFiles: 5,
    maxSize: 1,
    error: false,
    errorMessage: null,
  }

  get codeView() {
    return generateCodeToString('uploader', this.props);
  }

  // eslint-disable-next-line class-methods-use-this
  uploadFile(file: any): Promise<any> {
    return new Promise((resolve) => {
      const error = false; //! !Math.round(Math.random());
      const seconds = Math.floor(Math.random() * (2 - 1 + 1) + 1) * 1000;
      setTimeout(() => {
        if (error) {
          console.error('uploadFile', error);
        } else {
          console.log('uploadFile', error);
        }
        resolve({
          ...file,
          success: !error,
          error,
          errorMessage: error ? 'Ошибка загрузки файла' : null,
        });
      }, seconds);
    });
  }
}
</script>
