<template>
  <examples-block
    gitUrl="src/components/examples/Grid/GridColumnAndOffset.vue"
    title="Сетка по колонкам и с отступами"
    bgColor="trainspotting"
  >
    <r-row class="mb-6">
      <r-col
        class="alien--bg"
        :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }"
        :offset="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        class="alien--bg"
        :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }"
        :offset="{ widest: 1, wide: 1, middle: 1, narrow: 1 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
      <r-col
        class="alien--bg"
        :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }"
        :offset="{ widest: 2, wide: 2, middle: 0, narrow: 0 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        class="alien--bg"
        :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
      <r-col
        class="alien--bg"
        :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }"
        :offset="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        class="alien--bg"
        :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }"
        :offset="{ widest: 1, wide: 1, middle: 1, narrow: 1 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
      <r-col
        class="alien--bg"
        :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }"
        :offset="{ widest: 2, wide: 2, middle: 0, narrow: 0 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
    </r-row>
    <r-row>
      <r-col
        class="alien--bg"
        :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }"
        :offset="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
    </r-row>
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'GridColumnAndOffset',
  components: { ExamplesBlock },
};
</script>
