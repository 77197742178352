<template>
  <r-layout-dashboard
    is-compact
    :menu-options="menuOptions"
    title="LayoutDashboardPage"
  >
    <template #header>
      <div class="gentlemen--text departed--bg d-flex align-items-center justify-content-center" style="height: 40px; width: 100%">
        logo
      </div>
    </template>
    <template #navigation>
      <div class="gentlemen--text departed--bg d-flex align-items-center justify-content-center" style="height: 20px; width: 100%">
        navigation
      </div>
    </template>
    <template #actions>
      <div class="gentlemen--text departed--bg d-flex align-items-center justify-content-center" style="height: 40px; width: 288px">
        actions
      </div>
    </template>
    <template #content>
      <div class="gentlemen--text departed--bg d-flex align-items-center justify-content-center" style="height: 1500px; width: 100%">
        content
      </div>
    </template>
    <template #footer>
      <div
        style="width: 100%; height: 40px" class="departed--bg gentlemen--text feta d-flex justify-content-center align-items-center px-2">
        <r-shorter
          text="footer"
        />
      </div>
    </template>
  </r-layout-dashboard>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LayoutDashboardPage extends Vue {
  menuOptions: any = {
    items: [
      {
        title: 'menu1',
        icon: 'avatar',
        bulbOptions: {
          title: '22',
        },
      },
      {
        title: 'menu2',
        icon: 'avatar',
        children: [
          {
            title: 'sub menu 1',
            icon: 'avatar',
            bulbOptions: {
              title: '1',
              color: 'matrix',
            },
          },
          {
            title: 'sub menu 2',
            bulbOptions: {
              title: '2',
              color: 'fargo',
            },
          },
          {
            title: 'sub menu 3',
            icon: 'avatar',
          },
          {
            title: 'sub menu 4',
            icon: 'avatar',
          },
          {
            title: 'sub menu 5',
            icon: 'avatar',
          },
          {
            title: 'sub menu 6',
            icon: 'avatar',
          },
          {
            title: 'sub menu 7',
            icon: 'avatar',
          },
          {
            title: 'sub menu 8',
            icon: 'avatar',
          },
          {
            title: 'sub menu 9',
            icon: 'avatar',
          },
          {
            title: 'sub menu 10',
            icon: 'avatar',
          },
          {
            title: 'sub menu 11',
            icon: 'avatar',
          },
          {
            title: 'sub menu 12',
            icon: 'avatar',
          },
          {
            title: 'sub menu 13',
            icon: 'avatar',
          },
          {
            title: 'sub menu 14',
            icon: 'avatar',
          },
          {
            title: 'sub menu 15',
            icon: 'avatar',
          },
          {
            title: 'sub menu 16',
            icon: 'avatar',
          },
          {
            title: 'sub menu 17',
            icon: 'avatar',
          },
          {
            title: 'sub menu 18',
            icon: 'avatar',
          },
          {
            title: 'sub menu 19',
            icon: 'avatar',
          },
          {
            title: 'sub menu 20',
            icon: 'avatar',
          },
        ],
      },
      {
        title: 'menu3',
        icon: 'avatar',
      },
    ],
    isCompact: false,
  }
}

</script>
