<template>
  <component-page :code-view="codeView">
    <template #description>
      Иконки
    </template>
    <template #component>
      <r-icon
        v-bind="props"
      />
    </template>
    <template #props>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Icon"
            :icon="props.icon"
            v-model="props.icon"
            :items="dataIcons"
            is-search
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Size"
            v-model="props.size"
            :items="dataSize"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Fill"
            v-model="props.fill"
            :items="dataColor"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class Icon extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">icon</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Название иконки из списка в UiKit',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string | number</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Размер иконки (каждая иконка имеет свой размер и вид, не все иконки имеются в размерах)',
    },
    {
      nameProp: '<span class="rocky--text">fill</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Название цвета из палитры UiKit',
    },
  ]

  props = {
    icon: 'selected',
    size: '16',
    fill: 'rocky',
  }

  dataSize = [
    {
      id: '16',
      title: '16px',
    },
    {
      id: '20',
      title: '20px',
    },
    {
      id: '32',
      title: '32px',
    },
    {
      id: '56',
      title: '56px',
    },
    {
      id: '72',
      title: '72px',
    },
  ]

  dataColor = [
    {
      id: 'rocky',
      title: 'rocky',
    },
    {
      id: 'matrix',
      title: 'matrix',
    },
    {
      id: 'lebowski',
      title: 'lebowski',
    },
    {
      id: 'fargo',
      title: 'fargo',
    },
    {
      id: 'gentlemen',
      title: 'gentlemen',
    },
    {
      id: 'metropolis',
      title: 'metropolis',
    },
    {
      id: 'fightclub',
      title: 'fightclub',
    },
    {
      id: 'aquaman',
      title: 'aquaman',
    },
    // {
    //   id: 'goodfellas',
    //   title: 'goodfellas',
    // },
  ]

  get dataIcons() {
    return getIconName(`_${this.props.size}`).map((el) => ({
      id: el,
      title: el,
      icon: el,
    }));
  }

  get codeView() {
    return generateCodeToString('icon', this.props);
  }

  @Watch('props.size')
  changeSize() {
    this.props.icon = 'selected';
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
