<template>
  <examples-block
    gitUrl="src/components/examples/Table/TableFixedHeader.vue"
    title="Таблица с фиксированной шапкой и цветом amelie">
    <r-table
      :headers="headers"
      :items="items"
      is-fixed-header
      color-header="amelie"
    />
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'TableFixedHeader',
  components: { ExamplesBlock },
  data: () => ({
    headers: [
      {
        field: 'name',
        name: 'Имя',
      },
      {
        field: 'middleName',
        name: 'Отчество',
      },
      {
        field: 'firstName',
        name: 'Фамилия',
      },
    ],
    items: [
      {
        name: 'Иванов',
        middleName: 'Иван',
        firstName: 'Иванович',
      },
      {
        name: 'Иванов',
        middleName: 'Иван',
        firstName: 'Иванович',
      },
      {
        name: 'Иванов',
        middleName: 'Иван',
        firstName: 'Иванович',
      },
      {
        name: 'Иванов',
        middleName: 'Иван',
        firstName: 'Иванович',
      },
      {
        name: 'Иванов',
        middleName: 'Иван',
        firstName: 'Иванович',
      },
    ],
  }),
};
</script>
