<template>
  <component-page :code-view="codeView">
    <template #description>
      Интерактивный текст для навигации
    </template>
    <template #component>
      <r-button-navigation
        :key="props.size"
        v-bind="props"/>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="active"
          v-model="props.active"
        />
      </r-row>
      <r-row
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Size"
            :items="dataSize"
            v-model="props.size"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Color"
            :items="dataColor"
            v-model="props.color"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Icon"
            :icon="props.icon"
            v-model="props.icon"
            :items="dataIcons"
            is-search
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Title"
            v-model="props.title"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class ButtonNavigation extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"gouda"</span>',
      description: 'Размер компонента',
    },
    {
      nameProp: '<span class="rocky--text">color</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"harakiri"</span>',
      description: 'Цвет текста и его сотояние',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Текст контента компонента',
    },
    {
      nameProp: '<span class="rocky--text">icon</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">null</span>',
      description: 'Иконка',
    },
    {
      nameProp: '<span class="rocky--text">to</span>',
      typeData: '<span class="matrix--text">string | RouterPushParams</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Входной параметр для применение роутинга VUE в рамках экосистемы Vue-router. Формат данных, равен :to в router-link',
    },
    {
      nameProp: '<span class="rocky--text">href</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Входной параметр для применение прямой ссылки, данный вариант открыват ссылку в новой вкладке',
    },
    {
      nameProp: '<span class="rocky--text">active</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="rocky--text">true</span>',
      description: 'Входной параметр определяющий активность элемента',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается событие клика по компоненту',
    },
  ]

  props = {
    size: 'gouda',
    title: 'Button text',
    color: 'harakiri',
    active: true,
  }

  dataSize = [
    {
      id: 'gouda',
      title: 'gouda',
    },
    {
      id: 'cheddar',
      title: 'cheddar',
    },
    {
      id: 'camembert',
      title: 'camembert',
    },
    {
      id: 'ricotta',
      title: 'ricotta',
    },
    {
      id: 'burrata',
      title: 'burrata',
    },
    {
      id: 'taleggio',
      title: 'taleggio',
    },
    {
      id: 'harmeni',
      title: 'harmeni',
    },
    {
      id: 'calligenia',
      title: 'calligenia',
    },
  ]

  dataColor = [
    {
      id: 'harakiri',
      title: 'harakiri',
    },
    {
      id: 'metropolis',
      title: 'metropolis',
    },
    {
      id: 'troy',
      title: 'troy',
    },
    {
      id: 'rocky',
      title: 'rocky',
    },
    {
      id: 'matrix',
      title: 'matrix',
    },
    {
      id: 'lebowski',
      title: 'lebowski',
    },
    {
      id: 'fargo',
      title: 'fargo',
    },
    {
      id: 'fightclub',
      title: 'fightclub',
    },
    {
      id: 'gentlemen',
      title: 'gentlemen',
    },
    {
      id: 'aquaman',
      title: 'aquaman',
    },
    // {
    //   id: 'goodfellas',
    //   title: 'goodfellas',
    // },
  ]

  dataIcons = [
    {
      id: null,
      title: 'Без иконки',
      icon: 'block',
    },
  ]

  get codeView() {
    return generateCodeToString('button-navigation', this.props);
  }

  created() {
    this.dataIcons = this.dataIcons.concat(getIconName().map((el) => ({
      id: el,
      title: el,
      icon: el,
    })));
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
