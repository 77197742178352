<template>
  <component-page :code-view="codeView">
    <template #description>
      Компонент выбора времени с точностью от 1 секунды
    </template>
    <template #component>
      <r-time-picker
        style="width: 100%"
        v-model="model"
        v-bind="props"
      />
    </template>
    <template #props>
      <r-row
        class="mb-6"  gap-hidden>
        <r-switcher
          title="Range type selected"
          sub-title="Array type model"
          v-model="isRange"
        />
      </r-row>
      <r-row
        class="mb-6"  gap-hidden>
        <r-switcher
          title="disabled"
          v-model="props.disabled"
          />
        <r-switcher
          title="isSeconds"
          v-model="props.isSeconds"
          />
        <r-switcher
          title="error"
          v-model="props.error"
          />
        <r-switcher
          title="isClearModel"
          v-model="props.isClearModel"
          />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Label"
            v-model="props.label"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="ErrorMessage"
            v-model="props.errorMessage"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="StepMinutes"
            type-input="number"
            v-model="props.stepMinutes"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="StartTime"
            v-model="props.startTime"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="EndTime"
            v-model="props.endTime"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class TimePicker extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">string | array</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Модель компонента (тип данных array, включает режим range)',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Входной параметр для блокировки компонента',
    },
    {
      nameProp: '<span class="rocky--text">isSeconds</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Выбор секунд',
    },
    {
      nameProp: '<span class="rocky--text">error</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Входной параметр для определение ошибки валидации и подсветка компонента',
    },
    {
      nameProp: '<span class="rocky--text">errorMessage</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Текст сообщения о ошибки',
    },
    {
      nameProp: '<span class="rocky--text">label</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Описание поля',
    },
    {
      nameProp: '<span class="rocky--text">stepMinutes</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="rocky--text">60</span>',
      description: 'Интервал разбиение для шага в диапозоне',
    },
    {
      nameProp: '<span class="rocky--text">startTime</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'От какого времени начинать выбор (формат HH:MM:SS)',
    },
    {
      nameProp: '<span class="rocky--text">endTime</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'По какое время закончить выбор (формат HH:MM:SS)',
    },
    {
      nameProp: '<span class="rocky--text">popoverOptions</span>',
      typeData: '<span class="matrix--text">Pick&lt;RPopover,  \'isCheckAutoScrollBlock\'&gt;</span>',
      defaultValue: '<span class="alien--text">{\n'
        + '      isCheckAutoScrollBlock: false\n'
        + '    }</span>',
      description: 'Входной параметр включающий себя надстройки компонента RPopover',
    },
    {
      nameProp: '<span class="rocky--text">isClearModel</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Очистка модели по функционалу из компонента RInput',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
    {
      nameEvent: '<span class="rocky--text">focusout</span>',
      description: 'Возврощается событие при закрытие списка выбора',
    },
  ]

  model = null

  isRange = false

  props = {
    isClearModel: false,
    disabled: false,
    isSeconds: false,
    error: false,
    label: 'Label',
    errorMessage: null,
    stepMinutes: null,
    startTime: null,
    endTime: null,
  }

  @Watch('isRange')
  changeModelRange(val) {
    this.model = val ? [] : null;
  }

  get codeView() {
    return generateCodeToString('time-picker', this.props);
  }

  mounted() {
    this.model = this.isRange ? [] : null;
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
