<template>
  <component-page :code-view="codeView">
    <template #description>
      Виджет для отображения в списке новостей, событий, статей с фотографией и т.п.
      Количество строк заголовка может быть любым.
    </template>
    <template #component>
      <div style="width: 294px">
        <r-announcement v-bind="props">
          <template #imageContent v-if="dataSlot.imageContentData">
            <div class="leon--bg px-4 py-2 fightclub--text caprino">slot imageContent</div>
          </template>
        </r-announcement>
      </div>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="disabled"
          v-model="props.disabled"
        />
        <r-switcher
          title="isLoading"
          v-model="props.isLoading"
        />
      </r-row>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Size"
            :items="sizeType"
            v-model="props.size"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Title"
            v-model="props.title"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="SubTitle"
            v-model="props.subTitle"
          />
        </r-col>
      </r-row>
    </template>
    <template #slotsView>
      <r-row
        gap-hidden>
        <r-switcher
          title="imageContent"
          v-model="dataSlot.imageContentData"
        />
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Announcement extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Параметр Title используется для вывода надписи под картинкой',
    },
    {
      nameProp: '<span class="rocky--text">subTitle</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Параметр subTitle используется для вывода надписи под области отображение параметра title',
    },
    {
      nameProp: '<span class="rocky--text">image</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Параметр указывается прямой ссылкой на картинку для загрузки и вывода',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"polydora"</span>',
      description: 'Параметр для вариации размерности компонента, а так же влияет на адаптивность компонента',
    },
    {
      nameProp: '<span class="rocky--text">to</span>',
      typeData: '<span class="matrix--text">string | RouterPushParams</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Параметр для применение роутинга VUE в рамках экосистемы Vue-router. Формат данных, равен :to в router-link',
    },
    {
      nameProp: '<span class="rocky--text">href</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Параметр для применение прямой ссылки, данный вариант открыват ссылку в новой вкладке',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Параметр для блокировки компонента',
    },
    {
      nameProp: '<span class="rocky--text">isLoading</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Параметр для принудительной прилоадинга компонента',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">imageContent</span>',
      description: 'Слот занимает позиционирование поверх изображения в нижней части блока',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается событие клика',
    },
  ]

  sizeType = [
    {
      id: 'polydora',
      title: 'polydora',
    },
    {
      id: 'rhaenise',
      title: 'rhaenise',
    },
  ]

  dataSlot = {
    imageContentData: false,
  }

  props = {
    disabled: false,
    isLoading: false,
    image: '/img/test.jpg',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    size: 'polydora',
  }

  get codeView() {
    return generateCodeToString('announcement', { ...this.props }, { ...this.dataSlot });
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
