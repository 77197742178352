<template>
  <component-page :code-view="codeView">
    <template #description>
      Неинтерактивный компонент Балб
    </template>
    <template #component>
      <r-bulb
        v-bind="props"
      >
        <template #content>
          <span
            style="
            width: 104px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center"
            class="metropolis--bg white--text">
            content
          </span>
        </template>
      </r-bulb>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="isBlock"
          v-model="props.isBlock"
        />
        <r-switcher
          title="isShape"
          v-model="props.isShape"
        />
      </r-row>
      <r-row
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-select
              label="Position"
              :items="positionType"
              v-model="props.position"
              />
          </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-select
              label="Size"
              :items="sizeType"
              v-model="props.size"
              />
          </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-select
              label="Color"
              :items="colorType"
              v-model="props.color"
              />
          </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-input
            :is-clear="false"
              :readonly="props.size !== 'bretiax' && props.isShape"
              label="Title"
              v-model="props.title"
              />
          </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Bulb extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">isBlock</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Определяет тип позиционирования компонента relative или absolute',
    },
    {
      nameProp: '<span class="rocky--text">isShape</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Показывать фон компонента',
    },
    {
      nameProp: '<span class="rocky--text">color</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"rocky"</span>',
      description: 'Цвет фона или текста при isShape = false',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"marina"</span>',
      description: 'Размер компонента',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Текст в область контента компонента',
    },
    {
      nameProp: '<span class="rocky--text">position</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"eluno"</span>',
      description: 'Позиционирование компонента по слоту',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">content</span>',
      description: 'Слот для контента, в рамках которых компонент будет позиционироваться',
    },
  ]

  props = {
    isBlock: false,
    isShape: true,
    color: 'rocky',
    title: null,
    position: 'eluno',
    size: 'marina',
  }

  sizeType = [
    {
      id: 'marina',
      title: 'marina',
    },
    {
      id: 'strophia',
      title: 'strophia',
    },
    {
      id: 'bretiax',
      title: 'bretiax',
    },
  ]

  positionType = [
    {
      id: 'eluno',
      title: 'eluno',
    },
    {
      id: 'polyxo',
      title: 'polyxo',
    },
    {
      id: 'celano',
      title: 'celano',
    },
    {
      id: 'salamis',
      title: 'salamis',
    },
    {
      id: 'nikaia',
      title: 'nikaia',
    },
  ]

  colorType = [
    {
      id: 'rocky',
      title: 'rocky',
    },
    {
      id: 'matrix',
      title: 'matrix',
    },
    {
      id: 'lebowski',
      title: 'lebowski',
    },
    {
      id: 'fargo',
      title: 'fargo',
    },
    {
      id: 'gentlemen',
      title: 'gentlemen',
    },
    {
      id: 'metropolis',
      title: 'metropolis',
    },
    {
      id: 'fightclub',
      title: 'fightclub',
    },
    {
      id: 'aquaman',
      title: 'aquaman',
    },
    // {
    //   id: 'goodfellas',
    //   title: 'goodfellas',
    // },
    {
      id: 'white',
      title: 'white',
    },
  ]

  slotsData = {
    content: true,
  }

  get codeView() {
    return generateCodeToString('bulb', this.props, this.slotsData);
  }

  @Watch('props.isShape')
  changeShape() {
    if (this.props.size !== 'bretiax' && this.props.isShape) {
      this.props.title = null;
    }
  }

  @Watch('props.size')
  changeSize() {
    if (this.props.size !== 'bretiax' && this.props.isShape) {
      this.props.title = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
