<template>
  <component-page :code-view="codeView">
    <template #description>
      Использовать фотоизображения с&nbsp;видимыми границами можно только
      с&nbsp;описанными ниже соотношениями сторон.
    </template>
    <template #component>
      <div style="width: 200px">
        <r-image
          v-bind="props"
          url="/img/test.jpg"
        />
      </div>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="isLoading"
          v-model="props.isLoading"
        />
        <r-switcher
          title="isFill"
          v-model="props.isFill"
        />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Ratio"
            v-model="props.ratio"
            :items="dataRatio"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Rounded"
            v-model="props.rounded"
            :items="dataRounded"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class ImagePage extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">url</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Прямая ссылка на изображение',
    },
    {
      nameProp: '<span class="rocky--text">ratio</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"kano"</span>',
      description: 'Соотношение сторон изображения',
    },
    {
      nameProp: '<span class="rocky--text">rounded</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"kurza"</span>',
      description: 'Скругление картинки',
    },
    {
      nameProp: '<span class="rocky--text">isLoading</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fargo--text">false</span>',
      description: 'Принудительный прилоадер в рамках загрузки данных на проекте',
    },
    {
      nameProp: '<span class="rocky--text">isFill</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fargo--text">false</span>',
      description: 'Растягивать или встраивать картинку в область контента',
    },
  ]

  props = {
    ratio: 'kano',
    rounded: 'kurza',
    isLoading: false,
    isFill: false,
  }

  dataRounded = [
    {
      id: 'jade',
      title: 'jade',
    },
    {
      id: 'akbu',
      title: 'akbu',
    },
    {
      id: 'rajol',
      title: 'rajol',
    },
    {
      id: 'theku',
      title: 'theku',
    },
    {
      id: 'bana',
      title: 'bana',
    },
    {
      id: 'sylilis',
      title: 'sylilis',
    },
    {
      id: 'kurza',
      title: 'kurza',
    },
    {
      id: 'eluno',
      title: 'eluno',
    },
    {
      id: 'geban',
      title: 'geban',
    },
    {
      id: 'nemea',
      title: 'nemea',
    },
  ]

  dataRatio = [
    {
      id: 'kemto',
      title: 'kemto',
    },
    {
      id: 'odro',
      title: 'odro',
    },
    {
      id: 'rada',
      title: 'rada',
    },
    {
      id: 'tako',
      title: 'tako',
    },
    {
      id: 'kano',
      title: 'kano',
    },
    {
      id: 'tira',
      title: 'tira',
    },
  ]

  get codeView() {
    return generateCodeToString('image', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
