<template>
  <examples-block
    figma-url="https://www.figma.com/file/W6bvGBZas6baBlRQQKVquN/DS-Components?node-id=5792%3A106390"
    gitUrl="src/components/examples/Select/SelectGroupsItems.vue"
    title="Группировка элементов внутри списка"
    description="Группировка списка работает только с локальным списком"
  >
    <r-select
      label="Items"
      v-model="model"
      :items="items"
      :groups-list="groups"
      return-object
    />
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'SelectGroupsItems',
  components: { ExamplesBlock },
  data: () => ({
    items: [
      {
        id: 1,
        title: 'Title1',
        subTitle: 'SubTitle1',
        icon: 'avatar',
        group: 'group1',
      },
      {
        id: 2,
        title: 'Title2',
        subTitle: 'SubTitle2',
        icon: 'avatar',
        group: 'group2',
      },
      {
        id: 3,
        title: 'Title3',
        subTitle: 'SubTitle3',
        icon: 'avatar',
        group: 'group1',
      },
      {
        id: 4,
        title: 'Title4',
        subTitle: 'SubTitle4',
        icon: 'avatar',
        group: 'group3',
      },
      {
        id: 5,
        title: 'Title5',
        subTitle: 'SubTitle5',
        icon: 'avatar',
        group: 'group2',
      },
      {
        id: 6,
        title: 'Title6',
        subTitle: 'SubTitle6',
        icon: 'avatar',
        group: 'group3',
      },
    ],
    groups: [
      {
        id: 'group1',
        name: 'Group 1',
      },
      {
        id: 'group2',
        name: 'Group 2',
      },
      {
        id: 'group3',
        name: 'Group 3',
      },
    ],
    model: null,
  }),
  methods: {
    clearModel() {
      this.model = null;
    },
  },
};
</script>
