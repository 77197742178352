<template>
  <examples-block
    gitUrl="src/components/examples/BreadCrumbs/BreadCrumbsIcons.vue"
    title="Хлебные крошки с иконками">
    <div style="width: 300px;">
      <r-bread-crumbs
        :items="items"
      />
    </div>
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'BreadCrumbsIcons',
  components: { ExamplesBlock },
  data: () => ({
    items: [
      {
        icon: 'home',
      },
      {
        title: 'title2',
        icon: 'dashboard',
      },
      {
        title: 'title3',
        active: false,
        icon: 'agreement',
      },
      {
        icon: 'home',
        title: 'title8',
      },
      {
        title: 'title4',
        icon: 'dashboard',
      },
      {
        title: 'title5',
        active: false,
        icon: 'agreement',
      },
      {
        icon: 'home',
        title: 'title9',
      },
      {
        title: 'title6',
        icon: 'dashboard',
      },
      {
        title: 'title7',
        active: false,
        icon: 'agreement',
      },
    ],
  }),
};
</script>
