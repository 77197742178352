<template>
  <component-page :code-view="codeView">
    <template #description>
      Кнопка&nbsp;&mdash; основной интерактивный компонент любого интерфейса.
      Текст в&nbsp;кнопке&nbsp;&mdash; это команда которую пользователь даёт системе &mdash;
      глагол неопределённой формы несовершенного вида, отвечающий на&nbsp;вопрос что делать:
      открыть, загрузить, перейти, закрыть. <br />
      Может быть существительным&nbsp;&mdash; названием раздела или сценария, который запустится после нажатия:
      профиль, меню, регистрация. <br />
      Либо наречием в&nbsp;диалоговом окне: понятно, отлично, выражающим положительное отношение
      пользователя к&nbsp;сообщению на&nbsp;экране.
    </template>
    <template #component>
      <r-button :key="JSON.stringify(props)" v-bind="props" />
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="disabled"
          v-model="props.disabled"
        />
        <r-switcher
          title="isLoading"
          v-model="props.isLoading"
        />
        <r-switcher
          title="rounded"
          v-model="props.rounded"
        />
      </r-row>
      <r-row
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Type"
            v-model="props.type"
            :items="dataType"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Size"
            v-model="props.size"
            :items="dataSize"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Width"
            v-model="props.width"
            :items="dataWidth"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Color"
            v-model="props.color"
            :items="dataColor"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Icon"
            :icon="props.icon"
            v-model="props.icon"
            :items="dataIcons"
            is-search
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false" label="Title" v-model="props.title" />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { getIconName } from '@/utils/icons';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Button extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Блокировка действий по кнопке',
    },
    {
      nameProp: '<span class="rocky--text">isLoading</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Состояние загрузки после действия, блокировка эвента в момент загрузки',
    },
    {
      nameProp: '<span class="rocky--text">rounded</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Скругление кнопки',
    },
    {
      nameProp: '<span class="rocky--text">icon</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Установка иконки слева от текста',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"helike"</span>',
      description: 'Размер компонента',
    },
    {
      nameProp: '<span class="rocky--text">width</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"content"</span>',
      description: 'Определение длины компонента, по содержимому или по родителю',
    },
    {
      nameProp: '<span class="rocky--text">type</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"primary"</span>',
      description: 'Тип цветового состояния кнопки',
    },
    {
      nameProp: '<span class="rocky--text">color</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"rocky"</span>',
      description: 'Определение цвета кнопки для типа "primary"',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Текст кнопки',
    },
    {
      nameProp: '<span class="rocky--text">typeButton</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"button"</span>',
      description: 'Тип кнопки свойства native type button',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается событие клика по компоненту',
    },
  ]

  props = {
    disabled: false,
    isLoading: false,
    rounded: false,
    icon: null,
    typeButton: 'button',
    size: 'helike',
    width: 'content',
    type: 'primary',
    color: 'rocky',
    title: 'Button',
  }

  dataType = [
    {
      id: 'primary',
      title: 'primary',
    },
    {
      id: 'secondary',
      title: 'secondary',
    },
  ]

  dataWidth = [
    {
      id: 'content',
      title: 'content',
    },
    {
      id: 'wide',
      title: 'wide',
    },
  ]

  dataColor = [
    {
      id: 'rocky',
      title: 'rocky',
    },
    {
      id: 'matrix',
      title: 'matrix',
    },
    {
      id: 'lebowski',
      title: 'lebowski',
    },
    {
      id: 'fargo',
      title: 'fargo',
    },
    {
      id: 'gentlemen',
      title: 'gentlemen',
    },
    {
      id: 'metropolis',
      title: 'metropolis',
    },
    {
      id: 'fightclub',
      title: 'fightclub',
    },
    {
      id: 'aquaman',
      title: 'aquaman',
    },
    // {
    //   id: 'goodfellas',
    //   title: 'goodfellas',
    // },
  ]

  dataSize = [
    {
      id: 'laripha',
      title: 'laripha',
    },
    {
      id: 'helike',
      title: 'helike',
    },
    {
      id: 'polyxo',
      title: 'polyxo',
    },
  ]

  dataIcons = [
    {
      id: null,
      title: 'Без иконки',
      icon: 'block',
    },
  ]

  get codeView() {
    return generateCodeToString('button', this.props);
  }

  created() {
    this.dataIcons = this.dataIcons.concat(getIconName().map((el) => ({
      id: el,
      title: el,
      icon: el,
    })));
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
