<template>
  <examples-block
    gitUrl="src/components/examples/Tooltip/TooltipInButton.vue"
    title="Tooltip на кнопку">
    <div
      style="width: 100%"
    >
      <r-tooltip title="Tooltip in button">
        <template #activator>
          <r-button
            title="Button example"
            width="wide"
            disabled
            @click="testClick"
          />
        </template>
      </r-tooltip>
    </div>
  </examples-block>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'TooltipInButton',
  components: { ExamplesBlock },
  methods: {
    testClick() {
      console.log('click');
    },
  },
};
</script>
