<template>
  <section class="modal">

    <r-row class="mb-8">
      <r-col
        class="d-flex justify-content-center"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }"
        :offset="{ widest: 2, wide: 2, middle: 0, narrow: 0 }"
      >
        <r-popover
        style="width: fit-content">
          <template #activator="{ on }">
            <r-button
              title="activator"
              v-on="on"
              size="laripha"
              rounded
            />
          </template>
          <template #content>
            <div class="pa-3 briscola" style="text-align: center">
              <r-button
                title="Отрыть модальное окно"
                @click="openModal"
              />
            </div>
          </template>
        </r-popover>
      </r-col>
    </r-row>
  </section>
</template>

<script>
import ModalPage from '@/components/ModalPage.vue';

export default {
  name: 'FirstModal',
  methods: {
    openModal() {
      this.$rir.modal.open(ModalPage, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.modal{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
