<template>
  <component-page :code-view="codeView">
    <template #description>
      Иконки
    </template>
    <template #component>
      <section
        style="max-width: 400px; width: 100%"
      >
        <r-slideshow
          v-bind="props"
        />
      </section>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="isPreviewBlock"
          v-model="props.isPreviewBlock"
        />
        <r-switcher
          title="isStepBadge"
          v-model="props.isStepBadge"
        />
        <r-switcher
          title="isFillImage"
          v-model="props.isFillImage"
        />
      </r-row>
<!--      <r-row-->
<!--        gap-hidden>-->
<!--        <r-col-->
<!--          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">-->
<!--          <r-select-->
<!--            :items="dataSize"-->
<!--            v-model="props.size"-->
<!--            label="Size"-->
<!--            />-->
<!--        </r-col>-->
<!--      </r-row>-->
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class Slideshow extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">files</span>',
      typeData: '<span class="matrix--text">FileItem[]</span>',
      defaultValue: '<span class="alien--text">[]</span>',
      description: 'Список файлов для отображений в слайдере',
      children: [
        {
          nameProp: '<span class="rocky--text">url</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Прямая ссылка на файл',
        },
        {
          nameProp: '<span class="rocky--text">type</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'тип файла по правилам MIME Types',
        },
        {
          nameProp: '<span class="rocky--text">video</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text"></span>',
          description: 'Прямая ссылка на файл с видео',
        },
      ],
    },
    {
      nameProp: '<span class="rocky--text">isPreviewBlock</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">true</span>',
      description: 'Отображать список превьюшек',
    },
    {
      nameProp: '<span class="rocky--text">isStepBadge</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">true</span>',
      description: 'Отображение компонента RBadge для отображение счетчика',
    },
    {
      nameProp: '<span class="rocky--text">isFillImage</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Растягивать или встраивать картинку в область контента',
    },
    // {
    //   nameProp: '<span class="rocky--text">size</span>',
    //   typeData: '<span class="matrix--text">string</span>',
    //   defaultValue: '<span class="alien--text">caliadne</span>',
    //   description: 'Размерность комопнента',
    // },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Отрабатывает по области основного отображения в ответ возвращает данные по файлу',
    },
  ]

  props = {
    files: [
      {
        url: '/img/slide/1.jpg',
        type: 'image/jpeg',
      },
      {
        url: '/img/slide/2.jpg',
        type: 'image/jpeg',
      },
      {
        url: '/img/slide/3.jpg',
        type: 'image/jpeg',
      },
      {
        url: '/img/test.jpg',
        video: '/video/1.mp4',
        type: 'video/mp4',
      },
      {
        url: '/img/slide/4.jpg',
        type: 'image/jpeg',
      },
      {
        url: '/img/slide/5.jpg',
        type: 'image/jpeg',
      },
      {
        url: '/img/slide/6.jpg',
        type: 'image/jpeg',
      },
      {
        url: '/img/slide/7.jpg',
        type: 'image/jpeg',
      },
      {
        url: '/img/test.jpg',
        video: '/video/1.mp4',
        type: 'video/mp4',
      },
      {
        url: '/img/slide/8.jpg',
        type: 'image/jpeg',
      },
      {
        url: '/img/slide/9.jpg',
        type: 'image/jpeg',
      },
      {
        url: '/img/slide/10.jpg',
        type: 'image/jpeg',
      },
      {
        url: '/img/slide/11.jpg',
        type: 'image/jpeg',
      },
    ],
    isPreviewBlock: true,
    isStepBadge: true,
    isFillImage: false,
    // size: 'amiphite',
  }

  dataSize = [
    {
      id: 'amiphite',
      title: 'amiphite',
    },
    {
      id: 'caliadne',
      title: 'caliadne',
    },
  ]

  get codeView() {
    return generateCodeToString('slideshow', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
