<template>
  <examples-block
    gitUrl="src/components/examples/Grid/GridAdaptiveForm.vue"
    title="Адаптивная сетка"
    bgColor="trainspotting"
  >
    <r-row>
      <r-col
        class="alien--bg"
        :cols="{ widest: 3, wide: 3, middle: 3, narrow: 6 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
      <r-col
        class="alien--bg"
        :cols="{ widest: 3, wide: 3, middle: 3, narrow: 6 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
      <r-col
        class="alien--bg"
        :cols="{ widest: 3, wide: 3, middle: 3, narrow: 6 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
      <r-col
        class="alien--bg"
        :cols="{ widest: 3, wide: 3, middle: 3, narrow: 6 }">
        <div class="arrival--bg" style="height: 20px"></div>
      </r-col>
    </r-row>
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'GridAdaptiveForm',
  components: { ExamplesBlock },
};
</script>
