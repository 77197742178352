<template>
  <component-page>
    <template #description>
      Уведомления
    </template>
    <template #component>
      <r-button
        title="Send Notification"
        @click="sendNotification"
      />
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher title="hiddenClose" v-model="props.hiddenClose" />
      </r-row>
      <r-row
        gap-hidden>
        <r-col
          class="bryndza"
          :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
          Notification data:
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false" label="Title" v-model="props.title" />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false" label="Seconds" v-model="props.seconds" type-input="number" />
        </r-col>
        <r-col
          class="bryndza"
          :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
          Icon options:
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Icon"
            :icon="props.iconOptions.icon"
            v-model="props.iconOptions.icon"
            :items="dataIcons"
            is-search
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Color"
            v-model="props.iconOptions.fill"
            :items="fillData"
          />
        </r-col>
        <r-col
          class="bryndza"
          :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
          ButtonAction options:
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false" label="Title ButtonAction" v-model="props.buttonActionOptions.title" />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="troy--text parmigiano mb-6">Требуется в App.vue (основной шаблон при инициализации) вставить компонент r-notification </div>
      <div class="taleggio mb-8">Props attribute function $rir.notification.send</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';
// import { PropsDataNotification } from '../../../node_modules/@rir/vue-library/src/components/RNotification/RNotification';

@Component({
  components: { ComponentPage },
})
export default class Notification extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">iconOptions</span>',
      typeData: '<span class="matrix--text">RIconProps</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Опции для RIcon (если установлен таймер, то не отображается)',
      children: [
        {
          nameProp: '<span class="rocky--text">icon</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Название иконки в 16 размере',
        },
        {
          nameProp: '<span class="rocky--text">fill</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Цвет иконки',
        },
      ],
    },
    {
      nameProp: '<span class="rocky--text">buttonActionOptions</span>',
      typeData: '<span class="matrix--text">ButtonActionProps</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Опции для RButtonAction',
      children: [
        {
          nameProp: '<span class="rocky--text">title</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Описание кнопки',
        },
        {
          nameProp: '<span class="rocky--text">clickEvent</span>',
          typeData: '<span class="matrix--text">Function</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'CallBack по нажатию на кнопку',
        },
      ],
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Текст компонента',
    },
    {
      nameProp: '<span class="rocky--text">seconds</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Временой промежуток для автозакрытие компонента',
    },
    {
      nameProp: '<span class="rocky--text">hiddenClose</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Параметр для скрытия кнопки закрытия',
    },
  ]

  props = {
    iconOptions: {
      icon: 'selected',
      fill: 'matrix',
    },
    title: 'Notification text',
    seconds: 0,
    hiddenClose: false,
    buttonActionOptions: {
      title: 'Button text',
      clickEvent: this.emitButtonAction,
    },
  }

  get PropsData(): any {
    return {
      iconOptions: this.props.iconOptions.icon ? this.props.iconOptions : null,
      title: this.props.title,
      seconds: this.props.seconds,
      hiddenClose: this.props.hiddenClose,
      buttonActionOptions: this.props.buttonActionOptions.title ? this.props.buttonActionOptions : null,
    };
  }

  fillData = ['rocky', 'matrix', 'lebowski', 'fargo', 'fightclub', 'gentlemen', 'aquaman']

  sendNotification() {
    const data = JSON.parse(JSON.stringify(this.PropsData));
    this.$rir.notification.send(data);
  }

  dataIcons = [
    {
      id: null,
      title: 'Без иконки',
      icon: 'block',
    },
  ]

  created() {
    this.dataIcons = this.dataIcons.concat(getIconName().map((el) => ({
      id: el,
      title: el,
      icon: el,
    })));
  }

  emitButtonAction() {
    console.log('emitButtonAction');
  }
}
</script>
