<template>

  <div class="pt-4 component">
    <r-row class="mb-8">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="troy--text parmigiano d-flex align-items-center">
          Установка библиотеки производится с&nbsp;помощью менеджеров пакетов&nbsp;
          <r-button-link
            not-shorter
            @click="openPage('npmjs')"
            title="npm" />
          &nbsp;или&nbsp;
          <r-button-link
            not-shorter
            @click="openPage('yarnpkg')"
            title="yarn" />
        </span>
      </r-col>
    </r-row>
    <r-row class="mb-8">
      <r-col
        class="burrata"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        Установка пакета с помощью Package Registry
      </r-col>
      <r-col
        class="comte"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        Менеджер пакетов npm:
      </r-col>
      <r-col
        class="feta troy--text"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        В проекте требуется создать файл <span class="bryndza">.npmrc</span>
        (дайнный файл не должен находится в репозитории, занести его в .gitignore) и внести следующие строки.
      </r-col>
      <r-col
        class="pa-4 amelie--bg theku"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="troy--text parmigiano">
          <span class="rocky--text" style="white-space: pre-wrap; word-break: break-word;">
            @rir:registry=https://gitlab.rusatom.dev/api/v4/projects/338/packages/npm/
          </span>
          <span class="rocky--text" style="white-space: pre-wrap; word-break: break-word;">
            //gitlab.rusatom.dev/api/v4/projects/338/packages/npm/:_authToken=${CI_JOB_TOKEN}
          </span>
        </span>
      </r-col>
      <r-col
        class="feta troy--text"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        Далее в проекте устанавливаем пакет с помощью команды <span class="bryndza">npm i @rir/vue-library</span>.
      </r-col>
      <r-col
        class="comte"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        Менеджер пакетов yarn:
      </r-col>
      <r-col
        class="feta troy--text"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        В проекте требуется создать файл <span class="bryndza">.yarnrc</span>
        (дайнный файл не должен находится в репозитории, занести его в .gitignore) и внести следующие строки.
      </r-col>
      <r-col
        class="pa-4 amelie--bg theku"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="troy--text parmigiano">
          <span class="rocky--text" style="white-space: pre-wrap; word-break: break-word;">
            "@rir:registry" "https://gitlab.rusatom.dev/api/v4/projects/338/packages/npm/"
          </span>
          <span class="rocky--text" style="white-space: pre-wrap; word-break: break-word;">
            "//gitlab.rusatom.dev/api/v4/projects/338/packages/npm/:_authToken${CI_JOB_TOKEN}" token always-auth true
          </span>
        </span>
      </r-col>
      <r-col
        class="feta troy--text"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        Далее в проекте устанавливаем пакет с помощью команды <span class="bryndza">yarn add @rir/vue-library</span>.
      </r-col>
      <r-col
        class="taleggio"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        Локальное использование
      </r-col>
      <r-col
        class="feta troy--text"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        При разработки с учетной записи в gitlab создать
        <span class="bryndza">Personal Access Tokens</span> и подставить в файл <span class="bryndza">.npmrc</span>
        вместо <span class="bryndza">${CI_JOB_TOKEN}</span>.
      </r-col>
      <r-col
        class="taleggio"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        Проектное использование
      </r-col>
      <r-col
        class="feta troy--text"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        Для проектов потребуется запросить доступ к библиотеки,
        обратиться к разработчикам. Что бы CI/CD проекта мог иметь доступ к библиотеки
        с помощью  <span class="bryndza">CI_JOB_TOKEN</span>.
<!--        в рамках проекта создать <span class="bryndza">Variables</span> для <span class="bryndza">CI/CD</span>.<br />-->
<!--        Укажите название токена, которое будет использоваться при сборке. Значение уточнить у разработчиков.<br />-->
<!--        Установить флаги <span class="bryndza">Mask variable</span> и <span class="bryndza">Expand variable reference</span><br />-->
<!--        После этого в CI/CD будет доступна данная переменая и требуется-->
<!--        при сборки проектов установить ее вместо <span class="bryndza">${CI_JOB_TOKEN}</span>-->
      </r-col>
    </r-row>
    <r-row class="mb-8">
      <r-col
      class="burrata"
      :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
      Vue
    </r-col>
      <r-col
        class="troy--text feta"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        В проекте создать файл плагина src/plugins/RirLib.js со следующим содержимым:
      </r-col>
      <r-col
        class="pa-4 amelie--bg theku rocky--text"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        import Vue from 'vue';<br>
        import Rir from '@rir/vue-library';<br>
        import '@rir/vue-library/dist/RirLib.css';<br><br>

        Vue.use(Rir);<br><br>

        <div
          class="d-flex rocky--text"
          >
        const&nbsp;
        <r-button-link
          not-shorter
          color="drive"
          title="options"
          :to="{ name: 'Options' }" /> &nbsp;= {};<br>
        </div>
        export default new Rir(options);
      </r-col>
      <r-col
        class="troy--text feta"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        Перейдите к своей основной точке входа, где вы создаете свой экземпляр Vue и передаете объект rir в качестве опции.
      </r-col>
      <r-col
        class="pa-4 amelie--bg theku rocky--text"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="troy--text">// src/main.js</span><br>
        import Vue from 'vue';<br>
        import rir from '@/plugins/RirLib';<br><br>
        new Vue({
        $rir: rir,
        }).$mount('#app');
      </r-col>
    </r-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Install extends Vue {
  // eslint-disable-next-line class-methods-use-this
  openPage(packageName) {
    window.open(`https://www.${packageName}.com/`, '_blank');
  }

  // eslint-disable-next-line class-methods-use-this
  downloadFont(name) {
    window.open(`/docs/${name}.7z`, '_blank');
  }
}
</script>

<style lang="scss">
.working{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
}
</style>
