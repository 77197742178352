<template>
  <examples-block
    gitUrl="src/components/examples/Checkbox/CheckboxAndButtonLink.vue"
    title="Checkbox с использование слота #title">
    <r-checkbox
      v-model="model"
      sub-title="Test Test Test Test Test Test Test Test Test Test Test Test Test Test"
    >
      <template #title>
        <div class="d-flex align-items-center sulguni">
          Ознакомлен и согласен с условиями&nbsp;
          <r-button-link href="https://design.rusatom.dev/" title="политики конфиденциальности" @click.prevent="openPage"/>
        </div>
      </template>
    </r-checkbox>
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'CheckboxAndButtonLink',
  components: { ExamplesBlock },
  data: () => ({
    model: false,
  }),
  methods: {
    openPage() {
      window.open('https://design.rusatom.dev/', '_blank');
    },
  },
};
</script>
