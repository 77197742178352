<template>
  <examples-block
    gitUrl="src/components/examples/Modal/CloseEventInComponent.vue"
    title="Модальное окно с собственной обработкой closeModal"
  >
    <r-button
      title="Отрыть модальное окно"
      @click="openModal"
    />
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';
import CloseEventInComponentModal
  from '@/components/examples/Modal/modalView/CloseEventInComponentModal.vue';

export default {
  name: 'CloseEventInComponent',
  components: { ExamplesBlock },
  methods: {
    openModal() {
      this.$rir.modal.open(CloseEventInComponentModal, {}, {
        isCloseEventInitComponent: true,
      });
    },
  },
};
</script>
