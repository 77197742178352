<template>
  <r-layout-site
    :items="menuOptions.items"
  >
    <template #logo>
      <div class="gentlemen--text departed--bg d-flex align-items-center justify-content-center" style="height: 40px; width: 288px">
        logo
      </div>
    </template>
    <template #actions>
      <div class="gentlemen--text departed--bg d-flex align-items-center justify-content-center" style="height: 40px; width: 288px">
        actions
      </div>
    </template>
    <template #content>
      <div class="gentlemen--text departed--bg d-flex align-items-center justify-content-center" style="height: 1500px; width: 100%">
        content
      </div>
    </template>
    <template #footer>
      <div
        style="width: 100%; height: 72px" class="departed--bg gentlemen--text feta d-flex justify-content-center align-items-center">
        footer
      </div>
    </template>
  </r-layout-site>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LayoutDashboardPage extends Vue {
  menuOptions: any = {
    items: [
      {
        title: 'menu1',
      },
      {
        title: 'menu2',
        children: [
          {
            title: 'sub menu 1',
          },
          {
            title: 'sub menu 2',
          },
          {
            title: 'sub menu 3',
          },
        ],
      },
      {
        title: 'menu3',
      },
    ],
  }
}

</script>

<style lang="scss">

</style>
