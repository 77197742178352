<template>

  <div class="pt-2 component">
    <r-row class="mb-10">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="parmigiano troy--text">
          Значение применятся в&nbsp;зависимости от&nbsp;размеров скругляемого объекта.
          Для кнопок высотой 40&nbsp;px скругление&nbsp;8,
          для виджетов&nbsp;&mdash; 16&nbsp;и&nbsp;более.
        </span>
      </r-col>
    </r-row>
    <r-row class="mb-12">
      <r-col
        v-for="round in rounds"
        :key="round.token"
        :cols="{ widest: 2, wide: 2, middle: 2, narrow: 2 }">
        <div
          class="d-flex flex-direction-column"
        >
          <span class="rocky--text burrata mb-2">{{round.token}}</span>
          <span class="troy--text feta">{{round.value}}</span>
        </div>
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        class="burrata"
        :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        Пример
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        :cols="{ widest: 4, wide: 4, middle: 3, narrow: 6 }">
        <r-select
          v-if="rounds"
          label="Тип"
          :items="rounds"
          id-value="token"
          title-value="token"
          v-model="roundData"
        />
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        :cols="{ widest: 4, wide: 4, middle: 3, narrow: 6 }">
        <div
          style="height: 64px"
          class="memento--bg"
          :class="[roundData]">
        </div>
      </r-col>
    </r-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import rounds from '@/data/rounds.json';

@Component
export default class Rounds extends Vue {
  rounds = rounds

  roundData = 'jade'
}
</script>
