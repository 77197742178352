<template>
  <component-page :code-view="codeView">
    <template #description>
      Интерактивный не респонсивный виджет
    </template>
    <template #component>
      <div style="width: 200px">
        <r-story
          v-bind="props"
        />
      </div>
<!--      <r-row gap-hidden>-->
<!--        <r-col-->
<!--          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">-->
<!--          <r-navigation-tile-->
<!--            v-bind="props"-->
<!--          />-->
<!--        </r-col>-->
<!--      </r-row>-->
    </template>
    <template #props>
      <r-row
        class="mb-6" gap-hidden>
        <r-switcher
          title="disabled"
          v-model="props.disabled"
          />
        <r-switcher
          title="isLoading"
          v-model="props.isLoading"
          />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Title"
            v-model="props.title"
            />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Story extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Входной параметр Title используется для вывода надписи',
    },
    {
      nameProp: '<span class="rocky--text">image</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Входной параметр указывается прямой ссылкой на картинку для загрузки и вывода в область',
    },
    {
      nameProp: '<span class="rocky--text">to</span>',
      typeData: '<span class="matrix--text">string | RouterPushParams</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Параметр для применение роутинга VUE в рамках экосистемы Vue-router. Формат данных, равен :to в router-link',
    },
    {
      nameProp: '<span class="rocky--text">href</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Входной параметр для применение прямой ссылки, данный вариант открыват ссылку в новой вкладке',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Входной параметр для блокировки компонента',
    },
    {
      nameProp: '<span class="rocky--text">isLoading</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Входной параметр для принудительной прилоадинга компонента',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается событие клика',
    },
  ]

  props = {
    image: '/img/test.jpg',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    isLoading: false,
    disabled: false,
  }

  get codeView() {
    return generateCodeToString('story', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
