<template>
  <component-page :code-view="codeView">
    <template #description>
      Компонент для заполнения поля Дата с точностью до дня.
      Реализован с помощью выпадающего календаря, на котором можно выбрать год, месяц и число.
    </template>
    <template #component>
      <r-date-picker
        :style="{
          width: props.inline ? 'auto' : '100%'
        }"
        :key="keySlots"
        v-model="model"
        v-bind="props">
        <template #header v-if="dataSlot.header">
          <div class="leon--bg px-4 py-2 fightclub--text caprino">slot header</div>
        </template>
        <template #footer v-if="dataSlot.footer">
          <div class="leon--bg px-4 py-2 fightclub--text caprino">slot footer</div>
        </template>
      </r-date-picker>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="period"
          v-model="props.period"
        />
        <r-switcher
          title="disabled"
          v-model="props.disabled"
        />
        <r-switcher
          title="inline"
          v-model="props.inline"
        />
        <r-switcher
          title="clickClose"
          v-model="props.clickClose"
        />
        <r-switcher
          title="error"
          v-model="props.error"
        />
        <r-switcher
          title="isClearModel"
          v-model="props.isClearModel"
        />
        <r-switcher
          title="isTimestamp"
          v-model="props.isTimestamp"
        />
        <r-switcher
          :disabled="!props.isTimestamp"
          title="timeMillis"
          v-model="props.timeMillis"
        />
        <r-switcher
          title="isSetInput"
          v-model="props.isSetInput"
        />
      </r-row>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Label"
            v-model="props.label" />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-date-picker
            label="StartDate"
            v-model="props.startDate" />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-date-picker
            label="EndDate"
            v-model="props.endDate" />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            :readonly="!props.error"
            label="ErrorMessage"
            v-model="props.errorMessage" />
        </r-col>
      </r-row>
    </template>
    <template #slotsView>
      <r-row
        gap-hidden>
        <r-switcher
          title="header"
          v-model="dataSlot.header"
        />
        <r-switcher
          title="footer"
          v-model="dataSlot.footer"
        />
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
    </template>
    <template #examples>
      <date-picker-all-slots />
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import DatePickerAllSlots from '@/components/examples/DatePicker/DatePickerAllSlots.vue';

@Component({
  components: { DatePickerAllSlots, ComponentPage },
})
export default class DatePicker extends Vue {
  model = null

  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">string, array</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Модель компонента [String, Number (при флаге isTimestamp), Array] (формат YYYY-MM-DD)',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Блокировка действий по кнопке',
    },
    {
      nameProp: '<span class="rocky--text">inline</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Показать контентную часть календарь, без компонентов',
    },
    {
      nameProp: '<span class="rocky--text">clickClose</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Закрывать календарь после выбора даты или периода по второй дате',
    },
    {
      nameProp: '<span class="rocky--text">isClearModel</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Очистка модели по функционалу из компонента RInput',
    },
    {
      nameProp: '<span class="rocky--text">error</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Валидирование поля, если имеется ошибка',
    },
    {
      nameProp: '<span class="rocky--text">isTimestamp</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Возвращает в модель числовую отметку времени',
    },
    {
      nameProp: '<span class="rocky--text">timeMillis</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">true</span>',
      description: 'Работает с timestamp без миллисекунд при значение false',
    },
    {
      nameProp: '<span class="rocky--text">isSetInput</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Ввод даты ручным способом',
    },
    {
      nameProp: '<span class="rocky--text">label</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Название поля',
    },
    {
      nameProp: '<span class="rocky--text">startDate</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'С какой даты будет возможно выбрать (формат YYYY-MM-DD)',
    },
    {
      nameProp: '<span class="rocky--text">endDate</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'По какую дату будет возможно выбрать (формат YYYY-MM-DD)',
    },
    {
      nameProp: '<span class="rocky--text">errorMessage</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Текст ошибки при активном свойстве error',
    },
    {
      nameProp: '<span class="rocky--text">popoverOptions</span>',
      typeData: '<span class="matrix--text">Pick&lt;RPopover,  \'isCheckAutoScrollBlock\'&gt;</span>',
      defaultValue: '<span class="alien--text">{\n'
        + '      isCheckAutoScrollBlock: false\n'
        + '    }</span>',
      description: 'Входной параметр включающий себя надстройки компонента RPopover',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
    {
      nameEvent: '<span class="rocky--text">change</span>',
      description: 'Возвращается состояние модели компонента при полном выборе даты (в рамках периода, когда выбирается вторая дата)',
    },
    {
      nameEvent: '<span class="rocky--text">focusout</span>',
      description: 'Возврощается событие при закрытие списка выбора',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">header</span>',
      description: 'Контентная область слота над календарем',
    },
    {
      nameSlot: '<span class="rocky--text">footer</span>',
      description: 'Контентная область слота под календарем',
    },
  ]

  props = {
    isClearModel: false,
    disabled: false,
    inline: false,
    clickClose: false,
    period: false,
    error: false,
    isTimestamp: false,
    timeMillis: true,
    isSetInput: false,
    label: 'Label text',
    startDate: null,
    endDate: null,
    errorMessage: null,
  }

  dataSlot = {
    header: false,
    footer: false,
  }

  get codeView() {
    return generateCodeToString('date-picker', this.props, this.dataSlot);
  }

  get keySlots() {
    return JSON.stringify(this.dataSlot) + JSON.stringify(this.props);
  }

  @Watch('props.period')
  changePeriod() {
    this.model = null;
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
