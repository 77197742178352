<template>
  <component-page :code-view="codeView">
    <template #description>

    </template>
    <template #component>
      <r-shorter
        v-bind="props"
      />
    </template>
    <template #props>
      <div id="canvas"></div>
      <r-row
        class="mb-5"
        gap-hidden>
        <div style="display: flex; flex-direction: column">
          <span class="mb-2 troy--text parmigiano">countRow</span>
          <r-input-number v-model="props.countRow"/>
        </div>
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="Text"
            :is-clear="false"
            v-model="props.text"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="Description"
            :is-clear="false"
            v-model="props.description"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="ContentClass"
            :is-clear="false"
            v-model="props.contentClass"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class Shorter extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">text</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Текст для контектсной части',
    },
    {
      nameProp: '<span class="rocky--text">description</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Уникальное описание для тултипа при наведение (если пустое, то будет выводиться входной параметр text)',
    },
    {
      nameProp: '<span class="rocky--text">countRow</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="rocky--text">5</span>',
      description: 'Количество строк',
    },
    {
      nameProp: '<span class="rocky--text">contentClass</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Уникальный класс для контента',
    },
    {
      nameProp: '<span class="rocky--text">tooltipOptions</span>',
      typeData: '<span class="fargo--text">RTooltip</span>',
      defaultValue: '<span class="alien--text">{}</span>',
      description: 'Опция входных параметром компонента RTooltip',
      children: [
        {
          nameProp: '<span class="rocky--text">position</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="matrix--text">"bottom-center"</span>',
          description: 'Позиционирование компонента по контенту',
        },
        {
          nameProp: '<span class="rocky--text">alignTitle</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="matrix--text">"start"</span>',
          description: 'Определение в какую сторону устанавливать стрелку',
        },
        {
          nameProp: '<span class="rocky--text">maxWidth</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="matrix--text">"150px"</span>',
          description: 'Ширина контента',
        },
        {
          nameProp: '<span class="rocky--text">isArrow</span>',
          typeData: '<span class="matrix--text">boolean</span>',
          defaultValue: '<span class="fightclub--text">false</span>',
          description: 'Отображать стрелку для центрирование по компоненту',
        },
      ],
    },
  ]

  props = {
    // eslint-disable-next-line max-len
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    description: null,
    contentClass: null,
    countRow: 5,
    tooltipOptions: {
      isArrow: true,
      maxWidth: '320px',
    },
  }

  get codeView() {
    return generateCodeToString('shorter', this.props);
  }
}
</script>
