<template>
  <examples-block
    gitUrl="src/components/examples/Input/InputPhone.vue"
    title="Поле ввода для телефонов">
    <div style="width: 300px;">
      <r-input v-mask="'(###) ### ## ##'" label="Номер телефона" v-model="model">
        <template #content>
          <r-popover class="mr-1" click-close>
            <template #activator="{ on }">
              <r-button-action :title="codeModel" is-arrow color="harakiri" v-on="on"/>
            </template>
            <template #content>
              <div class="px-2 pb-2 pt-1">
                <r-list-item v-for="code in listCode" :val="code" :key="code" :title="code" :value="codeModel" @input="changeModel" class="mt-1"/>
              </div>
            </template>
          </r-popover>
        </template>
      </r-input>
    </div>
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';
import { mask } from 'vue-the-mask';

export default {
  name: 'InputPhone',
  components: { ExamplesBlock },
  directives: { mask },
  data: () => ({
    model: null,
    codeModel: '+7',
    listCode: ['+7', '+375'],
  }),
  methods: {
    changeModel(val) {
      if (val) {
        this.codeModel = val;
      }
    },
  },
};
</script>
