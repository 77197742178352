<template>
  <component-page :code-view="codeView">
    <template #description>
      Плашка с тенью, всплывающая поверх интерфейса при клике на вызывающий её компонент
    </template>
    <template #component>
      <r-popover
        :key="props.position"
        v-bind="props"
      >
        <template #activator="{ on }">
          <r-button
            title="activator"
            v-on="on"
            size="laripha"
            rounded
          />
        </template>
        <template #content>
          <div class="pa-3 briscola" style="text-align: center">content</div>
        </template>
      </r-popover>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="disabled"
          v-model="props.disabled"
        />
        <r-switcher
          title="fixed"
          v-model="props.fixed"
        />
        <r-switcher
          title="clickClose"
          v-model="props.clickClose"
        />
        <r-switcher
          title="contentWidthActivator"
          v-model="props.contentWidthActivator"
        />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Position"
            v-model="props.position"
            :items="dataPosition"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="ContentCoefficientWidth"
            v-model="props.contentCoefficientWidth"
            :items="dataContentCoefficientWidth"
            :disabled="!props.contentWidthActivator"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="ContentClass"
            v-model="props.contentClass"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        class="mb-8"
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
      <div class="taleggio mb-8">SlotsScoped</div>
      <r-table
        class="mb-8"
        :headers="headersSlotsScoped"
        :items="itemsSlotsScoped"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
        <template #props="{ item }">
          <div v-html="item.props" />
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
    <template #examples>
      <popover-init-content-scroll />
      <popover-selected-badge />
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import PopoverInitContentScroll from '@/components/examples/Popover/PopoverInitContentScroll.vue';
import PopoverSelectedBadge from '@/components/examples/Popover/PopoverSelectedBadge.vue';

@Component({
  components: { PopoverInitContentScroll, ComponentPage, PopoverSelectedBadge },
})
export default class Popover extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlotsScoped = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'props',
      name: 'props',
      description: 'Входные параметры слота',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Модель состояния области контента',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Входной параметр для блокировки компонента',
    },
    {
      nameProp: '<span class="rocky--text">fixed</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Фиксирование расположение контента по окну браузера',
    },
    {
      nameProp: '<span class="rocky--text">clickClose</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Закрытие контента после клика в область контента',
    },
    {
      nameProp: '<span class="rocky--text">contentWidthActivator</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Применять дилину активатора для контента',
    },
    {
      nameProp: '<span class="rocky--text">isCheckAutoScrollBlock</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Свойство для автоматического поиска родителя у которого имеется скрол, для встраивания внутрь данного блока (требуется у данных блоков свойства position)',
    },
    {
      nameProp: '<span class="rocky--text">idBlockAppend</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fightclub--text">null</span>',
      description: 'Свойство для принудительного встраивание область контента в блок с указанным идентификатором блока',
    },
    {
      nameProp: '<span class="rocky--text">contentClass</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Указать дополнительный класс контенту',
    },
    {
      nameProp: '<span class="rocky--text">position</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">center-bottom</span>',
      description: 'Позиционирование контента от области активатора',
    },
    {
      nameProp: '<span class="rocky--text">contentCoefficientWidth</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="matrix--text">1</span>',
      description: 'Управление размером контента по отношению к активатору (работает при входном'
        + ' параметре "contentWidthActivator" и в разрешениях wide и widest)',
    },
    {
      nameProp: '<span class="rocky--text">isCheckAutoScrollBlock</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="matrix--text">false</span>',
      description: 'Входной параметр определяющий искать контент с скролом автоматически',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">content</span>',
      description: 'Область контента',
    },
  ]

  itemsSlotsScoped = [
    {
      nameSlot: '<span class="rocky--text">activator</span>',
      props: '<span class="rocky--text">{ on: { click: EventActivator } }</span>',
      description: 'Область активатора',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается значение модели',
    },
    {
      nameEvent: '<span class="rocky--text">close</span>',
      description: 'Событие закрытие контента',
    },
  ]

  props = {
    disabled: false,
    fixed: false,
    clickClose: false,
    contentWidthActivator: false,
    contentClass: null,
    contentCoefficientWidth: 1,
    position: 'center-bottom',
  }

  dataPosition = [
    {
      id: 'center-bottom',
      title: 'center-bottom',
    },
    {
      id: 'center-top',
      title: 'center-top',
    },
    {
      id: 'left-bottom',
      title: 'left-bottom',
    },
    {
      id: 'left-top',
      title: 'left-top',
    },
    {
      id: 'right-bottom',
      title: 'right-bottom',
    },
    {
      id: 'right-top',
      title: 'right-top',
    },
    {
      id: 'bottom-left',
      title: 'bottom-left',
    },
    {
      id: 'bottom-right',
      title: 'bottom-right',
    },
    {
      id: 'top-left',
      title: 'top-left',
    },
    {
      id: 'top-right',
      title: 'top-right',
    },
  ]

  dataContentCoefficientWidth = [
    {
      id: 1,
      title: '1',
    },
    {
      id: 1.2,
      title: '1.2',
    },
    {
      id: 1.4,
      title: '1.4',
    },
    {
      id: 1.6,
      title: '1.6',
    },
    {
      id: 1.8,
      title: '1.8',
    },
    {
      id: 2,
      title: '2',
    },
  ]

  slotsView = {
    activator: true,
    content: true,
  }

  get codeView() {
    return generateCodeToString('popover', this.props, this.slotsView);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
