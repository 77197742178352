<template>
  <component-page>
    <template #description>
      Модальные окна отображаются в полный экран на белом не прозрачном фоне
    </template>
    <template #component>
      <r-button
        title="open modal"
        @click="openModal"
      />
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden
      >
        <r-switcher
          v-model="optionsModal.isClose"
          title="optionsModal.isClose"
          />
        <r-switcher
          v-model="optionsModal.isFixedActionsBlock"
          title="optionsModal.isFixedActionsBlock"
          />
        <r-switcher
          v-model="optionsModal.isCloseEventInitComponent"
          title="optionsModal.isCloseEventInitComponent"
          />
      </r-row>
      <r-row
        gap-hidden
      >
        <r-input
          v-model="optionsModal.backText"
          label="optionsModal.backText"
          />
      </r-row>
    </template>
    <template #api>
      <div class="troy--text parmigiano mb-6">Требуется в App.vue (основной шаблон при инициализации) вставить компонент r-modal </div>
      <div class="taleggio mb-8">Глобальные методы библиотеки</div>
      <r-table
        class="mb-8"
        :headers="headersMethods"
        :items="itemsMethod"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Отслеживаемые блоки внутри контента</div>
      <r-table
        class="mb-8"
        :headers="headersWatchBlock"
        :items="itemsWatchBlock"
      >
        <template #idBlock="{ item }">
          <div v-html="item.idBlock"/>
        </template>
        <template #description="{ item }">
          <div v-html="item.description"/>
        </template>
      </r-table>
    </template>
    <template #examples>
      <second-modal-open-in-popover />
      <setting-modal />
      <fixed-footer-modal />
      <uploader-modal />
      <callback-close-and-back />
      <close-event-in-component />
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ComponentPage from '@/layouts/ComponentPage.vue';
import ModalPage from '@/components/ModalPage.vue';
import SecondModalOpenInPopover from '@/components/examples/Modal/SecondModalOpenInPopover.vue';
import SettingModal from '@/components/examples/Modal/SettingModal.vue';
import FixedFooterModal from '@/components/examples/Modal/FixedFooterModal.vue';
import UploaderModal from '@/components/examples/Modal/UploaderModal.vue';
import CallbackCloseAndBack from '@/components/examples/Modal/CallbackCloseAndBack.vue';
import CloseEventInComponent from '@/components/examples/Modal/CloseEventInComponent.vue';

@Component({
  components: {
    CloseEventInComponent,
    CallbackCloseAndBack,
    UploaderModal,
    FixedFooterModal,
    SettingModal,
    SecondModalOpenInPopover,
    ComponentPage,
  },
})
export default class Modal extends Vue {
  headersMethods = [
    {
      field: 'nameProp',
      name: 'Метод',
      description: 'Метод для вызовов',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Параметры метода',
      description: 'Параметры',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
      slot: true,
    },
  ]

  headersWatchBlock = [
    {
      field: 'idBlock',
      name: 'Идентификатор блока',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание',
      slot: true,
    },
  ]

  itemsMethod = [
    {
      nameProp: '<span class="rocky--text">async $rir.modal.open</span>',
      typeData: '<span class="matrix--text">(component: VueConstructor, props: { [key: string]: any }, optionsModal?: OptionsModal)</span>',
      description: 'Глобальный метод для вызова модального окна',
      children: [
        {
          nameProp: '<span class="rocky--text">component</span>',
          typeData: '<span class="matrix--text">VueConstructor</span>',
          description: 'Компонент для вставки в контентую область модального окна',
        },
        {
          nameProp: '<span class="rocky--text">props</span>',
          typeData: '<span class="matrix--text">{ [key: string]: any }</span>',
          description: 'Входные параметры для передаваемого компонента',
        },
        {
          nameProp: '<span class="rocky--text">optionsModal</span>',
          typeData: '<span class="matrix--text">OptionsModal | undefined</span>',
          description: 'Опции для модального окна',
        },
      ],
    },
    {
      nameProp: '<span class="rocky--text">$rir.modal.close</span>',
      typeData: '<span class="matrix--text">(promiseReturn: any, destroyFull: boolean)</span>',
      description: 'Глобальный метод для закрытия модального окна. '
        + '\r\n promiseReturn - параметр для возврата ответа в вызываемую область, если destroyFull = true, то ответ вернется в саммый первый вызов'
        + '\r\n destroyFull - параметр для закрытие каскадного вызова окон.',
    },
  ]

  itemsWatchBlock = [
    {
      idBlock: '<span class="rocky--text">#RModal--actions</span>',
      description: 'Обертка с данным идентификатором, отслеживается для фиксации снизу модального окна при появление скрола или параметре isFixedActionsBlock',
    },
  ]

  optionsModal = {
    isClose: true,
    backText: null,
    isFixedActionsBlock: false,
    isCloseEventInitComponent: false,
  }

  async openModal() {
    const data = await this.$rir.modal.open(ModalPage, {}, this.optionsModal);
    setTimeout(() => {
      console.log(data);
    });
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
