<template>
  <div id="app">
    <r-modal />
    <r-notification />
    <component :is="layout" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  layout = () => import('@/layouts/EmptyPage.vue')

  mounted() {
    // @TODO доработать определение инциализации приложения
    let { layout } = this.$route.meta;
    if (layout === 'Dashboard') {
      layout = localStorage.getItem('layout') || 'Dashboard';
    }
    this.layout = () => import(`@/layouts/${layout || 'Empty'}Page.vue`);
    // this.layout = () => import('@/layouts/SitePage.vue');
  }

  @Watch('$route.meta.layout')
  changeLayout(val) {
    let layout = val;
    if (layout === 'Dashboard') {
      layout = localStorage.getItem('layout') || 'Dashboard';
    }
    this.layout = () => import(`@/layouts/${layout || 'Empty'}Page.vue`);
  }
}

</script>

<style lang="scss">

</style>
