<template>
  <div class="form">
    <r-switcher title="Disabled" v-model="disabled" />
    <r-input label="input" v-model="input" :readonly="disabled"/>
    <r-select label="select Arr" v-model="selectArr" :items="items" :disabled="disabled"/>
    <r-date-picker label="data picker" v-model="date" end-date="2023-04-15" :disabled="disabled" />
    <r-time-picker label="time" v-model="time" :disabled="disabled"/>
    <r-time-picker label="time Arr" v-model="timeArr" :disabled="disabled"/>
    <r-switcher title="switcher" v-model="switcher" :disabled="disabled"/>
    <r-checkbox title="checkbox" v-model="checkbox" :disabled="disabled"/>
    <r-radio-button title="radio button" v-model="radio" :disabled="disabled"/>
    <r-textarea label="textarea" v-model="textarea" :readonly="disabled"/>
    <r-list-item title="list item" v-model="list" val="1" :disabled="disabled"/>
    <r-list-item title="list item" v-model="list" val="2"/>
    <r-select label="select" v-model="select" :items="items" :disabled="disabled"/>
    <r-accordion
      v-if="switcher"
      is-shape
      title="Название"
      is-opening
    >
      <template #actions>
        <div
          class="d-flex align-items-center justify-content-center rocky--text avengers--bg"
          style="height: 100%"
        >
          actions
        </div>
      </template>
      <template #content>
        <div
          ref="content"
          class="d-flex align-items-center justify-content-center rocky--text avengers--bg"
          style="height: 500px"
        >

          <r-select label="select" v-model="select" :items="items" is-search :disabled="disabled"/>
        </div>
      </template>
    </r-accordion>
  </div>
</template>

<script>
export default {
  data: () => ({
    disabled: false,
    items: [
      {
        id: 1,
        title: 'test 1',
      },
      {
        id: 2,
        title: 'test 2',
      },
    ],
    input: null,
    textarea: null,
    select: null,
    date: null,
    time: null,
    timeArr: [],
    selectArr: [],
    switcher: false,
    checkbox: false,
    radio: false,
    list: [],
  }),
};
</script>

<style lang="scss" scoped>
.form{
  & > * {
    margin-bottom: 24px;
  }
}
</style>
