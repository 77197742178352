<template>
  <component-page :code-view="codeView">
    <template #description>
      Гиперссылки
    </template>
    <template #component>
      <r-button-link
        v-bind="props"
      />
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="notShorter"
          v-model="props.notShorter"
        />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Title"
            v-model="props.title"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Color"
            v-model="props.color"
            :items="dataColor"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Size"
            v-model="props.size"
            :items="dataSize"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class ButtonLink extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Текст контента',
    },
    {
      nameProp: '<span class="rocky--text">color</span>',
      typeData: '<span class="matrix--text">ColorComponent</span>',
      defaultValue: '<span class="rocky--text">"alien"</span>',
      description: 'Цвет полоски и фона при навведение',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="rocky--text">"salamis"</span>',
      description: 'Размер компонента',
    },
    {
      nameProp: '<span class="rocky--text">notShorter</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Отключение использования RShorter внутри компонента',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">[$listener]</span>',
      description: 'Все переданные эвенты будут привязываться к компоненту',
    },
  ]

  props = {
    title: 'Hypertext link',
    color: 'alien',
    size: 'salamis',
    notShorter: false,
  }

  dataColor = [
    {
      id: 'alien',
      title: 'alien',
    },
    {
      id: 'drive',
      title: 'drive',
    },
    {
      id: 'indiana',
      title: 'indiana',
    },
    {
      id: 'snatch',
      title: 'snatch',
    },
    {
      id: 'pulpfiction',
      title: 'pulpfiction',
    },
    {
      id: 'seven',
      title: 'seven',
    },
    {
      id: 'shershaah',
      title: 'shershaah',
    },
    {
      id: 'mummy',
      title: 'mummy',
    },
  ]

  dataSize = [
    {
      id: 'salamis',
      title: 'salamis',
    },
    {
      id: 'xanthe',
      title: 'xanthe',
    },
  ]

  get codeView() {
    return generateCodeToString('button-link', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
