<template>
  <component-page :code-view="codeView" color-preview-block="trainspotting">
    <template #description>
      Компонент древовидного списка
    </template>
    <template #component>
      <div :key="props.allOpen" class="d-flex flex-direction-column">
        <r-tree-list
          v-for="item in items"
          :key="item.id"
          :item="item"
          v-bind="props"
          @click="clickActions"
        >
          <template #content="{ item }">
            <div class="d-flex align-items-center">
              <r-icon
                icon="eye"
                fill="fargo"
                class="mr-2"
                v-if="item.id % 2 === 0"
              />
              <span class="sulguni">{{ item.title }}</span>
            </div>
          </template>
          <template
            #actions="{ item }">
            <r-button-simple
              v-if="item.id % 2 !== 0"
              type="transparent"
              color="rocky"
              icon="add"
              @click.stop="clickActions(item)"
            />
          </template>
        </r-tree-list>
      </div>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="isParentClicked"
          v-model="props.isParentClicked"
        />
        <r-switcher
          title="allOpen"
          v-model="props.allOpen"
        />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="Active Item"
            type-input="number"
            v-model="props.activeItem"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">SlotsScoped</div>
      <r-table
        class="mb-8"
        :headers="headersSlotsScoped"
        :items="itemsSlotsScoped"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
        <template #props="{ item }">
          <div v-html="item.props" />
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class TreeList extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlotsScoped = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'props',
      name: 'props',
      description: 'Входные параметры слота',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">item</span>',
      typeData: '<span class="matrix--text">Object</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Элемент дерева',
    },
    {
      nameProp: '<span class="rocky--text">childrenValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">children</span>',
      description: 'Входной параметр для определения списка потомков в элементе дерева',
    },
    {
      nameProp: '<span class="rocky--text">idValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">id</span>',
      description: 'Идентификатор элемента дерева',
    },
    {
      nameProp: '<span class="rocky--text">isParentClicked</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Отрабатывать клик по контенту элемента и возвращает $emit',
    },
    {
      nameProp: '<span class="rocky--text">allOpen</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Раскрывать все элементы',
    },
    {
      nameProp: '<span class="rocky--text">activeItem</span>',
      typeData: '<span class="matrix--text">String, Number, Object</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Выбранный элемент для UI выделения',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается элемент по клику',
    },
  ]

  itemsSlotsScoped = [
    {
      nameSlot: '<span class="rocky--text">content</span>',
      props: '<span class="rocky--text">{ item: ItemTree }</span>',
      description: 'Слот для контента элемента',
    },
    {
      nameSlot: '<span class="rocky--text">actions</span>',
      props: '<span class="rocky--text">{ item: ItemTree }</span>',
      description: 'Слот для разных событий у элемента',
    },
  ]

  props = {
    activeItem: null,
    isParentClicked: false,
    allOpen: false,
  }

  items = [
    {
      id: 1,
      title: 'Title 1',
      children: [
        {
          id: 11,
          title: 'Title 1-1',
          children: [
            {
              id: 111,
              title: 'Title 1-1-1',
              children: [
                {
                  id: 1111,
                  title: 'Title 1-1-1-1',
                },
                {
                  id: 1112,
                  title: 'Title 1-1-1-2',
                },
                {
                  id: 1113,
                  title: 'Title 1-1-1-3',
                },
              ],
            },
            {
              id: 112,
              title: 'Title 1-1-2',
            },
            {
              id: 113,
              title: 'Title 1-1-3',
            },
          ],
        },
        {
          id: 12,
          title: 'Title 1-2',
        },
        {
          id: 13,
          title: 'Title 1-3',
        },
      ],
    },
    {
      id: 2,
      title: 'Title 2',
    },
    {
      id: 3,
      title: 'Title 3',
    },
    {
      id: 4,
      title: 'Title 4',
      children: [
        {
          id: 41,
          title: 'Title 4-1',
        },
        {
          id: 42,
          title: 'Title 4-2',
        },
        {
          id: 43,
          title: 'Title 4-3',
        },
      ],
    },
  ]

  get codeView() {
    return generateCodeToString('tree-list', this.props);
  }

  clickActions(item) {
    console.log('actions', item, item.id);
    this.props.activeItem = item.id;
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
