<template>
  <component-page :code-view="codeView">
    <template #description>
      Подсказка, которая выводится по наведению на область
    </template>
    <template #component>
      <r-tooltip
        v-bind="props">
        <template #activator>
          <span
            style="
            width: 104px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center"
            class="metropolis--bg white--text">
            content
          </span>
        </template>
      </r-tooltip>
    </template>
    <template #props>
      <r-row
        class="mb-6" gap-hidden>
        <r-switcher
          title="isArrow"
          v-model="props.isArrow"
          />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Position"
            v-model="props.position"
            :items="dataPosition"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Title"
            v-model="props.title"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="AlignTitle"
            v-model="props.alignTitle"
            :items="dataAlignTitle"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">SlotsScoped</div>
      <r-table
        class="mb-8"
        :headers="headersSlotsScoped"
        :items="itemsSlotsScoped"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
        <template #props="{ item }">
          <div v-html="item.props" />
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        class="mb-8"
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
    </template>
    <template #examples>
      <tooltip-in-button />
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import TooltipInButton from '@/components/examples/Tooltip/TooltipInButton.vue';

@Component({
  components: { TooltipInButton, ComponentPage },
})
export default class Tooltip extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlotsScoped = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'props',
      name: 'props',
      description: 'Входные параметры слота',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Текст описание в контенте',
    },
    {
      nameProp: '<span class="rocky--text">position</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"bottom-center"</span>',
      description: 'Позиционирование компонента по контенту',
    },
    {
      nameProp: '<span class="rocky--text">alignTitle</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"start"</span>',
      description: 'Определение в какую сторону устанавливать стрелку',
    },
    {
      nameProp: '<span class="rocky--text">maxWidth</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"150px"</span>',
      description: 'Ширина контента',
    },
    {
      nameProp: '<span class="rocky--text">isArrow</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Отображать стрелку для центрирование по компоненту',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Блокировка компонента для вывода подсказки',
    },
  ]

  itemsSlotsScoped = [
    {
      nameSlot: '<span class="rocky--text">activator</span>',
      props: '<span class="rocky--text">{}</span>',
      description: 'Область активатора',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">content</span>',
      description: 'Область контента (приоритетнее входного параметра title)',
    },
  ]

  props = {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    position: 'bottom-center',
    isArrow: false,
    alignTitle: 'start',
    maxWidth: '150px',
  }

  dataPosition = [
    {
      id: 'top-center',
      title: 'top-center',
    },
    {
      id: 'center-left',
      title: 'center-left',
    },
    {
      id: 'bottom-center',
      title: 'bottom-center',
    },
    {
      id: 'center-right',
      title: 'center-right',
    },
  ]

  dataAlignTitle= [
    {
      id: 'start',
      title: 'start',
    },
    {
      id: 'end',
      title: 'end',
    },
  ]

  dataSlot = {
    activator: true,
  }

  get codeView() {
    return generateCodeToString('tooltip', this.props, this.dataSlot);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
