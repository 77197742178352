<template>
  <component-page :code-view="codeView">
    <template #description>
      Компонент Хлебные крошки используется в навигации с большой  вложенностью
    </template>
    <template #component>
      <r-bread-crumbs
        :key="`${countItems}`"
        :items="items"
        v-bind="props"
      />
    </template>
    <template #props>
      <r-input-number
        v-model="countItems"
      />
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
    <template #examples>
      <bread-crumbs-disabled-element />
      <bread-crumbs-icons />
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import BreadCrumbsDisabledElement
  from '@/components/examples/BreadCrumbs/BreadCrumbsDisabledElement.vue';
import BreadCrumbsIcons from '@/components/examples/BreadCrumbs/BreadCrumbsIcons.vue';

@Component({
  components: { BreadCrumbsIcons, BreadCrumbsDisabledElement, ComponentPage },
})
export default class BreadCrumbs extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">items</span>',
      typeData: '<span class="matrix--text">array</span>',
      defaultValue: '<span class="rocky--text">[]</span>',
      description: 'Список для вывода хлебных крошек',
      children: [
        {

          nameProp: '<span class="rocky--text">title</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="alien--text">undefined</span>',
          description: 'Наименование хлебной крошки',
        },
        {

          nameProp: '<span class="rocky--text">icon</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="alien--text">undefined</span>',
          description: 'Иконка перед наименованием',
        },
        {
          nameProp: '<span class="rocky--text">to</span>',
          typeData: '<span class="matrix--text">string | RouterPushParams</span>',
          defaultValue: '<span class="alien--text">undefined</span>',
          description: 'Параметр для применение роутинга VUE в рамках экосистемы Vue-router. Формат данных, равен :to в router-link',
        },
        {
          nameProp: '<span class="rocky--text">href</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="alien--text">undefined</span>',
          description: 'Параметр для применение прямой ссылки, данный вариант открыват ссылку в новой вкладке',
        },
        {
          nameProp: '<span class="rocky--text">active</span>',
          typeData: '<span class="matrix--text">boolean</span>',
          defaultValue: '<span class="alien--text">undefined</span>',
          description: 'Параметр определяющий активность хлебной крошки в списке (Если не указан по дефолту true)',
        },
      ],
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается событие клика по элементу и возвращает объект данного элемента',
    },
  ]

  countItems = 7

  props = {}

  get items() {
    const items = [];
    for (let i = 1; i <= this.countItems; i++) {
      items.push({
        // eslint-disable-next-line no-nested-ternary
        title: i !== 1 ? `page ${i === 1 ? 'start ' : i === this.countItems ? 'end ' : ''}${i}` : null,
        icon: i === 1 ? 'home' : null,
      });
    }
    return items;
  }

  get codeView() {
    return generateCodeToString('bread-crumbs', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
