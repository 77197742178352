<template>
  <component-page :code-view="codeView">
    <template #description>
      Компонент отображения загруженных или выбранных файлов и прочих сущностей:
      документов, профайлов и т.п.
    </template>
    <template #component>
      <r-file
        :key="props.type"
        v-bind="props"
        :file="fileData"
        :button-simple-option="buttonSimpleData"
      />
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="isFIleImage"
          v-model="isFIleImage"
        />
        <r-switcher
          title="isButtonSimple"
          v-model="isButtonSimple"
        />
      </r-row>
      <r-row
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-select
              label="Type"
              :items="dataType"
              v-model="props.type"
              />
          </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="IconPreview"
            :icon="props.iconPreview"
            v-model="props.iconPreview"
            :items="dataIcons"
            is-search
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-input
            :is-clear="false"
              label="Title"
              v-model="props.title"
              />
          </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-input
            :is-clear="false"
              label="SubTitle"
              v-model="props.subTitle"
              />
          </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class File extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">type</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"default"</span>',
      description: 'Тип отображения компонента',
    },
    {
      nameProp: '<span class="rocky--text">iconPreview</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"file"</span>',
      description: 'Иконка для отображение, если файл не является изображением',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Текст в область контента компонента',
    },
    {
      nameProp: '<span class="rocky--text">subTitle</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Дополнительное описание в область контента компонента',
    },
    {
      nameProp: '<span class="rocky--text">file</span>',
      typeData: '<span class="fargo--text">File</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Данные файла по формату конструтору File',
      children: [
        {
          nameProp: '<span class="alien--text">type</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Формат MIME-тип файла.',
        },
        {
          nameProp: '<span class="alien--text">url</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Прямая ссылка на файл',
        },
      ],
    },
    {
      nameProp: '<span class="rocky--text">buttonSimpleOption</span>',
      typeData: '<span class="fargo--text">RButtonSimple</span>',
      defaultValue: '<span class="matrix--text">undefined</span>',
      description: 'Входные параметры от компонента RButtonSimple, кроме type и size свойств',
      children: [
        {
          nameProp: '<span class="alien--text">color</span>',
          typeData: '<span class="matrix--text">string</span>',
          description: 'Цвет иконки',
        },
        {
          nameProp: '<span class="alien--text">icon</span>',
          typeData: '<span class="matrix--text">string</span>',
          description: 'Иконка',
        },
      ],
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается событие клика по всей области компонента и возвращает данные входного параметра "file"',
    },
    {
      nameEvent: '<span class="rocky--text">clickPreview</span>',
      description: 'Возвращается событие клика по области preview и возвращает данные входного параметра "file"',
    },
    {
      nameEvent: '<span class="rocky--text">actions</span>',
      description: 'Возвращается событие клика по области actions [RButtonSimple] и возвращает данные входного параметра "file"',
    },
  ]

  isFIleImage = true

  isButtonSimple = false

  props = {
    type: 'default',
    iconPreview: 'file',
    title: 'File name',
    subTitle: 'File desc',
  }

  get fileData() {
    return {
      type: this.isFIleImage ? 'image/png' : 'video/mp4',
      url: '/img/test.jpg',
    };
  }

  get buttonSimpleData() {
    return this.isButtonSimple ? {
      color: 'fargo',
      icon: 'delete',
    } : null;
  }

  get dataIcons() {
    return getIconName(this.props.type === 'default' ? '_20' : '_16').map((el) => ({
      id: el,
      title: el,
      icon: el,
    }));
  }

  @Watch('props.type')
  changeType() {
    this.props.iconPreview = 'file';
  }

  dataType = [
    {
      id: 'default',
      title: 'default',
    },
    {
      id: 'simple',
      title: 'simple',
    },
  ]

  get codeView() {
    return generateCodeToString('file', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
