<template>
  <div class="pt-4 component">
    <r-row class="mb-12">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="troy--text parmigiano">
          <slot name="description" />
        </span>
      </r-col>
    </r-row>
    <r-row class="mb-8">
      <r-col
        :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        <r-tabs
          :items="itemsTabs"
          v-model="tabActive"
        />
      </r-col>
    </r-row>
    <template v-if="isTabs('component')">
      <r-row>
        <r-col
          :cols="{ widest: codeView ? 8 : 12, wide: codeView ? 8 : 12, middle: 6, narrow: 6 }">
          <r-row
            gap-hidden
            class="mb-4">
            <r-col
              :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
              <span class="taleggio">Preview</span>
            </r-col>
          </r-row>
          <r-row
            gap-hidden
            class="mb-6">
            <r-col
              :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
              <div class="pa-6 component__view" :class="[`${colorPreviewBlock}--bg`]">
                <slot name="component" />
              </div>
            </r-col>
          </r-row>
          <template v-if="$slots.props">
            <r-row
              gap-hidden
              class="mb-6">
              <r-col
                :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
                <span class="taleggio">Props</span>
              </r-col>
            </r-row>
            <slot name="props" />
          </template>
          <r-row
            v-if="!!$slots.slotsView"
            gap-hidden
            class="mb-6">
            <r-col
              :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
              <span class="taleggio">Slots</span>
            </r-col>
          </r-row>
          <slot name="slotsView" />
        </r-col>
        <r-col
          v-if="codeView"
          :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
          <r-row
            class="mb-3"
            gap-hidden>
            <r-col
              :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
              <span class="taleggio mr-2">Code</span>
              <r-button-simple icon="copy" rounded type="secondary" @click="copyCode"/>
            </r-col>
          </r-row>
          <r-row
            gap-hidden>
            <r-col
              :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
              <prism-editor
                language="html"
                v-model="codeView"
                :highlight="highlight"
                readonly
              />
            </r-col>
          </r-row>
        </r-col>
      </r-row>
    </template>
    <template v-if="isTabs('api')">
      <r-row
        :wrap="false"
        :snap="false"
      >
        <r-col
          :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
          <slot name="api" />
        </r-col>
      </r-row>
    </template>
    <template v-if="isTabs('examples')">
      <slot name="examples" />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-javascript';

@Component
export default class ComponentPage extends Vue {
  tabActive = {
    id: 'component',
    title: 'Component',
  }

  @Prop({ type: String, default: '' }) codeView!: string

  @Prop({ type: String, default: 'amelie' }) colorPreviewBlock!: string

  // eslint-disable-next-line class-methods-use-this
  highlight(code) {
    return highlight(
      code,
      {
        ...languages.markup,
        ...languages.js,
        ...languages.css,
      },
      'markup',
    );
  }

  get itemsTabs() {
    return [
      {
        id: 'component',
        title: 'Component',
        disabled: !this.$slots.component,
      },
      {
        id: 'api',
        title: 'API',
        disabled: !this.$slots.api,
      },
      {
        id: 'examples',
        title: 'Examples',
        disabled: !this.$slots.examples,
      },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  copyCode() {
    const textarea: HTMLTextAreaElement = document.getElementsByClassName('prism-editor__textarea')[0] as HTMLTextAreaElement;
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    // window.navigator.clipboard.writeText(textarea.value);
    textarea.focus();
    this.$rir.notification.send({
      hiddenClose: true,
      title: 'Код скопирован',
      seconds: 3,
    });
  }

  isTabs(idTab) {
    return this.tabActive.id === idTab;
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
