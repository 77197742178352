<template>
  <component-page :code-view="codeView">
    <template #description>
      Компонент таймера, который, через заданное время выполняет нужное действие, например, закрытие уведомления
    </template>
    <template #component>
      <r-timer
        :key="JSON.stringify(props)"
        v-bind="props"
        @end="checkEmit"
      />
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <div style="display: flex; flex-direction: column">
          <span class="mb-2 troy--text parmigiano">Seconds</span>
          <r-input-number v-model="props.seconds"/>
        </div>
      </r-row>
      <r-row gap-hidden>
        <r-select
          label="Size"
          v-model="props.size"
          :items="dataSize"
          />
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class Timer extends Vue {
  checkEmit() {
    console.log('EMIT');
  }

  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">aethusa</span>',
      description: 'Размер спинера',
    },
    {
      nameProp: '<span class="rocky--text">seconds</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="alien--text">10</span>',
      description: 'Количество секунд до вызова emit("end")',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">end</span>',
      description: 'Возвращает событие окончание таймера',
    },
  ]

  props = {
    size: 'aethusa',
    seconds: 5,
  }

  dataSize = [
    {
      id: 'aethusa',
      title: 'aethusa',
    },
    {
      id: 'menolorice',
      title: 'menolorice',
    },
    {
      id: 'echemeia',
      title: 'echemeia',
    },
  ]

  get codeView() {
    return generateCodeToString('timer', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
