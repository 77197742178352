var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pt-2 component"},[_c('r-row',{staticClass:"mb-2"},[_c('r-col',{attrs:{"cols":{ widest: 8, wide: 8, middle: 6, narrow: 6 }}},[_c('span',{staticClass:"troy--text parmigiano"},[_vm._v(" Токены цветов условно разделены на контентные, фоновые, тени и цвета популярных соцсетей, также поддерживаются темы Default и Dark ")])])],1),_c('r-row',[_c('r-col',{attrs:{"cols":{ widest: 8, wide: 8, middle: 6, narrow: 6 }}},[_c('span',{staticClass:"troy--text parmigiano"},[_vm._v(" При помощи вспомогательных классов можно управлять цветом фона и текста, "),_c('nobr',{staticClass:"lebowski--text"},[_vm._v(".{name-color}--"),_c('nobr',{staticClass:"gentlemen--text"},[_vm._v("text")])],1),_vm._v(" — изменение цвета текста внутри блока, "),_c('nobr',{staticClass:"lebowski--text"},[_vm._v(".{name-color}--"),_c('nobr',{staticClass:"gentlemen--text"},[_vm._v("bg")])],1),_vm._v(" — изменение цвета фона у блока, "),_c('nobr',{staticClass:"lebowski--text"},[_vm._v(".{name-color}--"),_c('nobr',{staticClass:"gentlemen--text"},[_vm._v("[fill | stroke]")])],1),_vm._v(" — изменение цвета у svg элементов. ")],1)])],1),_c('r-row',[_c('r-col',{attrs:{"cols":{ widest: 12, wide: 12, middle: 6, narrow: 6 }}},_vm._l((_vm.dataColor),function(types,key){return _c('section',{key:key},[_c('div',{staticClass:"mt-8 mb-4 taleggio colors__title"},[_vm._v(_vm._s(key))]),_c('div',{staticClass:"colors"},_vm._l((types),function(color,keyColor){return _c('div',{key:keyColor,staticClass:"color",class:[
              `${keyColor}--bg`,
              `${keyColor}--text`,
              {
                inversion: _vm.inversionColors.includes(keyColor),
                white: _vm.whiteColors.includes(keyColor),
                black: _vm.blackColors.includes(keyColor),
                border: _vm.borderColors.includes(keyColor)
              }
            ]},[_c('div',{staticClass:"color__name mb-2"},[_c('span',{staticClass:"feta"},[_vm._v(_vm._s(keyColor))]),_c('span',{staticClass:"feta"},[_vm._v(_vm._s(color[0].value))])]),_vm._l((color),function(state,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index > 0),expression:"index > 0"}],key:index,staticClass:"color__name mb-1"},[_c('span',{staticClass:"caprino"},[_vm._v(_vm._s(keyColor)+_vm._s(state.state))]),_c('span',{staticClass:"caprino"},[_vm._v(_vm._s(state.value))])])})],2)}),0)])}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }