<template>

  <div class="pt-4 component">
    <r-row class="mb-6">
      <r-col
        class="troy--text parmigiano"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        При инициализации библиотеки в проекте есть возможность произвести настройку для дефолтных параметров
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        class="burrata"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        Параметры
      </r-col>
      <r-col
        :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        <r-table
          :headers="headers"
          :items="items"
        >
          <template #property="{ item }">
            <div  v-html="item.property"/>
          </template>
          <template #typeData="{ item }">
            <div  v-html="item.typeData"/>
          </template>
          <template #defaultValue="{ item }">
            <div  v-html="item.defaultValue"/>
          </template>
        </r-table>
      </r-col>
    </r-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class OptionsLibrary extends Vue {
  headers = [
    {
      field: 'property',
      name: 'Наименование свойства',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Возможные значения',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
    },
  ]

  items = [
    {
      property: '<span class="rocky--text">typeCssGlobal</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">all-tags | body</span>',
      description: 'Применение темы по умолчанию',
    },
    {
      property: '<span class="rocky--text">theme</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">default | dark</span>',
      description: 'Применение темы по умолчанию',
    },
    {
      property: '<span class="rocky--text">components</span>',
      typeData: '<span class="matrix--text">&lt;string&gt;[]</span>',
      defaultValue: '<span class="rocky--text">Массив названий компонентов</span>',
      description: 'Список компонентов требуемы для подключения, если не передавать, то подключится полностью все',
    },
    {
      property: '<span class="rocky--text">fixPointGrid</span>',
      typeData: '<span class="fargo--text">BreakpointName</span>',
      defaultValue: '<span class="matrix--text">widest | wide | middle | narrow</span>',
      description: 'Фиксация для всего проекта одной точки остановки, отключает определение по длине экрана',
    },
    {
      property: '<span class="rocky--text">points</span>',
      typeData: '<span class="fargo--text">Points</span>',
      defaultValue: '<span class="matrix--text">{\n'
        + '    "widest": 1360,\n'
        + '    "wide": 1024,\n'
        + '    "middle": 600,\n'
        + '    "narrow": 0\n'
        + '  }</span>',
      description: 'Breakpoints по ширине экрана',
    },
    {
      property: '<span class="rocky--text">gridsName</span>',
      typeData: '<span class="fargo--text">GridTypes</span>',
      defaultValue: '<span class="matrix--text">default | dashboard</span>',
      description: 'Наименование сетки и применение параметров выбранной для GridSystem',
    },
  ]
}
</script>

<style lang="scss">
.working{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
}
</style>
