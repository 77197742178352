<template>
  <examples-block
    figma-url="https://www.figma.com/file/W6bvGBZas6baBlRQQKVquN/DS-Components?node-id=5792%3A106390"
    gitUrl="src/components/examples/Select/SelectAllCheckedSlotBody.vue"
    title="Мультивыбор с чекбосом 'Выбрать все' в слоте #body"
    description="Множественный выбор, в селекте выводятся через точку с запятой, работает только с локальным списком"
  >
    <r-select
      label="Items"
      v-model="model"
      :items="items"
      is-search
      return-object
    >
      <template #body>
        <div
          class="px-5 py-3">
          <r-checkbox
            :value="selectAll"
            title="All items"
            :is-partial="selectPartial"
            @input="changeAllItems"
          />
        </div>
      </template>
    </r-select>
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'SelectAllCheckedSlotBody',
  components: { ExamplesBlock },
  data: () => ({
    items: [
      {
        id: 1,
        title: 'Title1',
        subTitle: 'SubTitle1',
        icon: 'avatar',
      },
      {
        id: 2,
        title: 'Title2',
        subTitle: 'SubTitle2',
        icon: 'avatar',
        group: 'group2',
      },
      {
        id: 3,
        title: 'Title3',
        subTitle: 'SubTitle3',
        icon: 'avatar',
        group: 'group1',
      },
      {
        id: 4,
        title: 'Title4',
        subTitle: 'SubTitle4',
        icon: 'avatar',
        group: 'group3',
      },
      {
        id: 5,
        title: 'Title5',
        subTitle: 'SubTitle5',
        icon: 'avatar',
        group: 'group2',
      },
      {
        id: 6,
        title: 'Title6',
        subTitle: 'SubTitle6',
        icon: 'avatar',
        group: 'group3',
      },
    ],
    model: [],
  }),
  computed: {
    selectAll() {
      return this.model.length === this.items.length;
    },
    selectPartial() {
      return !!this.model.length && this.model.length !== this.items.length;
    },
  },
  methods: {
    changeAllItems(val) {
      this.model = val ? [...this.items] : [];
    },
  },
};
</script>
