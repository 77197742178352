<template>
  <component-page :code-view="codeView">
    <template #description>
      Контрол для выбора одного значения или диапазона между минимальным и максимальным значениями на скользящей оси Х
    </template>
    <template #component>
      <r-slider
        :key="String(isRangeModel) + JSON.stringify(props)"
        v-model="value"
        v-bind="props"
        :labels="labelsProps"
      />
    </template>
    <template #props>
      <r-row gap-hidden class="mb-6">
        <r-switcher
          v-model="isRangeModel"
          title="isRangeModel"
        />
        <r-switcher
          v-model="props.disabled"
          title="disabled"
        />
        <r-switcher
          v-model="props.isStartLabel"
          title="isStartLabel"
        />
        <r-switcher
          v-model="props.isFinisLabel"
          title="isFinisLabel"
        />
        <r-switcher
          v-model="props.isStepPoint"
          title="isStepPoint"
        />
        <r-switcher
          v-model="props.visibleTooltip"
          title="visibleTooltip"
        />
        <r-switcher
          v-model="props.error"
          title="error"
        />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="title"
            v-model="props.title"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="StartValue"
            v-model="props.startValue"
            type-input="number"
            :is-clear="false"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="FinishValue"
            v-model="props.finishValue"
            type-input="number"
            :is-clear="false"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="minValue"
            v-model="props.minValue"
            type-input="number"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="maxValue"
            v-model="props.maxValue"
            type-input="number"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="step"
            v-model="props.step"
            type-input="number"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="labels"
            v-model="props.labels"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="errorMessage"
            v-model="props.errorMessage"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class Slider extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Заголовок компонента',
    },
    {
      nameProp: '<span class="rocky--text">step</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="alien--text">1</span>',
      description: 'Шаг для изменение модели',
    },
    {
      nameProp: '<span class="rocky--text">isStepPoint</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Позиции шага на отрезке',
    },
    {
      nameProp: '<span class="rocky--text">startValue</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="alien--text">0</span>',
      description: 'Начало отрезка',
    },
    {
      nameProp: '<span class="rocky--text">finishValue</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="alien--text">100</span>',
      description: 'Конец отрезка',
    },
    {
      nameProp: '<span class="rocky--text">isStartLabel</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Показать лейбл начала отрезка',
    },
    {
      nameProp: '<span class="rocky--text">isFinisLabel</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Показать лейбл конца отрезка',
    },
    {
      nameProp: '<span class="rocky--text">labels</span>',
      typeData: '<span class="matrix--text">Array</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Значения леблов под отрезком',
    },
    {
      nameProp: '<span class="rocky--text">labelsFormat</span>',
      typeData: '<span class="matrix--text">Function</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Возвращает значение labels и ожидает возврат значения',
    },
    {
      nameProp: '<span class="rocky--text">minValue</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Минимальная граница значение для выбора на отрезке',
    },
    {
      nameProp: '<span class="rocky--text">maxValue</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Максимальная граница значение для выбора на отрезке',
    },
    {
      nameProp: '<span class="rocky--text">visibleTooltip</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Постоянное отображение тултипа у значений',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Состояние блокировки контрола',
    },
    {
      nameProp: '<span class="rocky--text">error</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Определение ошибки для контрола',
    },
    {
      nameProp: '<span class="rocky--text">errorMessage</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">выбрано некорректное значение</span>',
      description: 'Текст ошибки',
    },
  ]

  // model = 20
  // value: number | number[] = [10, 90]
  value: number | number[] = 20

  isRangeModel = false

  props = {
    title: 'Slider title',
    startValue: 0,
    finishValue: 100,
    isFinisLabel: false,
    isStartLabel: false,
    isStepPoint: false,
    visibleTooltip: false,
    minValue: 0,
    maxValue: 100,
    step: 5,
    labelsFormat: this.labelsFormat,
    labels: '25,50,75',
    disabled: false,
    error: false,
    errorMessage: null,
  }

  @Watch('isRangeModel')
  changeModel(val) {
    if (val) {
      this.value = [this.props.startValue, this.props.finishValue];
    } else {
      this.value = this.props.minValue;
    }
  }

  get labelsProps() {
    return this.props.labels ? this.props.labels.split(',') : [];
  }

  labelsFormat(val: number) {
    // const hours = Math.floor(val / 60 / 60);
    // const minutes = Math.floor(val / 60) - (hours * 60);
    // return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return val;
  }

  get codeView() {
    return generateCodeToString('slider', {
      ...this.props,
      labels: this.labelsProps,
    });
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
