<template>
  <component-page>
    <template #description>
      Адаптивный шаблон для организации дашбордов с двухуровневым боковым меню
    </template>
    <template #component>
      <r-button
        title="open layout"
        @click="openPageLayout"
      />
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class LayoutDashboard extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">menuOptions</span>',
      typeData: '<a href="/components/menu" target="_blank" class="matrix--text">MenuOptions</router-link>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Параметр для передачи списка меню и настроек',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Заголовок страницы',
    },
    {
      nameProp: '<span class="rocky--text">isCompact</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">false</span>',
      description: 'Функционал для сжимания меню',
    },
    {
      nameProp: '<span class="rocky--text">isOffsetContent</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="alien--text">true</span>',
      description: 'Скрывает отступы в контентной области',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">header</span>',
      description: 'Слот вверху блока меню',
    },
    {
      nameSlot: '<span class="rocky--text">footer</span>',
      description: 'Слот внизу блока меню',
    },
    {
      nameSlot: '<span class="rocky--text">navigation</span>',
      description: 'Слот над зоголовком страницы',
    },
    {
      nameSlot: '<span class="rocky--text">actions</span>',
      description: 'Слот сбоку от заголовка и слота navigation',
    },
    {
      nameSlot: '<span class="rocky--text">content</span>',
      description: 'Слот области контентной части',
    },
  ]

  openPageLayout() {
    const routeData = this.$router.resolve({ name: 'LayoutDashboardPage' });
    window.open(routeData.href, '_blank');
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
