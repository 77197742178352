<template>
  <section class="modal">

    <r-row class="mb-8">
      <r-col
        class="d-flex justify-content-center"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }"
        :offset="{ widest: 2, wide: 2, middle: 0, narrow: 0 }"
      >
        <r-button
          title="Отрыть модальное окно второго уровня"
          @click="openModal"
        />
      </r-col>
    </r-row>
  </section>
</template>

<script>
import ModalPage from '@/components/ModalPage.vue';

export default {
  name: 'SettingModalContent',
  methods: {
    openModal() {
      this.$rir.modal.open(ModalPage, {}, {
        isClose: false,
        backText: 'Вернуться назад',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
