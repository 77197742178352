<template>
  <component-page>
    <template #description>
      Компонент для воспроизведения аудио
    </template>
    <template #component>
      <r-player-audio
        v-bind="props"
      />
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="Title"
            v-model="props.label"
            :is-clear="false"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class PlayerAudio extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">url</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательное свойство</span>',
      description: 'Ссылка на аудиофайл',
    },
    {
      nameProp: '<span class="rocky--text">label</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательное свойство</span>',
      description: 'Описание название аудиофайла',
    },
  ]

  props = {
    url: 'https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3',
    label: 'Памятник «Мирный Атом»',
  }
}
</script>
