<template>
  <component-page :code-view="codeView">
    <template #description>
      Поле ввода c&nbsp;условно бесконечным выпадающим списком, который хранится на&nbsp;сервере.
      После ввода символов, на&nbsp;сервер отправляется запрос на&nbsp;списк.
    </template>
    <template #component>
      <div style="width: 50%" :key="`${isMulti}`">
        <r-autocomplete
          v-model="model"
          :call-search="callBackFindAutocomplete"
          v-bind="props"
          :groupsList="groups"
        />
      </div>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="Multi Select"
          v-model="isMulti"
        />
        <r-switcher
          title="disabled"
          v-model="props.disabled"
        />
        <r-switcher
          title="error"
          v-model="props.error"
        />
        <r-switcher
          title="isClear"
          v-model="props.isClear"
        />
        <r-switcher
          title="isGroup"
          v-model="isGroup"
        />
      </r-row>
      <r-row
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Type"
            v-model="props.type"
            :items="dataType"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Icon"
            is-search
            :icon="props.icon"
            v-model="props.icon"
            :items="dataIcons"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="ErrorMessage"
            v-model="props.errorMessage" />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Label"
            v-model="props.label" />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="PopoverOptions.position"
            v-model="props.popoverOptions.position"
            :items="dataPopoverPosition"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="PopoverOptions.contentWidthActivator"
            v-model="props.popoverOptions.contentCoefficientWidth"
            :items="dataContentCoefficientWidth"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getIconName } from '@/utils/icons';

import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Autocomplete extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">string | number | object </span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Параметр для выбранного значения из списка',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Параметр для блокировки компонента',
    },
    {
      nameProp: '<span class="rocky--text">type</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"default"</span>',
      description: 'Вид компонента',
    },
    {
      nameProp: '<span class="rocky--text">label</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Наименования поля',
    },
    {
      nameProp: '<span class="rocky--text">icon</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Иконка для отображения слева от поля ввода',
    },
    {
      nameProp: '<span class="rocky--text">idValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"id"</span>',
      description: 'Названия свойства из объекта списка для определения по уникальному идентификатору',
    },
    {
      nameProp: '<span class="rocky--text">titleValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"title"</span>',
      description: 'Названия свойства из объекта списка для определения отображение наименования',
    },
    {
      nameProp: '<span class="rocky--text">subTitleValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"subTitle"</span>',
      description: 'Названия свойства из объекта списка для определения отображение дополнительного описания',
    },
    {
      nameProp: '<span class="rocky--text">iconValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"icon"</span>',
      description: 'Названия свойства из объекта списка для определения отображение иконки',
    },
    {
      nameProp: '<span class="rocky--text">isClear</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">true</span>',
      description: 'Кнопка действия для очистки модели',
    },
    {
      nameProp: '<span class="rocky--text">error</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Параметр для определение ошибки валидации и подсветка компонента',
    },
    {
      nameProp: '<span class="rocky--text">errorMessage</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Текст сообщения о ошибки',
    },
    {
      nameProp: '<span class="rocky--text">callSearch</span>',
      typeData: '<span class="matrix--text">Async Function</span>',
      defaultValue: '<span class="mummy--text">() => ({})</span>',
      description: 'Параметр асихроной функции для получение списка из ответа апи от родителя',
    },
    {
      nameProp: '<span class="rocky--text">groupsList</span>',
      typeData: '<span class="matrix--text">GroupsList</span>',
      defaultValue: '<span class="alien--text">[]</span>',
      description: 'Массив групп для группировки элементов списка (отключает входной параметр callScroll)',
    },
    {
      nameProp: '<span class="rocky--text">popoverOptions</span>',
      typeData: '<span class="matrix--text">Pick&lt;RPopover, \'position\' | \'contentCoefficientWidth\' | \'isCheckAutoScrollBlock\'&gt;</span>',
      defaultValue: '<span class="alien--text">{\n'
        + '      position: \'center-bottom\',\n'
        + '      contentCoefficientWidth: 1,\n'
        + '      isCheckAutoScrollBlock: false\n'
        + '    }</span>',
      description: 'Входной параметр включающий себя надстройки компонента RPopover',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">focus</span>',
      description: 'Событие при фокусе на компонент',
    },
    {
      nameEvent: '<span class="rocky--text">blur</span>',
      description: 'Событие при потери фокуса',
    },
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
    {
      nameEvent: '<span class="rocky--text">focusout</span>',
      description: 'Возврощается событие при закрытие списка выбора',
    },
  ]

  isMulti = false

  model = null
  // model = []

  itemArray = Array.from(Array(10).keys())

  itemsServer = this.itemArray.map((el) => ({
    id: el,
    title: `Title ${el + 1}`,
    icon: 'avatar',
    group: `group${this.randGroup()}`,
  }))

  get groups() {
    return this.isGroup ? [
      {
        id: 'group1',
        name: 'Group 1',
      },
      {
        id: 'group2',
        name: 'Group 2',
      },
      {
        id: 'group3',
        name: 'Group 3',
      },
    ] : [];
  }

  isGroup = false

  props = {
    disabled: false,
    error: false,
    isClear: true,
    type: 'default',
    errorMessage: null,
    label: 'Placeholder',
    icon: null,
    titleValue: 'title',
    idValue: 'id',
    subTitleValue: 'subTitle',
    iconValue: 'icon',
    popoverOptions: {
      position: 'left-bottom',
      contentCoefficientWidth: 1,
    },
  }

  dataPopoverPosition = [
    'center-bottom',
    'center-top',
    'left-bottom',
    'left-top',
    'right-bottom',
    'right-top',

    'bottom-left',
    'bottom-right',
    'top-left',
    'top-right',
  ]

  dataContentCoefficientWidth = [
    {
      id: 1,
      title: '1',
    },
    {
      id: 1.2,
      title: '1.2',
    },
    {
      id: 1.4,
      title: '1.4',
    },
    {
      id: 1.6,
      title: '1.6',
    },
    {
      id: 1.8,
      title: '1.8',
    },
    {
      id: 2,
      title: '2',
    },
  ]

  dataType = [
    {
      id: 'default',
      title: 'default',
    },
    {
      id: 'map',
      title: 'map',
    },
  ]

  dataIcons = [
    {
      id: null,
      title: 'Без иконки',
      icon: 'block',
    },
  ]

  randGroup(min = 1, max = 3) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  get codeView() {
    return generateCodeToString('autocomplete', this.props);
  }

  callBackFindAutocomplete(query: string): Promise<any> {
    return new Promise((res) => {
      setTimeout(() => {
        if (query) {
          const items = this.itemsServer
            .filter((el: any) => (el[this.props.titleValue] || el)
              .toLowerCase()
              .includes((query as string).toLowerCase()));
          res(items);
        }
        res(this.itemsServer);
      }, 1000);
    });
  }

  created() {
    this.dataIcons = this.dataIcons.concat(getIconName().map((el) => ({
      id: el,
      title: el,
      icon: el,
    })));
  }

  @Watch('isMulti')
  changeIsMulti(val) {
    this.model = val ? [] : null;
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
