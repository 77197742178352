<template>
  <examples-block
    gitUrl="src/components/examples/RadioButton/RadioButtonString.vue"
    title="RRadioButton - props value type String">
    <r-radio-button
      title="One"
      v-model="model"
      val="one"
      class="mr-5"
    />
    <r-radio-button
      title="two"
      v-model="model"
      val="two"
      class="mr-5"
    />
    <r-radio-button
      title="three"
      v-model="model"
      val="three"
    />
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'RadioButtonString',
  components: {
    ExamplesBlock,
  },
  data() {
    return {
      model: 'one',
    };
  },
  methods: {
    changeVal(val) {
      this.model = val;
    },
  },
};
</script>
