import Vue from 'vue';

import rir from '@/plugins/RirLib';
import { VuePlugin } from 'vuera';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import '@/assets/prism/prism-rir.css';
import '@/assets/global.css';
import App from './App.vue';
import router from './router';
import store from './store';
// import the styles
Vue.component('PrismEditor', PrismEditor);
Vue.use(VuePlugin);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  $rir: rir,
  render: (h) => h(App),
}).$mount('#app');
