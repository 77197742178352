<template>
  <component-page :code-view="codeView">
    <template #description>
      Контрол для ввода небольших чисел до&nbsp;99&nbsp;только с&nbsp;помощью + и &minus;.
      Рекомендуется применять там, где бизнес-логика в&nbsp;основном подразумевает
      ввод от&nbsp;1&nbsp;до&nbsp;5.
      В&nbsp;иных случаях лучше использовать компонент Input
    </template>
    <template #component>
      <r-input-number
        v-model="model"
        v-bind="props"
      />
    </template>
    <template #props>
      <r-row gap-hidden>
        <r-switcher title="disabled" v-model="props.disabled"/>
      </r-row>
      <r-row gap-hidden class="mt-6">
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Min"
            type-input="number"
            v-model="props.min"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Max"
            type-input="number"
            v-model="props.max"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Step"
            type-input="number"
            v-model="props.step"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class InputNumber extends Vue {
  model = 0

  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="rocky--text">0</span>',
      description: 'Модель компонента',
    },
    {
      nameProp: '<span class="rocky--text">min</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="rocky--text">0</span>',
      description: 'Минимальное принимаемое значение',
    },
    {
      nameProp: '<span class="rocky--text">max</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="rocky--text">99</span>',
      description: 'Максимальное принимаемое значение',
    },
    {
      nameProp: '<span class="rocky--text">step</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="rocky--text">1</span>',
      description: 'Шаг изменяемого значения',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="rocky--text">false</span>',
      description: 'Состояние блокировки компонента',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Событие возвращает значение',
    },
  ]

  props = {
    min: 0,
    max: 99,
    step: 1,
    disabled: false,
  }

  get codeView() {
    return generateCodeToString('input-number', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
