<template>
  <component-page :code-view="codeView">
    <template #description>
      Кнопка со стрелкой влево или вправо
    </template>
    <template #component>
      <r-button-arrow
        :key="props.size"
        v-bind="props"/>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          v-model="props.disabled"
          title="disabled" />
      </r-row>
      <r-row
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Type"
            :items="dataType"
            v-model="props.type"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-select
              label="Size"
              :items="sizeType"
              v-model="props.size"
              />
          </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-select
              label="Direction"
              :items="directionType"
              v-model="props.direction"
              />
          </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class ButtonArrow extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">type</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"default"</span>',
      description: 'Тип отображения компонента',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"klymentise"</span>',
      description: 'Размер компонента',
    },
    {
      nameProp: '<span class="rocky--text">direction</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"left"</span>',
      description: 'Направление иконки',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается событие клика по компоненту',
    },
  ]

  props = {
    type: 'default',
    direction: 'left',
    size: 'klymentise',
    disabled: false,
  }

  dataType = [
    {
      id: 'default',
      title: 'default',
    },
    {
      id: 'shapeless',
      title: 'shapeless',
    },
  ]

  sizeType = [
    {
      id: 'klymentise',
      title: 'klymentise',
    },
    {
      id: 'ileneira',
      title: 'ileneira',
    },
    {
      id: 'samilea',
      title: 'samilea',
    },
  ]

  directionType = [
    {
      id: 'left',
      title: 'left',
    },
    {
      id: 'right',
      title: 'right',
    },
  ]

  get codeView() {
    return generateCodeToString('button-arrow', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
