<template>
  <examples-block
    figma-url="https://www.figma.com/file/W6bvGBZas6baBlRQQKVquN/DS-Components?node-id=5792%3A106390"
    gitUrl="src/components/examples/Select/SelectClearModel.vue"
    title="Сброс модели в слоте #body"
    description="Пример со слотом, который сбрасывает селект в дефолтное состояние, внутри слота может быть что угодно в рамках проекта"
  >
    <r-select
      label="Items"
      v-model="model"
      :items="items"
      is-search
      return-object
    >
      <template #body="{ close }">
        <div class="px-1">
          <r-list-item
            title="Cleare model"
            icon="block"
            @click="() => { clearModel(); close()}"
          />
        </div>
      </template>
    </r-select>
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'SelectClearModel',
  components: { ExamplesBlock },
  data: () => ({
    items: [
      {
        id: 1,
        title: 'Title1',
        subTitle: 'SubTitle1',
        icon: 'avatar',
      },
      {
        id: 2,
        title: 'Title2',
        subTitle: 'SubTitle2',
        icon: 'avatar',
        group: 'group2',
      },
      {
        id: 3,
        title: 'Title3',
        subTitle: 'SubTitle3',
        icon: 'avatar',
        group: 'group1',
      },
      {
        id: 4,
        title: 'Title4',
        subTitle: 'SubTitle4',
        icon: 'avatar',
        group: 'group3',
      },
      {
        id: 5,
        title: 'Title5',
        subTitle: 'SubTitle5',
        icon: 'avatar',
        group: 'group2',
      },
      {
        id: 6,
        title: 'Title6',
        subTitle: 'SubTitle6',
        icon: 'avatar',
        group: 'group3',
      },
    ],
    model: null,
  }),
  methods: {
    clearModel() {
      this.model = null;
    },
  },
};
</script>
