<template>
  <examples-block
    gitUrl="src/components/examples/Modal/modalView/FixedFooterModalContent.vue"
    title="Автоматический фиксированный footer">
    <r-button
      title="Отрыть модальное окно"
      @click="openModal"
    />
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';
import FixedFooterModalContent from './modalView/FixedFooterModalContent.vue';

export default {
  name: 'FixedFooterModal',
  components: { ExamplesBlock },
  methods: {
    openModal() {
      this.$rir.modal.open(FixedFooterModalContent, {});
    },
  },
};
</script>
