<template>
  <section class="modal">
    <r-row class="mb-8">
      <r-col
        class="ricotta"
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }"
        :offset="{ widest: 2, wide: 2, middle: 0, narrow: 0 }"
      >
        Длинный список
      </r-col>
    </r-row>
    <r-row class="mb-8">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }"
        :offset="{ widest: 2, wide: 2, middle: 0, narrow: 0 }"
      >
        <r-list-group>
          <template #content>
            <r-list-item
              v-for="n in listView"
              :key="n"
              :title="`Title${n}`"
            />
          </template>
        </r-list-group>
      </r-col>
    </r-row>
    <div id="RModal--actions">
      <r-row class="mb-8">
        <r-col
          :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }"
          :offset="{ widest: 2, wide: 2, middle: 0, narrow: 0 }"
        >
          <r-button
            width="wide"
            title="Сохранить"
            @click="openModal"
            />
        </r-col>
      </r-row>
    </div>
  </section>
</template>

<script>
import ModalPage from '@/components/ModalPage.vue';

export default {
  name: 'SettingModalContent',
  data: () => ({
    listView: [],
  }),
  mounted() {
    for (let i = 1; i <= 25; i++) {
      setTimeout(() => this.listView.push(i), 100 * i);
    }
  },
  methods: {
    openModal() {
      this.$rir.modal.open(ModalPage, {}, {
        isClose: false,
        backText: 'Вернуться назад',
        isFixedActionsBlock: true,
      });
    },
  },
};
</script>
