<template>
  <component-page :code-view="codeView">
    <template #description>
      Интерактивный элемент списка
    </template>
    <template #component>
      <r-list-group
        v-bind="props"
      >
        <template #content>
          <r-list-item
            v-for="n in 4"
            :key="n"
            v-bind="props"
            :title="val[n-1].title"
            :sub-title="isSubTitle ? val[n-1].subTitle : null"
            :icon="isIcon ? val[n-1].icon : null"
            :group="val[n-1].group"
            :val="val[n-1]"
          />
        </template>
      </r-list-group>
    </template>
    <template #props>
      <r-row gap-hidden>
        <r-switcher
          title="isGroup"
          v-model="isGroup" />
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        class="mb-8"
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class ListGroup extends Vue {
  val = [
    {
      id: 1,
      title: 'Title1',
      icon: 'avatar',
      subTitle: 'subtitle',
      group: 'group1',
    },
    {
      id: 2,
      title: 'Title2',
      icon: 'avatar',
      subTitle: 'subtitle',
      group: 'group2',
    },
    {
      id: 3,
      title: 'Title3',
      icon: 'avatar',
      subTitle: 'subtitle',
      group: 'group1',
    },
    {
      id: 4,
      title: 'Title4',
      icon: 'avatar',
      subTitle: 'subtitle',
      group: 'group2',
    },
  ]

  isIcon = true

  isSubTitle = true

  isGroup = true

  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">groups</span>',
      typeData: '<span class="matrix--text">array</span>',
      defaultValue: '<span class="fightclub--text">[]</span>',
      description: 'Объектный массив для группировки списка внутри слота "content", у компонентов должен присутствовать входной параметр "group"',
      children: [
        {
          nameProp: '<span class="rocky--text">id</span>',
          typeData: '<span class="matrix--text">string | number</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Идентификатор для группировки элементов',
        },
        {
          nameProp: '<span class="rocky--text">name</span>',
          typeData: '<span class="matrix--text">string | number</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Наименование заголовка группы',
        },
      ],
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">content</span>',
      description: 'Область контента',
    },
  ]

  get props() {
    return {
      groups: this.isGroup ? [
        {
          id: 'group1',
          name: 'Group 1',
        },
        {
          id: 'group2',
          name: 'Group 2',
        },
      ] : [],
    };
  }

  get codeView() {
    return generateCodeToString('list-group', this.props, { content: true });
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
