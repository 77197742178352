import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Install from '@/views/Instructions/Install.vue';
import Button from '@/views/components/Button.vue';
import Palette from '@/views/styles/Palette.vue';
import Typographic from '@/views/styles/Typographic.vue';
import Input from '@/views/components/Input.vue';
import Autocomplete from '@/views/components/Autocomplete.vue';
import Table from '@/views/components/Table.vue';
import Paginator from '@/views/components/Paginator.vue';
// import Kanban from '@/views/crm/Kanban.vue';
import Announcement from '@/views/components/Announcement.vue';
import Badge from '@/views/components/Badge.vue';
import Bulb from '@/views/components/Bulb.vue';
import ButtonAction from '@/views/components/ButtonAction.vue';
import ButtonArrow from '@/views/components/ButtonArrow.vue';
import ButtonGallery from '@/views/components/ButtonGallery.vue';
import ButtonNavigation from '@/views/components/ButtonNavigation.vue';
import ButtonSimple from '@/views/components/ButtonSimple.vue';
import ButtonTag from '@/views/components/ButtonTag.vue';
import Checkbox from '@/views/components/Checkbox.vue';
import DatePicker from '@/views/components/DatePicker.vue';
import File from '@/views/components/File.vue';
import Icon from '@/views/components/Icon.vue';
import InputNumber from '@/views/components/InputNumber.vue';
import ImagePage from '@/views/components/ImagePage.vue';
import ListItem from '@/views/components/ListItem.vue';
import Menu from '@/views/components/Menu.vue';
import NavigationTile from '@/views/components/NavigationTile.vue';
import Popover from '@/views/components/Popover.vue';
import RadioButton from '@/views/components/RadioButton.vue';
import Select from '@/views/components/Select.vue';
import Spinner from '@/views/components/Spinner.vue';
import Story from '@/views/components/Story.vue';
import Switcher from '@/views/components/Switcher.vue';
import Tabs from '@/views/components/Tabs.vue';
import Textarea from '@/views/components/Textarea.vue';
import TimePicker from '@/views/components/TimePicker.vue';
import Tooltip from '@/views/components/Tooltip.vue';
import Uploader from '@/views/components/Uploader.vue';
import GridSystem from '@/views/components/GridSystem.vue';
import Modal from '@/views/components/Modal.vue';
import Avatar from '@/views/components/Avatar.vue';
import BreadCrumbs from '@/views/components/BreadCrumbs.vue';
import Pictograms from '@/views/styles/Icons.vue';
import Breakpoints from '@/views/styles/Breakpoints.vue';
import Grid from '@/views/styles/Grid.vue';
import Offsets from '@/views/styles/Offsets.vue';
import Rounds from '@/views/styles/Rounds.vue';
import Opacities from '@/views/styles/Opacities.vue';
import Home from '@/views/Home.vue';
import ButtonLink from '@/views/components/ButtonLink.vue';
import Plank from '@/views/components/Plank.vue';
import Shorter from '@/views/components/Shorter.vue';
import OptionsLibrary from '@/views/Instructions/OptionsLibrary.vue';
import ListGroup from '@/views/components/ListGroup.vue';
import ButtonPlayer from '@/views/components/ButtonPlayer.vue';
import Timer from '@/views/components/Timer.vue';
import Notification from '@/views/components/Notification.vue';
import Slideshow from '@/views/components/Slideshow.vue';
import Accordion from '@/views/components/Accordion.vue';
import PlayerAudio from '@/views/components/PlayerAudio.vue';
import PlayerVideo from '@/views/components/PlayerVideo.vue';
import MediaGallery from '@/views/components/MediaGallery.vue';
import LayoutDashboard from '@/views/components/LayoutDashboard.vue';
import LayoutSite from '@/views/components/LayoutSite.vue';
import LayoutSitePage from '@/layouts/LayoutSitePage.vue';
import LayoutDashboardPage from '@/layouts/LayoutDashboardPage.vue';
import LightBox from '@/views/components/LightBox.vue';
import ColorPicker from '@/views/components/ColorPicker.vue';

import TestForm from '@/views/TestForm.vue';
import TreeList from '@/views/components/TreeList.vue';
import Slider from '@/views/components/Slider.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      menu: false,
      layout: 'Empty',
    },
    // redirect: { name: 'Install' },
  },
  {
    path: '/test-form',
    name: 'TestForm',
    component: TestForm,
    meta: {
      menu: false,
      layout: 'Empty',
    },
    // redirect: { name: 'Install' },
  },
  {
    path: '/layout-site-page',
    name: 'LayoutSitePage',
    component: LayoutSitePage,
    meta: {
      menu: false,
      layout: 'Empty',
    },
    // redirect: { name: 'Install' },
  },
  {
    path: '/layout-dashboard-page',
    name: 'LayoutDashboardPage',
    component: LayoutDashboardPage,
    meta: {
      menu: false,
      layout: 'Empty',
    },
    // redirect: { name: 'Install' },
  },
  {
    path: '/instructions',
    name: 'Instructions',
    component: {
      render: (h) => h('router-view'),
    },
    meta: {
      icon: 'errand',
      title: 'Инструкция',
      menu: true,
      layout: 'Dashboard',
    },
    children: [
      {
        path: 'install',
        name: 'Install',
        component: Install,
        meta: {
          title: 'Установка',
          menu: true,
          layout: 'Dashboard',
        },
      },
      {
        path: 'options',
        name: 'Options',
        component: OptionsLibrary,
        meta: {
          title: 'Настройка',
          menu: true,
          layout: 'Dashboard',
        },
      },
    ],
  },
  {
    path: '/styles',
    name: 'Styles',
    component: {
      render: (h) => h('router-view'),
    },
    meta: {
      icon: 'edit-picture',
      title: 'Стили',
      menu: true,
      layout: 'Dashboard',
    },
    children: [
      {
        path: 'palettes',
        name: 'Palettes',
        component: Palette,
        meta: {
          menu: true,
          title: 'Палитра',
          layout: 'Dashboard',
        },
      },
      {
        path: 'typographic',
        name: 'Typographic',
        component: Typographic,
        meta: {
          menu: true,
          title: 'Типографика',
          layout: 'Dashboard',
        },
      },
      {
        path: 'pictograms',
        name: 'Pictograms',
        component: Pictograms,
        meta: {
          menu: true,
          title: 'Пиктограммы',
          layout: 'Dashboard',
        },
      },
      {
        path: 'breakpoints',
        name: 'Breakpoints',
        component: Breakpoints,
        meta: {
          menu: true,
          title: 'Брейкпоинты',
          layout: 'Dashboard',
        },
      },
      {
        path: 'grid',
        name: 'Grid',
        component: Grid,
        meta: {
          menu: true,
          title: 'Сетка',
          layout: 'Dashboard',
        },
      },
      {
        path: 'offsets',
        name: 'Offsets',
        component: Offsets,
        meta: {
          menu: true,
          title: 'Отступы',
          layout: 'Dashboard',
        },
      },
      {
        path: 'rounds',
        name: 'Rounds',
        component: Rounds,
        meta: {
          menu: true,
          title: 'Скругления',
          layout: 'Dashboard',
        },
      },
      {
        path: 'opacities',
        name: 'Opacities',
        component: Opacities,
        meta: {
          menu: true,
          title: 'Прозрачность',
          layout: 'Dashboard',
        },
      },
    ],
  },
  {
    path: '/components',
    name: 'components',
    component: {
      render: (h) => h('router-view'),
    },
    meta: {
      icon: 'data-system',
      title: 'Компоненты',
      menu: true,
      sorting: true,
      layout: 'Dashboard',
    },
    children: [
      {
        path: 'tree-list',
        name: 'TreeList',
        component: TreeList,
        meta: {
          menu: true,
          title: 'TreeList',
          layout: 'Dashboard',
        },
      },
      {
        path: 'slider',
        name: 'Slider',
        component: Slider,
        meta: {
          menu: true,
          title: 'Slider',
          layout: 'Dashboard',
        },
      },
      {
        path: 'color-picker',
        name: 'ColorPicker',
        component: ColorPicker,
        meta: {
          menu: true,
          title: 'ColorPicker',
          layout: 'Dashboard',
        },
      },
      {
        path: 'layout-dashboard',
        name: 'LayoutDashboard',
        component: LayoutDashboard,
        meta: {
          menu: true,
          title: 'LayoutDashboard',
          layout: 'Dashboard',
        },
      },
      {
        path: 'layout-site',
        name: 'LayoutSite',
        component: LayoutSite,
        meta: {
          menu: true,
          title: 'LayoutSite',
          layout: 'Dashboard',
        },
      },
      {
        path: 'media-gallery',
        name: 'MediaGallery',
        component: MediaGallery,
        meta: {
          menu: true,
          title: 'MediaGallery',
          layout: 'Dashboard',
        },
      },
      {
        path: 'player-video',
        name: 'PlayerVideo',
        component: PlayerVideo,
        meta: {
          menu: true,
          title: 'PlayerVideo',
          layout: 'Dashboard',
        },
      },
      {
        path: 'player-audio',
        name: 'PlayerAudio',
        component: PlayerAudio,
        meta: {
          menu: true,
          title: 'PlayerAudio',
          layout: 'Dashboard',
        },
      },
      {
        path: 'accordion',
        name: 'Accordion',
        component: Accordion,
        meta: {
          menu: true,
          title: 'Accordion',
          layout: 'Dashboard',
        },
      },
      {
        path: 'button-player',
        name: 'ButtonPlayer',
        component: ButtonPlayer,
        meta: {
          menu: true,
          title: 'ButtonPlayer',
          layout: 'Dashboard',
        },
      },
      {
        path: 'slideshow',
        name: 'Slideshow',
        component: Slideshow,
        meta: {
          menu: true,
          title: 'Slideshow',
          layout: 'Dashboard',
        },
      },
      {
        path: 'notification',
        name: 'Notification',
        component: Notification,
        meta: {
          menu: true,
          title: 'Notification',
          layout: 'Dashboard',
        },
      },
      {
        path: 'timer',
        name: 'Timer',
        component: Timer,
        meta: {
          menu: true,
          title: 'Timer',
          layout: 'Dashboard',
        },
      },
      {
        path: 'shorter',
        name: 'Shorter',
        component: Shorter,
        meta: {
          menu: true,
          title: 'Shorter',
          layout: 'Dashboard',
        },
      },
      {
        path: 'list-group',
        name: 'ListGroup',
        component: ListGroup,
        meta: {
          menu: true,
          title: 'ListGroup',
          layout: 'Dashboard',
        },
      },
      {
        path: 'plank',
        name: 'Plank',
        component: Plank,
        meta: {
          menu: true,
          title: 'Plank',
          layout: 'Dashboard',
        },
      },
      {
        path: 'button-link',
        name: 'ButtonLink',
        component: ButtonLink,
        meta: {
          menu: true,
          title: 'ButtonLink',
          layout: 'Dashboard',
        },
      },
      {
        path: 'bread-crumbs',
        name: 'BreadCrumbs',
        component: BreadCrumbs,
        meta: {
          menu: true,
          title: 'BreadCrumbs',
          layout: 'Dashboard',
        },
      },
      {
        path: 'avatar',
        name: 'Avatar',
        component: Avatar,
        meta: {
          menu: true,
          title: 'Avatar',
          layout: 'Dashboard',
        },
      },
      {
        path: 'modal',
        name: 'Modal',
        component: Modal,
        meta: {
          menu: true,
          title: 'Modal',
          layout: 'Dashboard',
        },
      },
      {
        path: 'grid-system',
        name: 'GridSystem',
        component: GridSystem,
        meta: {
          menu: false,
          title: 'GridSystem',
        },
      },
      {
        path: 'uploader',
        name: 'Uploader',
        component: Uploader,
        meta: {
          menu: true,
          title: 'Uploader',
          layout: 'Dashboard',
        },
      },
      {
        path: 'tooltip',
        name: 'Tooltip',
        component: Tooltip,
        meta: {
          menu: true,
          title: 'Tooltip',
          layout: 'Dashboard',
        },
      },
      {
        path: 'time-picker',
        name: 'TimePicker',
        component: TimePicker,
        meta: {
          menu: true,
          title: 'TimePicker',
          layout: 'Dashboard',
        },
      },
      {
        path: 'textarea',
        name: 'Textarea',
        component: Textarea,
        meta: {
          menu: true,
          title: 'Textarea',
          layout: 'Dashboard',
        },
      },
      {
        path: 'tabs',
        name: 'Tabs',
        component: Tabs,
        meta: {
          menu: true,
          title: 'Tabs',
          layout: 'Dashboard',
        },
      },
      {
        path: 'switcher',
        name: 'Switcher',
        component: Switcher,
        meta: {
          menu: true,
          title: 'Switcher',
          layout: 'Dashboard',
        },
      },
      {
        path: 'Story',
        name: 'Story',
        component: Story,
        meta: {
          menu: true,
          title: 'Story',
          layout: 'Dashboard',
        },
      },
      {
        path: 'Spinner',
        name: 'Spinner',
        component: Spinner,
        meta: {
          menu: true,
          title: 'Spinner',
          layout: 'Dashboard',
        },
      },
      {
        path: 'radio-button',
        name: 'RadioButton',
        component: RadioButton,
        meta: {
          menu: true,
          title: 'RadioButton',
          layout: 'Dashboard',
        },
      },
      {
        path: 'select',
        name: 'Select',
        component: Select,
        meta: {
          menu: true,
          title: 'Select',
          layout: 'Dashboard',
        },
      },
      {
        path: 'popover',
        name: 'Popover',
        component: Popover,
        meta: {
          menu: true,
          title: 'Popover',
          layout: 'Dashboard',
        },
      },
      {
        path: 'navigation-tile',
        name: 'NavigationTile',
        component: NavigationTile,
        meta: {
          menu: true,
          title: 'NavigationTile',
          layout: 'Dashboard',
        },
      },
      {
        path: 'menu',
        name: 'Menu',
        component: Menu,
        meta: {
          menu: true,
          title: 'Menu',
          layout: 'Dashboard',
        },
      },
      {
        path: 'list',
        name: 'ListItem',
        component: ListItem,
        meta: {
          menu: true,
          title: 'ListItem',
          layout: 'Dashboard',
        },
      },
      {
        path: 'input-number',
        name: 'InputNumber',
        component: InputNumber,
        meta: {
          menu: true,
          title: 'InputNumber',
          layout: 'Dashboard',
        },
      },
      {
        path: 'image',
        name: 'Image',
        component: ImagePage,
        meta: {
          menu: true,
          title: 'Image',
          layout: 'Dashboard',
        },
      },
      {
        path: 'icon',
        name: 'Icon',
        component: Icon,
        meta: {
          menu: true,
          title: 'Icon',
          layout: 'Dashboard',
        },
      },
      {
        path: 'file',
        name: 'File',
        component: File,
        meta: {
          menu: true,
          title: 'File',
          layout: 'Dashboard',
        },
      },
      {
        path: 'date-picker',
        name: 'DatePicker',
        component: DatePicker,
        meta: {
          menu: true,
          title: 'DatePicker',
          layout: 'Dashboard',
        },
      },
      {
        path: 'button-tag',
        name: 'ButtonTag',
        component: ButtonTag,
        meta: {
          menu: true,
          title: 'ButtonTag',
          layout: 'Dashboard',
        },
      },
      {
        path: 'checkbox',
        name: 'Checkbox',
        component: Checkbox,
        meta: {
          menu: true,
          title: 'Checkbox',
          layout: 'Dashboard',
        },
      },
      {
        path: 'button-simple',
        name: 'ButtonSimple',
        component: ButtonSimple,
        meta: {
          menu: true,
          title: 'ButtonSimple',
          layout: 'Dashboard',
        },
      },
      {
        path: 'button-navigation',
        name: 'ButtonNavigation',
        component: ButtonNavigation,
        meta: {
          menu: true,
          title: 'ButtonNavigation',
          layout: 'Dashboard',
        },
      },
      {
        path: 'button-gallery',
        name: 'ButtonGallery',
        component: ButtonGallery,
        meta: {
          menu: true,
          title: 'ButtonGallery',
          layout: 'Dashboard',
        },
      },
      {
        path: 'button-arrow',
        name: 'ButtonArrow',
        component: ButtonArrow,
        meta: {
          menu: true,
          title: 'ButtonArrow',
          layout: 'Dashboard',
        },
      },
      {
        path: 'button-action',
        name: 'ButtonAction',
        component: ButtonAction,
        meta: {
          menu: true,
          title: 'ButtonAction',
          layout: 'Dashboard',
        },
      },
      {
        path: 'bulb',
        name: 'Bulb',
        component: Bulb,
        meta: {
          menu: true,
          title: 'Bulb',
          layout: 'Dashboard',
        },
      },
      {
        path: 'badge',
        name: 'Badge',
        component: Badge,
        meta: {
          menu: true,
          title: 'Badge',
          layout: 'Dashboard',
        },
      },
      {
        path: 'announcement',
        name: 'Announcement',
        component: Announcement,
        meta: {
          menu: true,
          title: 'Announcement',
          layout: 'Dashboard',
        },
      },
      {
        path: 'autocomplete',
        name: 'Autocomplete',
        component: Autocomplete,
        meta: {
          menu: true,
          title: 'Autocomplete',
          layout: 'Dashboard',
        },
      },
      {
        path: 'button',
        name: 'Button',
        component: Button,
        meta: {
          menu: true,
          title: 'Button',
          layout: 'Dashboard',
        },
      },
      {
        path: 'input',
        name: 'Input',
        component: Input,
        meta: {
          menu: true,
          title: 'Input',
          layout: 'Dashboard',
        },
      },
      {
        path: 'table',
        name: 'Table',
        component: Table,
        meta: {
          menu: true,
          title: 'Table',
          layout: 'Dashboard',
        },
      },
      {
        path: 'paginator',
        name: 'Paginator',
        component: Paginator,
        meta: {
          menu: true,
          title: 'Paginator',
          layout: 'Dashboard',
        },
      },
      // {
      //   path: 'kanban',
      //   name: 'Kanban',
      //   component: Kanban,
      //   meta: {
      //     menu: true,
      //     title: 'Kanban',
      //   },
      // },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
