<template>
  <component-page :code-view="codeView">
    <template #description>
      Поле ввода для длинного текста
    </template>
    <template #component>
      <r-textarea
        :key="JSON.stringify(props)"
        v-bind="props"
        v-model="model"
      />
    </template>
    <template #props>
      <r-row
        class="mb-6" gap-hidden>
        <r-switcher
          title="readonly"
          v-model="props.readonly"
        />
        <r-switcher
          title="error"
          v-model="props.error"
        />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Label"
            v-model="props.label"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Rows"
            v-model="props.rows"
            type-input="number"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="MaxRows"
            v-model="props.maxRows"
            type-input="number"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="ErrorMessage"
            v-model="props.errorMessage"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="maxLength"
            typeInput="number"
            v-model="props.maxLength"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Textarea extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Модель компонента',
    },
    {
      nameProp: '<span class="rocky--text">label</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Наименование компонента',
    },
    {
      nameProp: '<span class="rocky--text">rows</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="rocky--text">3</span>',
      description: 'Минимальное количество строк',
    },
    {
      nameProp: '<span class="rocky--text">maxRows</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Максимальное количество строк',
    },
    {
      nameProp: '<span class="rocky--text">readonly</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Блокировка компонента',
    },
    {
      nameProp: '<span class="rocky--text">error</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Входной параметр для определение ошибки валидации и подсветка компонента',
    },
    {
      nameProp: '<span class="rocky--text">errorMessage</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Текст сообщения о ошибки',
    },
    {
      nameProp: '<span class="rocky--text">maxLength</span>',
      typeData: '<span class="matrix--text">string | number</span>',
      defaultValue: '<span class="matrix--text">undefined</span>',
      description: 'Максимальное количество символов для ввода',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
    {
      nameEvent: '<span class="rocky--text">focus</span>',
      description: 'Событие при фокусе на компонент',
    },
    {
      nameEvent: '<span class="rocky--text">blur</span>',
      description: 'Событие при потери фокуса',
    },
  ]

  model = null

  props = {
    label: 'Label',
    rows: 3,
    maxRows: 6,
    readonly: false,
    error: false,
    errorMessage: null,
    maxLength: null,
  }

  get codeView() {
    return generateCodeToString('textarea', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
