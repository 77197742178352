<template>
  <component-page>
    <template #description>
      Адаптивный шаблон для для создания сайтов и посадочных страниц. Содержит Хедер со слотами под логотип, навигацию и действия, контентную область и футер с одним слотом.
    </template>
    <template #component>
      <r-button
        title="open layout"
        @click="openPageLayout"
      />
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class LayoutSite extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">items</span>',
      typeData: '<a href="/components/menu" target="_blank" class="matrix--text">MenuOptions.items[]</router-link>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Параметр для передачи списка меню',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">logo</span>',
      description: 'В шапке слева от области меню',
    },
    {
      nameSlot: '<span class="rocky--text">actions</span>',
      description: 'В шапке справа от области меню',
    },
    {
      nameSlot: '<span class="rocky--text">content</span>',
      description: 'Слот области контентной части',
    },
    {
      nameSlot: '<span class="rocky--text">footer</span>',
      description: 'Слот в нижней части шаблона',
    },
  ]

  openPageLayout() {
    const routeData = this.$router.resolve({ name: 'LayoutSitePage' });
    window.open(routeData.href, '_blank');
  }
}
</script>
