<template>
  <component-page :code-view="codeView" color-preview-block="trainspottin">
    <template #description>
      Кликабельная плашка
    </template>
    <template #component>
      <div class="d-flex flex-direction-column">

        <r-plank
          :key="`${JSON.stringify(dataSlot)}-first`"
          v-bind="props"
          val="1"
          @click="changeModelOrClick"
        >
          <template v-if="dataSlot.action" #action>99+</template>
        </r-plank>
      </div>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="isModel"
          v-model="isModel"
        />
        <r-switcher
          title="isArrowAction"
          v-model="props.isArrowAction"
        />
        <r-switcher
          title="photo"
          v-model="isImage"
          @input="changeValImg"
        />
        <r-switcher
          title="disabled"
          v-model="props.disabled"
        />
      </r-row>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="Title"
            v-model="props.title"
            :is-clear="false"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            label="SubTitle"
            v-model="props.subTitle"
            :is-clear="false"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Icon"
            :icon="props.icon"
            v-model="props.icon"
            :items="dataIcons"
            is-search
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="IconAction"
            :icon="props.iconAction"
            v-model="props.iconAction"
            :items="dataIcons"
            is-search
          />
        </r-col>
      </r-row>
    </template>
    <template #slotsView>
      <r-row
        gap-hidden>
        <r-switcher
          title="action slot"
          v-model="dataSlot.action"
        />
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class Plank extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">Object | String | Number</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Модель компонента',
    },
    {
      nameProp: '<span class="rocky--text">val</span>',
      typeData: '<span class="matrix--text">Object | String | Number</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Значение которое запишется в модель или вернется эвентом @input[object, string, number]',
    },
    {
      nameProp: '<span class="rocky--text">icon</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Название иконки из списка в UiKit',
    },
    {
      nameProp: '<span class="rocky--text">iconAction</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Название иконки из списка в UiKit',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Заголовок компонента',
    },
    {
      nameProp: '<span class="rocky--text">subTitle</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Дополнительное описание компонента',
    },
    {
      nameProp: '<span class="rocky--text">img</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'URL на картинку для вывода вместо icon',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Блокировка компонента',
    },
    {
      nameProp: '<span class="rocky--text">isArrowAction</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'В область Action выводится иконка стрелка',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается входной параметр val, если был передан иначе event:PointerEvent',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">action</span>',
      description: 'Слот для подстановки комнтента вместо вывода iconAction',
    },
  ]

  isImage = false

  isModel = false

  props = {
    icon: 'avatar',
    iconAction: null,
    isArrowAction: true,
    title: 'Title',
    subTitle: null,
    img: null,
    disabled: false,
    value: undefined,
  }

  dataSlot = {
    action: false,
  }

  dataIcons = [
    {
      id: null,
      title: 'Без иконки',
      icon: 'block',
    },
  ]

  created() {
    this.dataIcons = this.dataIcons.concat(getIconName().map((el) => ({
      id: el,
      title: el,
      icon: el,
    })));
  }

  get codeView() {
    return generateCodeToString('plank', this.props, { ...this.dataSlot });
  }

  changeValImg(val) {
    if (val) {
      this.props.img = '/img/test.jpg';
    } else {
      this.props.img = null;
    }
  }

  changeModelOrClick(val) {
    if (this.props.value !== undefined) {
      this.props.value = val;
    }
    console.log('click event: ', val);
  }

  @Watch('isModel')
  changeModel(val) {
    this.props.value = val ? null : undefined;
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
