<template>
  <component-page :code-view="codeView">
    <template #description>
      Меню для боковой панели дашбордов
    </template>
    <template #component>
      <r-menu
        v-bind="props"
        :items="items"
      />
    </template>
    <template #props>
      <r-row gap-hidden>
        <r-switcher
          title="isCompact"
          v-model="props.isCompact"
        />
        <r-switcher
          title="isIconParent"
          v-model="props.isIconParent"
        />
        <r-switcher
          title="isIconChild"
          v-model="props.isIconChild"
        />
      </r-row>
      <r-row gap-hidden class="mt-6">
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false" label="countLine" v-model="props.countLine"
            typeInput="number" />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Menu extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">items</span>',
      typeData: '<span class="matrix--text">ItemsElement[]</span>',
      defaultValue: '<span class="">[]</span>',
      description: 'Список пунктов меню',
      children: [
        {
          nameProp: '<span class="rocky--text">title</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Титл меню',
        },
        {
          nameProp: '<span class="rocky--text">icon</span>',
          typeData: '<span class="matrix--text">string</span>',
          description: 'Иконка меню из списка иконок 16 размера',
        },
        {
          nameProp: '<span class="rocky--text">router</span>',
          typeData: '<span class="matrix--text">ItemsElement.router</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр (если нет свойства "children" или "href")</span>',
          description: 'Объект входных параметров метода $router.push() и свойства exact',
        },
        {
          nameProp: '<span class="rocky--text">href</span>',
          typeData: '<span class="matrix--text">ItemsElement.href</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр (если нет свойства "children" или "router")</span>',
          description: 'Объект для внешних ссылок состоит из двух свойств link (адрес внешней ссылки) и newTab - boolean (открытие в новом табе)',
        },
        {
          nameProp: '<span class="rocky--text">disabled</span>',
          typeData: '<span class="matrix--text">boolean</span>',
          description: 'Блокировка пункта меню',
        },
        {
          nameProp: '<span class="rocky--text">bulbOptions</span>',
          typeData: '<span class="matrix--text">BulbOption</span>',
          description: 'Входные параметры компонента Bulb, за исключение [\'size\' | \'isBlock\' | \'isShape\']',
        },
        {
          nameProp: '<span class="rocky--text">children</span>',
          typeData: '<span class="matrix--text">ItemsElement[]</span>',
          description: 'Аналогичный объектный массив для потомков меню',
        },
      ],
    },
    {
      nameProp: '<span class="rocky--text">isCompact</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Применение компактного вида меню',
    },
    {
      nameProp: '<span class="rocky--text">isIconParent</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Отображение иконок на первом уровне меню',
    },
    {
      nameProp: '<span class="rocky--text">isIconChild</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Отображение иконок в дочерних меню',
    },
    {
      nameProp: '<span class="rocky--text">countLine</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="fightclub--text">1</span>',
      description: 'Значение количество строк для пунктов меню',
    },
  ]

  items = [
    {
      title: 'menu1',
      icon: 'avatar',
      bulbOptions: {
        title: '22',
      },
    },
    {
      title: 'menu2',
      icon: 'avatar',
      bulbOptions: {
        title: '22',
      },
      children: [
        {
          title: 'sub menu 1',
          icon: 'avatar',
          bulbOptions: {
            title: '1',
            color: 'matrix',
          },
        },
        {
          title: 'sub menu 2',
          bulbOptions: {
            title: '2',
            color: 'fargo',
          },
        },
        {
          title: 'sub menu 3',
          icon: 'avatar',
          children: [
            {
              title: 'sub menu 31',
              icon: 'avatar',
              bulbOptions: {
                title: '1',
                color: 'matrix',
              },
            },
            {
              title: 'sub menu 32',
              bulbOptions: {
                title: '2',
                color: 'fargo',
              },
            },
            {
              title: 'sub menu 33',
              icon: 'avatar',
              children: [
                {
                  title: 'sub menu 331',
                  icon: 'avatar',
                  bulbOptions: {
                    title: '1',
                    color: 'matrix',
                  },
                },
                {
                  title: 'sub menu 332',
                  bulbOptions: {
                    title: '2',
                    color: 'fargo',
                  },
                },
                {
                  title: 'sub menu 333',
                  icon: 'avatar',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'menu3',
      icon: 'avatar',
    },
    {
      title: 'menu4',
      icon: 'link',
      href: {
        link: 'https://google.com',
      },
    },
  ]

  props = {
    isCompact: false,
    isIconChild: false,
    isIconParent: true,
    countLine: 1,
  }

  get codeView() {
    return generateCodeToString('menu', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
