<template>
  <component-page :code-view="codeView">
    <template #description>
      Вкладки, группирующие большие объемы данных, делая страницу компактнее и удобнее
    </template>
    <template #component>
      <div style="width: 100%">
        <r-tabs
          v-model="model"
          :items="items"
          v-bind="props"
        />
      </div>
    </template>
    <template #props>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Type"
            v-model="props.type"
            :items="dataType"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Tabs extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">object</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Модель компонента',
    },
    {
      nameProp: '<span class="rocky--text">items</span>',
      typeData: '<span class="matrix--text">array</span>',
      defaultValue: '<span class="alien--text">[]</span>',
      description: 'Список выводимых элементов',
      children: [
        {
          nameProp: '<span class="rocky--text">[:idValue]</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Индификатор записи',
        },
        {
          nameProp: '<span class="rocky--text">[:titleValue]</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
          description: 'Титл меню',
        },
        {
          nameProp: '<span class="rocky--text">disabled</span>',
          typeData: '<span class="matrix--text">boolean</span>',
          description: 'Блокировка пункта таба',
        },
        {
          nameProp: '<span class="rocky--text">optionsBulb</span>',
          typeData: '<span class="matrix--text">BulbOption</span>',
          description: 'Входные параметры компонента Bulb, за исключение [\'size\' | \'isBlock\' | \'isShape\']',
        },
      ],
    },
    {
      nameProp: '<span class="rocky--text">type</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"default"</span>',
      description: 'Тип представления компонента',
    },
    {
      nameProp: '<span class="rocky--text">idValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"id"</span>',
      description: 'Названия свойства из объекта списка для определения по уникальному идентификатору',
    },
    {
      nameProp: '<span class="rocky--text">titleValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"title"</span>',
      description: 'Названия свойства из объекта списка для определения отображение титла',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
  ]

  model = null

  items = [
    {
      id: 1,
      title: 'Title1',
    },
    {
      id: 2,
      title: 'Title2',
      disabled: true,
    },
    {
      id: 3,
      title: 'Title3',
    },
    {
      id: 4,
      title: 'Title4',
    },
    {
      id: 5,
      title: 'Title5',
    },
    {
      id: 6,
      title: 'Title6',
    },
    {
      id: 7,
      title: 'Title7',
      optionsBulb: {
        title: '99+',
        color: 'matrix',
      },
    },
    {
      id: 8,
      title: 'Title8',
      optionsBulb: {
        title: '99+',
        color: 'fargo',
      },
    },
    {
      id: 9,
      title: 'Title9',
    },
    {
      id: 10,
      title: 'Title10',
    },
    {
      id: 11,
      title: 'Title11',
    },
    {
      id: 12,
      title: 'Title12',
    },
    {
      id: 13,
      title: 'Title13',
    },
    {
      id: 14,
      title: 'Title14',
    },
    {
      id: 15,
      title: 'Title15',
    },
    {
      id: 16,
      title: 'Title16',
    },
    {
      id: 17,
      title: 'Title17',
    },
    {
      id: 18,
      title: 'Title18',
    },
    {
      id: 19,
      title: 'Title19',
    },
    {
      id: 20,
      title: 'Title20',
    },
    {
      id: 21,
      title: 'Title21',
    },
    {
      id: 22,
      title: 'Title22',
    },
    {
      id: 23,
      title: 'Title23',
    },
    {
      id: 24,
      title: 'Title24',
    },
    {
      id: 25,
      title: 'Title25',
    },
    {
      id: 26,
      title: 'Title26',
    },
    {
      id: 27,
      title: 'Title27',
    },
    {
      id: 28,
      title: 'Title28',
    },
    {
      id: 29,
      title: 'Title29',
    },
    {
      id: 30,
      title: 'Title30',
    },
  ]

  props = {
    type: 'default',
  }

  dataType = [
    {
      id: 'default',
      title: 'default',
    },
    {
      id: 'rounded',
      title: 'rounded',
    },
  ]

  get codeView() {
    return generateCodeToString('tabs', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
