<template>
  <div class="modal">
    <r-row class="mb-8">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 4, narrow: 4 }"
        :offset="{ widest: 2, wide: 2, middle: 1, narrow: 1 }"
      >
        <r-icon
          class="modal__icon"
          icon="selected"
          size="56"
          fill="matrix"
        />
        <div class="camembert mb-4">Заголовок в форме вопроса или утверждения</div>
        <div class="parmigiano troy--text">
          Функциональность решения покрывает практически все задачи, выполняемые оперативным
          персоналом крупных промышленных объектов, связанных с опасным производством: от
          формирования рабочих сменных бригад, подготовки расписания их работы до составления
          маршрутов обходов, выполнения, анализа их результатов и ведения многочисленных оперативных журналов</div>
      </r-col>
    </r-row>
    <div
        id="RModal--actions">

      <r-row class="mb-6">
        <r-col
          :cols="{ widest: 8, wide: 8, middle: 4, narrow: 4 }"
          :offset="{ widest: 2, wide: 2, middle: 1, narrow: 1 }"
        >

          <r-row gap-hidden class="mb-6">
            <r-col
              :cols="{ widest: 6, wide: 6, middle: 3, narrow: 3 }"
            >
              <r-button
                width="wide"
                type="secondary"
                title="Отменить"
                @click="closeModal('Ответ кнопки `Отменить`')"
              />
            </r-col>
            <r-col
              :cols="{ widest: 6, wide: 6, middle: 3, narrow: 3 }"
            >
              <r-button
                width="wide"
                title="Подтвердить"
                @click="closeModal('Ответ кнопки `Подтвердить`')"
              />
            </r-col>
          </r-row>
        </r-col>
      </r-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ModalPage extends Vue {
  closeModal(ret) {
    this.$rir.modal.close(ret, false);
  }
}
</script>

<style lang="scss" scoped>
.modal{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  &__icon{
    position: absolute;
    top: -80px
  }
}
</style>
