<template>
  <component-page>
    <template #description>
      Сетка
    </template>
    <template #component>
      <r-row
        v-for="(row, indexRow) in rows"
        :key="indexRow"
        v-bind="row.props"
        style="border: 1px solid var(--rir-alien)">
        <r-col
          v-for="(col, indexCol) in row.cols"
          :key="indexCol"
          v-bind="col.props"
          style="
          border: 1px solid var(--rir-harakiri);
          background-color: var(--rir-seven);
          text-align: center">
          {{ indexCol + 1 }}
        </r-col>
      </r-row>
    </template>
    <template #props>
      <r-row
        v-for="(row, indexRow) in rows"
        :key="indexRow"
        gap-hidden>
        <r-col
          :cols="{widest: 12, wide: 12, middle: 6, narrow: 6}"
        >
          <span class="bryndza">Row {{ indexRow + 1 }}</span>
        </r-col>
        <r-col
          v-for="(value, propName) in row.props"
          :key="propName"
          :cols="{widest: 12, wide: 12, middle: 6, narrow: 6}"
        >
          <r-row gap-hidden>
            <template
              v-if="propName === 'rowGap'">
              <r-col
                :cols="{widest: 12, wide: 12, middle: 6, narrow: 6}"
              >
                <span class="bryndza">{{ propName }}</span>
              </r-col>
              <r-col
                v-for="(value, breakpoint) in row.props[propName]"
                :key="breakpoint"
                :cols="{widest: 3, wide: 3, middle: 3, narrow: 3}"
              >
                <div class="sulguni mb-2">{{ breakpoint }}</div>
                <r-input-number
                  :min="1"
                  :max="99"
                  v-model="row.props[propName][breakpoint]"
                />
              </r-col>
            </template>
            <r-col
              :cols="{widest: 12, wide: 12, middle: 6, narrow: 6}"
              v-else>
              <r-switcher
                :title="propName"
                v-model="row.props[propName]"
              />
            </r-col>
          </r-row>
        </r-col>

        <r-col
          v-for="(col, indexCol) in row.cols"
          :key="indexCol"
          :cols="{widest: 12, wide: 12, middle: 6, narrow: 6}"
        >
          <r-row gap-hidden>
            <r-col
              :cols="{widest: 12, wide: 12, middle: 6, narrow: 6}"
            >
              <span class="bryndza">Col {{ indexCol + 1 }}</span>
            </r-col>
            <r-col
              v-for="(value, propName) in col.props"
              :key="propName"
              :cols="{widest: 12, wide: 12, middle: 6, narrow: 6}"
            >
              <r-row gap-hidden>
                <template
                  v-if="propName === 'cols'">
                  <r-col
                    :cols="{widest: 12, wide: 12, middle: 6, narrow: 6}"
                  >
                    <span class="bryndza">{{ propName }}</span>
                  </r-col>
                  <r-col
                    v-for="(value, breakpoint) in col.props[propName]"
                    :key="breakpoint"
                    :cols="{widest: 3, wide: 3, middle: 3, narrow: 3}"
                  >
                    <div class="sulguni mb-2">{{ breakpoint }}</div>
                    <r-input-number
                      :min="1"
                      :max="['middle', 'narrow'].includes(breakpoint) ? 6 : 12"
                      v-model="col.props[propName][breakpoint]"
                    />
                  </r-col>
                </template>
                <template
                  v-else-if="propName === 'offset'">
                  <r-col
                    :cols="{widest: 12, wide: 12, middle: 6, narrow: 6}"
                  >
                    <span class="bryndza">{{ propName }}</span>
                  </r-col>
                  <r-col
                    v-for="(value, breakpoint) in col.props[propName]"
                    :key="breakpoint"
                    :cols="{widest: 3, wide: 3, middle: 3, narrow: 3}"
                  >
                    <div class="sulguni mb-2">{{ breakpoint }}</div>
                    <r-input-number
                      :min="0"
                      :max="['middle', 'narrow'].includes(breakpoint) ? 6 : 12"
                      v-model="col.props[propName][breakpoint]"
                    />
                  </r-col>
                </template>
                <r-col
                  :cols="{widest: 12, wide: 12, middle: 6, narrow: 6}"
                  v-else>
                  <r-switcher
                    :title="propName"
                    v-model="col.props[propName]"
                  />
                </r-col>
              </r-row>
            </r-col>

          </r-row>
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props Row</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsPropsRow"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Props Col</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsPropsCol"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class GridSystem extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsPropsRow = [
    {
      nameProp: '<span class="rocky--text">rowGap</span>',
      typeData: '<span class="matrix--text">object</span>',
      defaultValue: '<span class="rocky--text">{ widest: 24, wide: 20, middle: 16, narrow: 12 }</span>',
      description: 'Межстрочный отступ при переносе колонок ',
    },
    {
      nameProp: '<span class="rocky--text">wrap</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">true</span>',
      description: 'Перенос колонок, если перенос отключен, то строка будет скролится при привышение колонок в сумме',
    },
    {
      nameProp: '<span class="rocky--text">snap</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">true</span>',
      description: 'Автопривязка скрола к точкам внутренего контента',
    },
    {
      nameProp: '<span class="rocky--text">gapHidden</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Убирает боковые отступы сетки',
    },
  ]

  itemsPropsCol = [
    {
      nameProp: '<span class="rocky--text">cols</span>',
      typeData: '<span class="matrix--text">object</span>',
      defaultValue: '<span class="rocky--text">{ widest: 1, wide: 1, middle: 1, narrow: 1 }</span>',
      description: 'Количество колонок занимает данная область',
    },
    {
      nameProp: '<span class="rocky--text">offset</span>',
      typeData: '<span class="matrix--text">object</span>',
      defaultValue: '<span class="rocky--text">{ widest: 0, wide: 0, middle: 0, narrow: 0 }</span>',
      description: 'Количество колонок отступить слева',
    },
    {
      nameProp: '<span class="rocky--text">isWidthContent</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Ширина колонки равна контенту внутри. Расчет сетки по колонкам отключена',
    },
    {
      nameProp: '<span class="rocky--text">isFullWidth</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Растягивает колонку на всю свободную ширину строки. Отрабатывает при параметре isWidthContent',
    },
  ]

  rows = [
    {
      props: {
        rowGap: {
          widest: 24, wide: 20, middle: 16, narrow: 12,
        },
        wrap: true,
        gapHidden: false,
      },
      cols: [
        {
          props: {
            cols: {
              widest: 1, wide: 1, middle: 1, narrow: 1,
            },
            offset: {
              widest: 0, wide: 0, middle: 0, narrow: 0,
            },
            isWidthContent: false,
            isFullWidth: false,
          },
        },
        {
          props: {
            cols: {
              widest: 1, wide: 1, middle: 1, narrow: 1,
            },
            offset: {
              widest: 0, wide: 0, middle: 0, narrow: 0,
            },
            isWidthContent: false,
            isFullWidth: false,
          },
        },
      ],
    },
  ]
}
</script>
