<template>
  <examples-block
    gitUrl="src/components/examples/Table/TablePopoverActionColumn.vue"
    title="Таблица с колонкой action (внутри RPopover с меню) и&nbsp;кликом по строке">
    <r-table
      :headers="headers"
      :items="items"
      @clickRow="testClickRow"
    >
      <template #action>
        <r-popover
          position="right-bottom"
          click-close
        >
          <template #activator="{ on }">
            <r-button-action
              icon="menu-context"
              v-on="on"
            />
          </template>

          <template #content>
            <div class="pa-1">
              <r-list-group>
                <template #content>
                  <r-list-item
                    icon="eye"
                    title="Посмотреть"
                  />
                  <r-list-item
                    icon="edit"
                    title="Редактировать"
                  />
                  <r-list-item
                    icon="delete"
                    title="Удалить"
                  />
                </template>
              </r-list-group>
            </div>
          </template>
        </r-popover>
      </template>
    </r-table>
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'TablePopoverActionColumn',
  components: { ExamplesBlock },
  data: () => ({
    headers: [
      {
        field: 'name',
        name: 'Имя',
      },
      {
        field: 'middleName',
        name: 'Отчество',
      },
      {
        field: 'firstName',
        name: 'Фамилия',
      },
      {
        field: 'action',
        name: '',
        slot: true,
        ignoreClickRow: true,
      },
    ],
    items: [
      {
        name: 'Иванов',
        middleName: 'Иван',
        firstName: 'Иванович',
      },
      {
        name: 'Иванов',
        middleName: 'Иван',
        firstName: 'Иванович',
      },
      {
        name: 'Иванов',
        middleName: 'Иван',
        firstName: 'Иванович',
      },
      {
        name: 'Иванов',
        middleName: 'Иван',
        firstName: 'Иванович',
      },
      {
        name: 'Иванов',
        middleName: 'Иван',
        firstName: 'Иванович',
      },
    ],
  }),
  methods: {
    testClickRow() {
      console.log('Клик по строке');
    },
  },
};
</script>
