<template>
  <component-page :code-view="codeView">
    <template #description>
      Интерактивный элемент списка
    </template>
    <template #component>
      <r-list-group>
        <template #content>
          <r-list-item
            v-for="n in 4"
            :key="n"
            v-model="model"
            v-bind="props"
            :title="`${val[n-1].title} ${n}`"
            :sub-title="val[n-1].subTitle"
            :icon="val[n-1].icon"
            :group="val[n-1].group"
            :val="val[n-1]"
            />
        </template>
      </r-list-group>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="Model is Array"
          v-model="modelIsArray" />
        <r-switcher
          title="disabled"
          v-model="props.disabled" />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false" label="Title" v-model="props.title" />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false" label="SubTitle" v-model="props.subTitle" />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false" typeInput="number" label="CountLine" v-model="props.countLine" />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Icon"
            :icon="props.icon"
            v-model="props.icon"
            :items="dataIcons"
            is-search
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Color"
            v-model="props.iconFill"
            :items="dataColor"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Slots</div>
      <r-table
        :headers="headersSlots"
        :items="itemsSlots"
      >
        <template #nameSlot="{ item }">
          <div v-html="item.nameSlot" />
        </template>
      </r-table>
    </template>
    <template #examples>
      <popover-selected-badge />
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';
import PopoverSelectedBadge from '@/components/examples/Popover/PopoverSelectedBadge.vue';

@Component({
  components: { PopoverSelectedBadge, ComponentPage },
})
export default class ListItem extends Vue {
  model = null

  get val() {
    return [
      {
        id: 1,
        title: this.props.title,
        icon: this.props.icon,
        iconFill: this.props.iconFill,
        subTitle: this.props.subTitle,
        group: 'group1',
      },
      {
        id: 2,
        title: this.props.title,
        icon: this.props.icon,
        iconFill: this.props.iconFill,
        subTitle: this.props.subTitle,
        group: 'group2',
      },
      {
        id: 3,
        title: this.props.title,
        icon: this.props.icon,
        iconFill: this.props.iconFill,
        subTitle: this.props.subTitle,
        group: 'group1',
      },
      {
        id: 4,
        title: this.props.title,
        icon: this.props.icon,
        iconFill: this.props.iconFill,
        subTitle: this.props.subTitle,
        group: 'group2',
      },
    ];
  }

  modelIsArray = false

  groups = [
    {
      id: 'group1',
      name: 'Group 1',
    },
    {
      id: 'group2',
      name: 'Group 2',
    },
  ]

  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersSlots = [
    {
      field: 'nameSlot',
      name: 'Наименование слота',
      description: 'Наименование слота в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">any</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Модель компонента [Array, Boolean, String, Object, Number]. Если тип данных Array, автоматически определяется мультивыбор',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Текст контента компонента',
    },
    {
      nameProp: '<span class="rocky--text">subTitle</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Дополнительное описание',
    },
    {
      nameProp: '<span class="rocky--text">icon</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Иконка компонента при одиночном выборе',
    },
    {
      nameProp: '<span class="rocky--text">iconFill</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">"harakiri"</span>',
      description: 'Цвет иконки',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Блокировка компонента',
    },
    {
      nameProp: '<span class="rocky--text">val</span>',
      typeData: '<span class="matrix--text">any</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Значение которое запишется в модель или вернется эвентом @input[boolean, object, string, number]',
    },
    {
      nameProp: '<span class="rocky--text">returnObject</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'При val с типом Object, будет возвращаться полностью объект, а не его индификатор из параметра "idValue"',
    },
    {
      nameProp: '<span class="rocky--text">idValue</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"id"</span>',
      description: 'Параметр для определение в объектах уникального ключа',
    },
    {
      nameProp: '<span class="rocky--text">group</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="lebowski--text">undefined</span>',
      description: 'Параметр для определение к какой группе относится элемент в рамках группировки в компонент RListGroup',
    },
    {
      nameProp: '<span class="rocky--text">countLine</span>',
      typeData: '<span class="matrix--text">string | number</span>',
      defaultValue: '<span class="lebowski--text">1</span>',
      description: 'Параметр для определение строки в блоке для входного параметра Title',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается событие клика по элементу и возвращает модель',
    },
  ]

  itemsSlots = [
    {
      nameSlot: '<span class="rocky--text">content</span>',
      description: 'Область встраивания в блок title',
    },
  ]

  props = {
    disabled: false,
    title: 'Title',
    icon: null,
    iconFill: 'harakiri',
    subTitle: null,
    countLine: 1,
  }

  dataColor = [
    {
      id: 'harakiri',
      title: 'harakiri',
    },
    {
      id: 'rocky',
      title: 'rocky',
    },
    {
      id: 'matrix',
      title: 'matrix',
    },
    {
      id: 'lebowski',
      title: 'lebowski',
    },
    {
      id: 'fargo',
      title: 'fargo',
    },
    {
      id: 'gentlemen',
      title: 'gentlemen',
    },
    {
      id: 'fightclub',
      title: 'fightclub',
    },
    {
      id: 'aquaman',
      title: 'aquaman',
    },
    // {
    //   id: 'goodfellas',
    //   title: 'goodfellas',
    // },
  ]

  get codeView() {
    return generateCodeToString('list-item', this.props);
  }

  @Watch('modelIsArray')
  changeSize(val) {
    if (val) {
      this.model = [];
    } else {
      this.model = null;
    }
  }

  dataIcons = [
    {
      id: null,
      title: 'Без иконки',
      icon: 'block',
    },
  ]

  created() {
    this.dataIcons = this.dataIcons.concat(getIconName().map((el) => ({
      id: el,
      title: el,
      icon: el,
    })));
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
