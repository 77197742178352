<template>
  <component-page :code-view="codeView">
    <template #description>

    </template>
    <template #component>
      <r-paginator v-bind="props" v-model="model"/>
    </template>
    <template #props>
      <r-row
        gap-hidden>
        <div style="display: flex; flex-direction: column">
          <span class="mb-2 troy--text parmigiano">countPage</span>
          <r-input-number v-model="props.countPage"/>
        </div>
        <div style="display: flex; flex-direction: column">
          <span class="mb-2 troy--text parmigiano">totalPage</span>
          <r-input-number :min="7" v-model="props.totalPage"/>
        </div>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-javascript';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Paginator extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Модель выбранного значения',
    },
    {
      nameProp: '<span class="rocky--text">countPage</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Количество страниц',
    },
    {
      nameProp: '<span class="rocky--text">totalPage</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="fargo--text">Обязательный параметр</span>',
      description: 'Количество выводеных нумераций (в учет берутся первы и последня + троиточии), минимальное число принимается 7 (указанное ниже игнорируется)',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
  ]

  model = 1

  props = {
    countPage: 9,
    totalPage: 7,
  }

  get codeView() {
    return generateCodeToString('paginator', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
