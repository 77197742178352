<template>
  <examples-block
    gitUrl="src/components/examples/RadioButton/RadioButtonBoolean.vue"
    title="RRadioButton - props value type Boolean">
    <r-radio-button
      title="true"
      :value="model"
      @input="changeVal"
      class="mr-5"
    />
    <r-radio-button
      title="false"
      :value="!model"
      @input="changeVal"
    />
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'RadioButtonBoolean',
  components: {
    ExamplesBlock,
  },
  data() {
    return {
      model: false,
    };
  },
  methods: {
    changeVal() {
      this.model = !this.model;
    },
  },
};
</script>
