// @ts-ignore
import icons from '../data/icons.json';

export function getIconName(size = '_16') {
  return [...new Set(
    Object.keys(icons)
      // .filter((el: string) => el.includes(size) && !el.includes('arrow-'))
      .filter((el: string) => el.includes(size))
      .map((el: string) => el.split('_')[0]),
  )];
}
