import Vue from 'vue';
// import Rir from '@rir/vue-library';
// import '@rir/vue-library/dist/RirLib.css';
import Rir from '@rir/vue-library/src';

Vue.use(Rir);

// fixPointGrid: 'narrow',
// gridsName: 'dashboard',

// const layout = localStorage.getItem('layout');
// const gridsName = layout === 'Dashboard' ? 'dashboard' : 'default';
const options: any = {
  // typeCssGlobal: 'body',
  gridsName: 'dashboard',
};
export default new Rir(options);
