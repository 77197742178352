<template>
  <component-page>
    <template #description>
      Компонент для запуска воспроизведения видео
    </template>
    <template #component>
      <div style="width: 548px">

        <r-player-video
          v-bind="props"
        />
      </div>
    </template>
    <template #props>
      <r-row
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }"
        >
          <r-select
            label="Size"
            v-model="props.size"
            :items="sizeData"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class PlayerVideo extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">url</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="fargo--text">Обязательное свойство</span>',
      description: 'Ссылка на ведофайл',
    },
    {
      nameProp: '<span class="rocky--text">screenPreview</span>',
      typeData: '<span class="matrix--text">number</span>',
      defaultValue: '<span class="alien--text">0</span>',
      description: 'Секунда видео для превью',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">"atlentea"</span>',
      description: 'Размер видеоплеера',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">fullscreen</span>',
      description: 'Эвент для после открытия или закрытия fullscreen режима, возвращает состояние плеера',
    },
  ]

  props = {
    url: '/video/1.mp4',
    size: 'atlentea',
    screenPreview: 64,
  }

  sizeData = [
    {
      id: 'atlentea',
      title: 'atlentea',
    },
    {
      id: 'kleokhareia',
      title: 'kleokhareia',
    },
  ]
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
