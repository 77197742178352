<template>
  <component-page :code-view="codeView">
    <template #description>
      Виджет для отображения профиля пользователя
    </template>
    <template #component>
      <r-avatar
        :key="props.size"
        v-bind="props"
        :img="imgView"
        v-on="onEvent"
      />
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="isEventClick"
          v-model="isClick"
        />
        <r-switcher
          title="photo"
          v-model="isImg"
        />
        <r-switcher
          title="isEdit"
          v-model="props.isEdit"
        />
        <r-switcher
          title="disabled"
          v-model="props.disabled"
        />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Size"
            v-model="props.size"
            :items="dataSize"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Color"
            v-model="props.color"
            :items="dataColor"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-input
            :is-clear="false"
            label="Title"
            v-model="props.title"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';

@Component({
  components: { ComponentPage },
})
export default class Avatar extends Vue {
  test() {
    console.log('click test');
  }

  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"acotali"</span>',
      description: 'Размер компонента',
    },
    {
      nameProp: '<span class="rocky--text">color</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"nixilei"</span>',
      description: 'Цвет компонента',
    },
    {
      nameProp: '<span class="rocky--text">img</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Прямая ссылка на изображение',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Наименование (из названия берется первая буква и преобразуется в заглавную)',
    },
    {
      nameProp: '<span class="rocky--text">isEdit</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Режим редактирования',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Блокировка компонента',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается событие клика по компоненту',
    },
    {
      nameEvent: '<span class="rocky--text">click-edit</span>',
      description: 'Возвращается событие клика по значку редактированию',
    },
  ]

  isImg = false

  isClick = false

  props = {
    size: 'acotali',
    color: 'nixilei',
    title: null,
    isEdit: false,
    disabled: false,
  }

  dataSize = [
    {
      id: 'acotali',
      title: 'acotali',
    },
    {
      id: 'dexane',
      title: 'dexane',
    },
    {
      id: 'othresise',
      title: 'othresise',
    },
    {
      id: 'aethelsa',
      title: 'aethelsa',
    },
    {
      id: 'themise',
      title: 'themise',
    },
    {
      id: 'thesipha',
      title: 'thesipha',
    },
  ]

  dataColor = [
    {
      id: 'nixilei',
      title: 'nixilei',
    },
    {
      id: 'rhanis',
      title: 'rhanis',
    },
  ]

  get onEvent() {
    const on: any = {};
    if (this.isClick) {
      on.click = this.test;
    }
    return on;
  }

  get imgView() {
    return this.isImg ? '/img/test.jpg' : null;
  }

  get codeView() {
    return generateCodeToString('avatar', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
