<template>
  <div class="modal">
    <r-row class="mb-8">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 4, narrow: 4 }"
        :offset="{ widest: 2, wide: 2, middle: 1, narrow: 1 }"
      >
        <r-icon
          class="modal__icon"
          icon="selected"
          size="56"
          fill="matrix"
        />
        <div class="camembert mb-4">Модальное окно с подтверждением закрытия</div>
        <div class="parmigiano troy--text">Модальное окно можно закрыть, только после подтверждения</div>
      </r-col>
    </r-row>
  </div>
</template>

<script>

import ConfirmModal from '@/components/examples/Modal/modalView/ConfirmModal.vue';

export default {
  name: 'CloseEventInComponentModal',
  methods: {
    async closeModal() {
      const res = await this.$rir.modal.open(ConfirmModal, {}, {
        isCloseEventInitComponent: true,
      });
      if (res) {
        this.$rir.modal.close(null, true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  &__icon{
    position: absolute;
    top: -80px
  }
}
</style>
