<template>
  <examples-block
    gitUrl="src/components/examples/Uploader/UploaderAndFile.vue"
    title="Связка компонентов RUploader и RFile при серверной загрузки">
    <div
      style="width: 100%"
    >
      <r-uploader
        v-model="files"
        title='Загрузите файлы'
        sub-title="Производим загрузку файлов на сервер"
        :accept="['application/pdf', 'image/jpeg']"
        :callback="uploadFile"
      />
      <div
        class="mt-6"
        style="display: grid; grid-template-columns: repeat(auto-fill, minmax(33%, 1fr)); gap: 24px;">
        <r-file
          v-for="(file, index) in files"
          :key="index"
          :file="file"
          :title="`File ${index}`"
          sub-title="информация"
        />
      </div>
    </div>
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'UploaderAndFile',
  components: { ExamplesBlock },
  data: () => ({
    files: [],
  }),
  methods: {
    uploadFile(file) {
      return new Promise((resolve) => {
        setTimeout(() => {
          // Пример с отработкой локально (в данной реализации ошибки обрабатываются только на тип файла, максимальное количество и максимальный вес всех файлов)
          this.files.push({
            type: file.file.type,
            size: file.file.size,
            name: file.file.name,
            url: URL.createObjectURL(file.file),
          });
          resolve();
          // или отдаем ответ компоненту (в данном случае, компоненту надо указать была ли ошибка при загрузке и описать причину ошибки
          // const error = !Math.round(Math.random());
          // resolve({
          //   success: !error,
          //   error,
          //   errorMessage: error ? 'Ошибка загрузки файла' : null,
          //   file: {
          //     type: file.file.type,
          //     size: file.file.size,
          //     name: file.file.name,
          //     url: URL.createObjectURL(file.file),
          //   },
          // });
        }, 2000);
      });
    },
  },
};
</script>
