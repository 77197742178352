<template>
  <component-page :code-view="codeView">
    <template #description>
      Кнопка без фона. Может совершать действия или открывать контекстные окна PopOver и
      ModalWindow.
    </template>
    <template #component>
      <r-button-action
        v-model="model"
        v-bind="props"/>
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="isArrow"
          v-model="props.isArrow"
        />
        <r-switcher
          title="disabled"
          v-model="props.disabled"
        />
        <r-switcher
          title="notShorter"
          v-model="props.notShorter"
        />
      </r-row>
      <r-row
        gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Icon"
            is-search
            :items="dataIcons"
            v-model="props.icon"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-select
              label="Size"
              :items="sizeType"
              v-model="props.size"
              />
          </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-select
              label="Color"
              :items="colorType"
              v-model="props.color"
              />
          </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <r-input
            :is-clear="false"
              label="Title"
              v-model="props.title"
              />
          </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class ButtonAction extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">value</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Модель состояния при использования стрелки',
    },
    {
      nameProp: '<span class="rocky--text">title</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Текст в теле компонента',
    },
    {
      nameProp: '<span class="rocky--text">icon</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="alien--text">undefined</span>',
      description: 'Иконки слева от текста',
    },
    {
      nameProp: '<span class="rocky--text">color</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"rocky"</span>',
      description: 'Цвет компонента',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"sulguni"</span>',
      description: 'Размер компонента',
    },
    {
      nameProp: '<span class="rocky--text">isArrow</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Вывод стрелки справа от текста с анимацией поворота по параметру "value"',
    },
    {
      nameProp: '<span class="rocky--text">disabled</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Блокировка компонента',
    },
    {
      nameProp: '<span class="rocky--text">notShorter</span>',
      typeData: '<span class="matrix--text">boolean</span>',
      defaultValue: '<span class="fightclub--text">false</span>',
      description: 'Отключение использования RShorter внутри компонента',
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">input</span>',
      description: 'Возвращается состояние модели компонента',
    },
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается событие клика по компоненту',
    },
  ]

  model = false

  props = {
    title: 'Button text',
    icon: null,
    isArrow: false,
    disabled: false,
    notShorter: false,
    color: 'rocky',
    size: 'sulguni',
  }

  sizeType = [
    {
      id: 'sulguni',
      title: 'sulguni',
    },
    {
      id: 'briscola',
      title: 'briscola',
    },
  ]

  colorType = [
    {
      id: 'rocky',
      title: 'rocky',
    },
    {
      id: 'matrix',
      title: 'matrix',
    },
    {
      id: 'lebowski',
      title: 'lebowski',
    },
    {
      id: 'fargo',
      title: 'fargo',
    },
    {
      id: 'gentlemen',
      title: 'gentlemen',
    },
    {
      id: 'fightclub',
      title: 'fightclub',
    },
    {
      id: 'aquaman',
      title: 'aquaman',
    },
    // {
    //   id: 'goodfellas',
    //   title: 'goodfellas',
    // },
    {
      id: 'troy',
      title: 'troy',
    },
    {
      id: 'harakiri',
      title: 'harakiri',
    },
    {
      id: 'metropolis',
      title: 'metropolis',
    },
  ]

  dataIcons = [
    {
      id: null,
      title: 'Без иконки',
      icon: 'block',
    },
  ]

  get codeView() {
    return generateCodeToString('button-action', this.props);
  }

  created() {
    this.dataIcons = this.dataIcons.concat(getIconName().map((el) => ({
      id: el,
      title: el,
      icon: el,
    })));
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
