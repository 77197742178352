<template>

  <div class="pt-2 component">
    <r-row class="mb-2">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="troy--text parmigiano">
          Токены цветов условно разделены на&nbsp;контентные, фоновые,
          тени и&nbsp;цвета популярных соцсетей, также поддерживаются темы Default и&nbsp;Dark
        </span>
      </r-col>
    </r-row>
    <r-row>
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="troy--text parmigiano">
          При помощи вспомогательных классов можно управлять цветом фона
          и текста, <nobr class="lebowski--text">.{name-color}--<nobr class="gentlemen--text">text</nobr></nobr> — изменение цвета
          текста внутри блока, <nobr class="lebowski--text">.{name-color}--<nobr class="gentlemen--text">bg</nobr></nobr> — изменение цвета фона у блока,
          <nobr class="lebowski--text">.{name-color}--<nobr class="gentlemen--text">[fill | stroke]</nobr></nobr> — изменение цвета у svg элементов.
        </span>
      </r-col>
    </r-row>
    <r-row>
      <r-col
        :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        <section
          v-for="(types, key) in dataColor"
          :key="key"
        >
          <div class="mt-8 mb-4 taleggio colors__title">{{ key }}</div>
          <div class="colors">
            <div
              v-for="(color, keyColor) in types"
              :key="keyColor"
              class="color"
              :class="[
                `${keyColor}--bg`,
                `${keyColor}--text`,
                {
                  inversion: inversionColors.includes(keyColor),
                  white: whiteColors.includes(keyColor),
                  black: blackColors.includes(keyColor),
                  border: borderColors.includes(keyColor)
                }
              ]"
            >
              <div class="color__name mb-2">
                <span class="feta">{{keyColor}}</span>
                <span class="feta">{{color[0].value}}</span>
              </div>
              <div
                v-for="(state, index) in color"
                :key="index"
                v-show="index > 0"
                class="color__name mb-1">
                <span class="caprino">{{keyColor}}{{state.state}}</span>
                <span class="caprino">{{state.value}}</span>
              </div>
            </div>
          </div>
        </section>
      </r-col>
    </r-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import colors from '@/data/colors.json';

@Component
export default class Palette extends Vue {
  colors = colors

  whiteColors = [
    'black',
  ]

  blackColors = [
    'white',
    'memento',
  ]

  inversionColors = [
    'harakiri',
    'troy',
    'metropolis',
    'rocky',
    'matrix',
    'lebowski',
    'fargo',
    'fightclub',
    'alien',
    'gentlemen',
    'drive',
    'snatch',
    'indiana',
    'pulpfiction',
    'seven',
    'aquaman',
    'shershaah',
    'goodfellas',
    'mummy',
  ]

  borderColors = [
    'trainspotting',
    'oldboy',
    'terminator',
  ]

  get dataColor() {
    return this.colors[this.$rir.theme];
  }
}
</script>

<style scoped lang="scss">
.colors{
  display: grid;
  gap: 3px;
  grid-template-columns: repeat(auto-fill, 233px);
  grid-auto-rows: 120px;
  &__title{
    text-transform: capitalize;
  }
}
.color{
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  background-clip: padding-box;
  &__name{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.border{
    border: 1px solid var(--rir-django);
  }
  &.inversion{
    & * {
      color: var(--rir-amelie);
    }
  }
  &.white{
    & * {
      color: var(--rir-white);
    }
  }
  &.black{
    & * {
      color: var(--rir-black);
    }
  }
}
</style>
