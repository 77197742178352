<template>
  <examples-block
    gitUrl="src/components/examples/Modal/modalView/UploaderModalComponent.vue"
    title="Модальное окно с компонентом RUploader"
    description="Загрузка файлов из под модального окна и обработка ошибки при загрузки компонентом"
  >
    <r-button
      title="Отрыть модальное окно"
      @click="openModal"
    />
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';
import UploaderModalComponent from './modalView/UploaderModalComponent.vue';

export default {
  name: 'UploaderModal',
  components: { ExamplesBlock },
  methods: {
    openModal() {
      this.$rir.modal.open(UploaderModalComponent, {});
    },
  },
};
</script>
