<template>
  <component-page :code-view="codeView">
    <template #description>
      Компонент для отображения галереи изображений и видео
    </template>
    <template #component>
      <r-media-gallery
        :key="props.size"
        v-bind="props"
      />
    </template>
    <template #props>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Size"
            v-model="props.size"
            :items="dataSize"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
      <div class="taleggio mb-8">Events</div>
      <r-table
        class="mb-8"
        :headers="headersEvents"
        :items="itemsEvents"
      >
        <template #nameEvent="{ item }">
          <div  v-html="item.nameEvent"/>
        </template>
        <template #description="{ item }">
          <div  v-html="item.description"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class MediaGallery extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  headersEvents = [
    {
      field: 'nameEvent',
      name: 'Наименование события',
      description: 'Наименование события в компоненте',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="matrix--text">"mellaniphe"</span>',
      description: 'Тип отображения компонента',
    },
    {
      nameProp: '<span class="rocky--text">items</span>',
      typeData: '<span class="fargo--text">ItemMediaGallery</span>',
      defaultValue: '<span class="rocky--text">[]</span>',
      description: 'Массив данных по файлам',
      children: [
        {
          nameProp: '<span class="alien--text">type</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательное поле</span>',
          description: 'Формат MIME-тип файла. (Принимаются image/* и video/*)',
        },
        {
          nameProp: '<span class="alien--text">img</span>',
          typeData: '<span class="matrix--text">string</span>',
          defaultValue: '<span class="fargo--text">Обязательное поле</span>',
          description: 'Прямая ссылка на превью',
        },
      ],
    },
  ]

  itemsEvents = [
    {
      nameEvent: '<span class="rocky--text">click</span>',
      description: 'Возвращается событие нажатие на элемент и возвращает объект элемента',
    },
    {
      nameEvent: '<span class="rocky--text">more</span>',
      description: 'Возвращается событие нажатие на крайний элемент в блоке, если элементов больше, чем выведено и возвращает объект элемента',
    },
  ]

  props = {
    size: 'steliope',
    items: [
      {
        type: 'image/png',
        url: '/img/test.jpg',
      },
      {
        type: 'video/mp4',
        url: '/img/test.jpg',
      },
      {
        type: 'image/png',
        url: '/img/test.jpg',
      },
      {
        type: 'image/png',
        url: '/img/test.jpg',
      },
      {
        type: 'video/mp4',
        url: '/img/test.jpg',
      },
      {
        type: 'video/mp4',
        url: '/img/test.jpg',
      },
    ],
  }

  dataSize = [
    {
      id: 'mellaniphe',
      title: 'mellaniphe',
    },
    {
      id: 'steliope',
      title: 'steliope',
    },
    {
      id: 'perseis',
      title: 'perseis',
    },
  ]

  get codeView() {
    return generateCodeToString('media-gallery', this.props);
  }
}
</script>
