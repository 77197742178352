<template>
  <section class="mt-2">
    <r-row class="mb-2">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="troy--text parmigiano">
          &laquo;Голос&raquo;&nbsp;&mdash; основная гарнитура, используемая в&nbsp;цифровых
          продуктах. Этот универсальный закрытый гротеск хорошо подходит для чтения длинных текстов
          с&nbsp;экрана и&nbsp;рекомендуется для сайтов государственных и&nbsp;социальных
          сервисов.</span>
      </r-col>
    </r-row>
    <r-row class="mb-4">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="troy--text parmigiano">
         При помощи вспомогательных классов можно изменять свойства текста в&nbsp;точках остановок,
          <nobr class="lebowski--text">.{name-typography}</nobr> — применяется в любых брейкпоинтах,
          <nobr class="lebowski--text">.{name-typography}<nobr class="gentlemen--text">--{breakpoint}</nobr></nobr> —
          применяется в <span class="gentlemen--text">narrow</span>,
          <span class="gentlemen--text">middle</span>, <span class="gentlemen--text">wide</span> или
          <span class="gentlemen--text">widest</span>.</span>
      </r-col>
    </r-row>
    <r-row class="mb-8">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <r-button-action title="Скачать шрифт «Голос»" icon="load" @click="download"/>
      </r-col>
    </r-row>
    <r-row>
      <r-col
        :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
        <r-row
          class="mb-4"
          gap-hidden>
          <r-col
            :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
            <span class="lebowski--text feta">Заголовки</span>
          </r-col>
        </r-row>
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text primo mb-2">primo</span>
              <span class="brie">72/72 Bold (700)</span>
            </div>
          </r-col>
        </r-row>
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text gouda mb-2">gouda</span>
              <span class="brie">56/56 Bold (700)</span>
            </div>
          </r-col>
        </r-row>
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text cheddar mb-2">cheddar</span>
              <span class="brie">40/44 Bold (700)</span>
            </div>
          </r-col>
        </r-row>
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text camembert mb-2">camembert</span>
              <span class="brie">32/36 Bold (700)</span>
            </div>
          </r-col>
        </r-row>
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text ricotta mb-2">ricotta</span>
              <span class="feta">28/32 Bold (700)</span>
            </div>
          </r-col>
        </r-row>
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text burrata mb-2">burrata</span>
              <span class="feta">24/28 Bold (700)</span>
            </div>
          </r-col>
        </r-row>
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text taleggio mb-2">taleggio</span>
              <span class="feta">20/24 Bold (700)</span>
            </div>
          </r-col>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text comte mb-2">comte</span>
              <span class="feta">20/28 Bold (700)</span>
            </div>
          </r-col>
        </r-row>
        <r-row
          gap-hidden>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text bryndza mb-2">bryndza</span>
              <span class="feta">16/20 Bold (700)</span>
            </div>
          </r-col>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text tulum mb-2">tulum</span>
              <span class="feta">16/24 Bold (700)</span>
            </div>
          </r-col>
        </r-row>
      </r-col>
      <r-col
        :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
        <!--Основной наборный текст-->
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
            <span class="lebowski--text feta">Основной наборный текст</span>
          </r-col>
        </r-row>
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text mascarpone mb-2">mascarpone</span>
              <span class="feta">20/28 Regular (400)</span>
            </div>
          </r-col>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text brie mb-2">brie</span>
              <span class="feta">20/24 Regular (400)</span>
            </div>
          </r-col>
        </r-row>
        <r-row
          class="mb-12"
          gap-hidden>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text parmigiano mb-2">parmigiano</span>
              <span class="feta">16/24 Regular (400)</span>
            </div>
          </r-col>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text feta mb-2">feta</span>
              <span class="feta">16/20 Regular (400)</span>
            </div>
          </r-col>
        </r-row>
        <!--Дополнительный текст-->
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
            <span class="lebowski--text feta">Дополнительный текст</span>
          </r-col>
        </r-row>
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text mozzarella mb-2">mozzarella</span>
              <span class="feta">13/16 Regular (400)</span>
            </div>
          </r-col>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text roquefort mb-2">roquefort</span>
              <span class="feta">13/16 Bold  (700)</span>
            </div>
          </r-col>
        </r-row>
        <r-row
          class="mb-12"
          gap-hidden>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text zamorano mb-2">zamorano</span>
              <span class="feta">13/20 Regular (400)</span>
            </div>
          </r-col>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text caprino mb-2">caprino</span>
              <span class="feta">10/12 Regular (400)</span>
            </div>
          </r-col>
        </r-row>
        <!--Контролы-->
        <r-row
          class="mb-4"
          gap-hidden>
          <r-col
            :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
            <span class="lebowski--text feta">Контролы</span>
          </r-col>
        </r-row>
        <r-row
          class="mb-6"
          gap-hidden>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text maasdam mb-2">maasdam</span>
              <span class="feta">20/24 Medium (500)</span>
            </div>
          </r-col>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text sulguni mb-2">sulguni</span>
              <span class="feta">16/20 Medium (500)</span>
            </div>
          </r-col>
        </r-row>
        <r-row
          gap-hidden>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text briscola mb-2">briscola</span>
              <span class="feta">13/16 Medium (500)</span>
            </div>
          </r-col>
          <r-col
            :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
            <div class="page__typo">
              <span class="rocky--text edam mb-2">edam</span>
              <span class="feta">10/12 Medium (500)</span>
            </div>
          </r-col>
        </r-row>
      </r-col>
    </r-row>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Typographic extends Vue {
  download() {
    window.open('/docs/golos.7z', '_blank');
  }
}
</script>

<style lang="scss" scoped>
.page{
  &__typo{
    display: flex;
    flex-direction: column;
  }
}
</style>
