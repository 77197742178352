<template>
  <component-page :code-view="codeView" colorPreviewBlock="django">
    <template #description>
      Кнопки для видеоплеера
    </template>
    <template #component>
        <r-button-player
          :key="props.size"
          v-bind="props"
        />
    </template>
    <template #props>
      <r-row
        class="mb-6"
        gap-hidden>
        <r-switcher
          title="disabled"
          v-model="props.disabled"
        />
      </r-row>
      <r-row gap-hidden>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Type"
            v-model="props.type"
            :items="dataType"
          />
        </r-col>
        <r-col
          :cols="{ widest: 6, wide: 6, middle: 6, narrow: 6 }">
          <r-select
            label="Size"
            v-model="props.size"
            :items="dataSize"
          />
        </r-col>
      </r-row>
    </template>
    <template #api>
      <div class="taleggio mb-8">Props</div>
      <r-table
        class="mb-8"
        :headers="headersProps"
        :items="itemsProps"
      >
        <template #nameProp="{ item }">
          <div  v-html="item.nameProp"/>
        </template>
        <template #typeData="{ item }">
          <div  v-html="item.typeData"/>
        </template>
        <template #defaultValue="{ item }">
          <div  v-html="item.defaultValue"/>
        </template>
      </r-table>
    </template>
  </component-page>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { generateCodeToString } from '@/utils/markupCode';
import ComponentPage from '@/layouts/ComponentPage.vue';
import { getIconName } from '@/utils/icons';

@Component({
  components: { ComponentPage },
})
export default class ButtonPlayer extends Vue {
  headersProps = [
    {
      field: 'nameProp',
      name: 'Название параметра',
      description: 'Наименование входного параметра',
      slot: true,
    },
    {
      field: 'typeData',
      name: 'Тип данных',
      description: 'Применение входного параметра с типом данных',
      slot: true,
    },
    {
      field: 'defaultValue',
      name: 'Значение по умолчанию',
      slot: true,
    },
    {
      field: 'description',
      name: 'Описание',
      description: 'Описание входного параметра',
    },
  ]

  itemsProps = [
    {
      nameProp: '<span class="rocky--text">type</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="rocky--text">"play"</span>',
      description: 'Тип кнопки "play" или "pause"',
    },
    {
      nameProp: '<span class="rocky--text">size</span>',
      typeData: '<span class="matrix--text">string</span>',
      defaultValue: '<span class="rocky--text">"kerleise"</span>',
      description: 'Размер компонента',
    },
  ]

  props = {
    type: 'play',
    size: 'kerleise',
    disabled: false,
  }

  dataSize = [
    {
      id: 'kerleise',
      title: 'kerleise',
    },
    {
      id: 'crotheise',
      title: 'crotheise',
    },
    {
      id: 'samilea',
      title: 'samilea',
    },
  ]

  dataType = [
    {
      id: 'play',
      title: 'play',
    },
    {
      id: 'pause',
      title: 'pause',
    },
  ];

  get codeView() {
    return generateCodeToString('button-player', this.props);
  }
}
</script>

<style lang="scss" scoped>
.component{
  &__view{
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
