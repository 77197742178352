<template>
  <r-row class="examples--row">
    <r-col
      :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }"
    >
      <div class="examples">
        <div class="examples__header">
          <div class="examples__title">
            <span class="taleggio" v-html="title" />
            <span class="troy--text parmigiano mt-2" v-if="description">{{ description }}</span>
          </div>
          <div class="examples__links">
            <r-button-link title="Figma" color="seven" @click="getFigma" v-if="!!figmaUrl"/>
            <r-button-link title="GitLab" color="snatch" @click="getGitLab" v-if="!!gitUrl"/>
          </div>
        </div>
        <div
          :class="[`${bgColor}--bg`]"
          class="d-flex align-items-center justify-content-center bana pa-5" style="flex-wrap: wrap">
          <slot />
        </div>
      </div>
    </r-col>
  </r-row>
</template>
<script>
export default {
  data: () => ({
    branch: process.env.VUE_APP_BRANCH,
  }),
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    figmaUrl: {
      type: String,
    },
    gitUrl: {
      type: String,
    },
    bgColor: {
      type: String,
      default: 'amelie',
    },
  },
  methods: {
    getFigma() {
      window.open(this.figmaUrl, '_blank');
    },
    getGitLab() {
      window.open(`https://gitlab.rusatom.dev/rosatom/core/design/ds-previewer/-/blob/${this.branch}/${this.gitUrl}`, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.examples{
  position: relative;
  &--row{
    margin-top: 32px;
    &:first-child{
      margin-top: 0;
    }
  }
  &__links{
    top: 0;
    right: 0;
    background-color: var(--rir-trainspotting);
    display: flex;
    align-items: center;
    align-self: end;
    & > a {
      display: inline-block;
      cursor: pointer;
      &:first-child{
        margin-right: 20px;
      }
    }
  }
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__title{
    display: flex;
    flex-direction: column;
    flex: 0 1 621px;
  }
}
</style>
