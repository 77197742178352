<template>

  <div class="pt-2 component">
    <r-row class="mb-8">
      <r-col
        :cols="{ widest: 8, wide: 8, middle: 6, narrow: 6 }">
        <span class="parmigiano troy--text">
          Это ширины при которых вёрстка перестраивается сразу и&nbsp;полностью&nbsp;&mdash;
          адаптируется, а&nbsp;не&nbsp;постепенно как при масштабировании
        </span>
      </r-col>
    </r-row>
    <r-row
    class="mb-8">
      <r-col
        :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        <span class="rocky--text cheddar mr-10">600</span>
        <span class="rocky--text cheddar mr-10">1024</span>
        <span class="rocky--text cheddar">1360</span>
      </r-col>
    </r-row>
    <r-row class="mb-10">
      <r-col
        :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
        <div class="breakpoint__block">
          <span class="harakiri--text burrata mb-2">
            narrow
          </span>
          <span class="rocky--text feta mb-4">
            360—599
          </span>
          <span class="harakiri--text parmigiano mb-6">
            Мобильные приложения и версии сайтов для мобильных телефонов
          </span>
          <svg width="56" height="106" viewBox="0 0 56 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0H48C52.4183 0 56 3.58172 56 8V98C56 102.418 52.4183 106 48 106H8C3.58172 106 0 102.418 0 98V8Z" fill="var(--rir-inception)"/>
            <path d="M8 3C5.23858 3 3 5.23858 3 8V98C3 100.761 5.23858 103 8 103H48C50.7614 103 53 100.761 53 98V8C53 5.23858 50.7614 3 48 3H38V4C38 5.10457 37.1046 6 36 6H20C18.8954 6 18 5.10457 18 4V3H8Z" fill="var(--rir-amelie)"/>
            <path d="M7 27C7 25.3431 8.34315 24 10 24H44C45.6569 24 47 25.3431 47 27C47 28.6569 45.6569 30 44 30H10C8.34315 30 7 28.6569 7 27Z" fill="var(--rir-inception)"/>
            <path d="M7 37C7 35.3431 8.34315 34 10 34H36C37.6569 34 39 35.3431 39 37C39 38.6569 37.6569 40 36 40H10C8.34315 40 7 38.6569 7 37Z" fill="var(--rir-inception)"/>
            <path d="M10 44C8.34315 44 7 45.3431 7 47C7 48.6569 8.34315 50 10 50H20C21.6569 50 23 48.6569 23 47C23 45.3431 21.6569 44 20 44H10Z" fill="var(--rir-inception)"/>
            <path d="M7 57C7 55.3431 8.34315 54 10 54H28C29.6569 54 31 55.3431 31 57C31 58.6569 29.6569 60 28 60H10C8.34315 60 7 58.6569 7 57Z" fill="var(--rir-inception)"/>
            <path d="M13 99C16.3137 99 19 96.3137 19 93C19 89.6863 16.3137 87 13 87C9.68629 87 7 89.6863 7 93C7 96.3137 9.68629 99 13 99Z" fill="var(--rir-inception)"/>
          </svg>
        </div>
      </r-col>
      <r-col
        :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
        <div class="breakpoint__block">
          <span class="harakiri--text burrata mb-2">
            middle
          </span>
          <span class="rocky--text feta mb-4">
            600—1023
          </span>
          <span class="harakiri--text parmigiano mb-6">
            Приложения и версии сайтов для планшетов
          </span>
          <svg width="176" height="120" viewBox="0 0 176 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0H168C172.418 0 176 3.58172 176 8V112C176 116.418 172.418 120 168 120H8C3.58173 120 0 116.418 0 112V8Z" fill="var(--rir-inception)"/>
            <path d="M6 8C6 6.89543 6.89543 6 8 6H168C169.105 6 170 6.89543 170 8V112C170 113.105 169.105 114 168 114H8C6.89543 114 6 113.105 6 112V8Z" fill="var(--rir-amelie)"/>
            <path d="M14 17C14 15.3431 15.3431 14 17 14H51C52.6569 14 54 15.3431 54 17C54 18.6569 52.6569 20 51 20H17C15.3431 20 14 18.6569 14 17Z" fill="var(--rir-inception)"/>
            <path d="M14 29C14 27.3431 15.3431 26 17 26H43C44.6569 26 46 27.3431 46 29C46 30.6569 44.6569 32 43 32H17C15.3431 32 14 30.6569 14 29Z" fill="var(--rir-inception)"/>
            <path d="M14 41C14 39.3431 15.3431 38 17 38H27C28.6569 38 30 39.3431 30 41C30 42.6569 28.6569 44 27 44H17C15.3431 44 14 42.6569 14 41Z" fill="var(--rir-inception)"/>
            <path d="M14 53C14 51.3431 15.3431 50 17 50H35C36.6569 50 38 51.3431 38 53C38 54.6569 36.6569 56 35 56H17C15.3431 56 14 54.6569 14 53Z" fill="var(--rir-inception)"/>
            <path d="M26 100C26 103.314 23.3137 106 20 106C16.6863 106 14 103.314 14 100C14 96.6863 16.6863 94 20 94C23.3137 94 26 96.6863 26 100Z" fill="var(--rir-inception)"/>
          </svg>
        </div>
      </r-col>
    </r-row>
    <r-row>
      <r-col
        :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
        <div class="breakpoint__block">
          <span class="harakiri--text burrata mb-2">
            wide
          </span>
          <span class="rocky--text feta mb-4">
            1024—1359
          </span>
          <span class="harakiri--text parmigiano mb-6">
            Десктопные версии сайтов и приложений, большие планшеты
          </span>
          <svg width="248" height="142" viewBox="0 0 248 142" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8C16 3.58172 19.5817 0 24 0H223.996C228.416 0 231.998 3.58436 231.996 8.00433L231.926 136H248V138C248 140.209 246.209 142 244 142H4C1.79086 142 0 140.209 0 138V136H16V8Z" fill="var(--rir-inception)"/>
            <path d="M24 4C21.7909 4 20 5.79086 20 8V130H228V8C228 5.79086 226.209 4 224 4H134V6C134 7.10457 133.105 8 132 8H116C114.895 8 114 7.10457 114 6V4H24Z" fill="var(--rir-amelie)"/>
            <path d="M82 4C78.6863 4 76 6.68629 76 10V130H228V8C228 5.79086 226.209 4 224 4H134V6C134 7.10457 133.105 8 132 8H116C114.895 8 114 7.10457 114 6V4H82Z" fill="var(--rir-trainspotting)"/>
            <path d="M28 19C28 17.3431 29.3431 16 31 16H65C66.6569 16 68 17.3431 68 19C68 20.6569 66.6569 22 65 22H31C29.3431 22 28 20.6569 28 19Z" fill="var(--rir-inception)"/>
            <path d="M28 31C28 29.3431 29.3431 28 31 28H57C58.6569 28 60 29.3431 60 31C60 32.6569 58.6569 34 57 34H31C29.3431 34 28 32.6569 28 31Z" fill="var(--rir-inception)"/>
            <path d="M28 43C28 41.3431 29.3431 40 31 40H41C42.6569 40 44 41.3431 44 43C44 44.6569 42.6569 46 41 46H31C29.3431 46 28 44.6569 28 43Z" fill="var(--rir-inception)"/>
            <path d="M28 55C28 53.3431 29.3431 52 31 52H49C50.6569 52 52 53.3431 52 55C52 56.6569 50.6569 58 49 58H31C29.3431 58 28 56.6569 28 55Z" fill="var(--rir-inception)"/>
            <path d="M40 110C40 113.314 37.3137 116 34 116C30.6863 116 28 113.314 28 110C28 106.686 30.6863 104 34 104C37.3137 104 40 106.686 40 110Z" fill="var(--rir-inception)"/>
            <path d="M84 20C84 17.7909 85.7909 16 88 16H216C218.209 16 220 17.7909 220 20V63C220 65.2091 218.209 67 216 67H88C85.7909 67 84 65.2091 84 63V20Z" fill="var(--rir-amelie)"/>
            <path d="M84 75C84 72.7909 85.7909 71 88 71H216C218.209 71 220 72.7909 220 75V118C220 120.209 218.209 122 216 122H88C85.7909 122 84 120.209 84 118V75Z" fill="var(--rir-amelie)"/>
          </svg>
        </div>
      </r-col>
      <r-col
        :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
        <div class="breakpoint__block">
          <span class="harakiri--text burrata mb-2">
            widest
          </span>
          <span class="rocky--text feta mb-4">
            1360—etc
          </span>
          <span class="harakiri--text parmigiano mb-6">
            Дашборды и прикладное програмное обеспечение
          </span>
          <svg width="298" height="180" viewBox="0 0 298 180" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0H290C294.418 0 298 3.58172 298 8V172C298 176.418 294.418 180 290 180H8C3.58172 180 0 176.418 0 172V8Z" fill="var(--rir-inception)"/>
            <path d="M4 8C4 5.79086 5.79086 4 8 4H290C292.209 4 294 5.79086 294 8V172C294 174.209 292.209 176 290 176H8C5.79086 176 4 174.209 4 172V8Z" fill="var(--rir-amelie)"/>
            <path d="M12 15C12 13.3431 13.3431 12 15 12H49C50.6569 12 52 13.3431 52 15C52 16.6569 50.6569 18 49 18H15C13.3431 18 12 16.6569 12 15Z" fill="var(--rir-inception)"/>
            <path d="M12 27C12 25.3431 13.3431 24 15 24H41C42.6569 24 44 25.3431 44 27C44 28.6569 42.6569 30 41 30H15C13.3431 30 12 28.6569 12 27Z" fill="var(--rir-inception)"/>
            <path d="M12 39C12 37.3431 13.3431 36 15 36H25C26.6569 36 28 37.3431 28 39C28 40.6569 26.6569 42 25 42H15C13.3431 42 12 40.6569 12 39Z" fill="var(--rir-inception)"/>
            <path d="M12 51C12 49.3431 13.3431 48 15 48H33C34.6569 48 36 49.3431 36 51C36 52.6569 34.6569 54 33 54H15C13.3431 54 12 52.6569 12 51Z" fill="var(--rir-inception)"/>
            <path d="M24 162C24 165.314 21.3137 168 18 168C14.6863 168 12 165.314 12 162C12 158.686 14.6863 156 18 156C21.3137 156 24 158.686 24 162Z" fill="var(--rir-inception)"/>
            <path d="M76 14C76 8.47716 80.4772 4 86 4H290C292.209 4 294 5.79086 294 8V172C294 174.209 292.209 176 290 176H76V14Z" fill="var(--rir-trainspotting)"/>
            <path d="M84 16C84 13.7909 85.7909 12 88 12H179C181.209 12 183 13.7909 183 16V78C183 80.2091 181.209 82 179 82H88C85.7909 82 84 80.2091 84 78V16Z" fill="var(--rir-amelie)"/>
            <path d="M187 16C187 13.7909 188.791 12 191 12H282C284.209 12 286 13.7909 286 16V56C286 58.2091 284.209 60 282 60H191C188.791 60 187 58.2091 187 56V16Z" fill="var(--rir-amelie)"/>
            <path d="M187 68C187 65.7909 188.791 64 191 64H282C284.209 64 286 65.7909 286 68V148C286 150.209 284.209 152 282 152H191C188.791 152 187 150.209 187 148V68Z" fill="var(--rir-amelie)"/>
            <path d="M84 90C84 87.7909 85.7909 86 88 86H179C181.209 86 183 87.7909 183 90V130C183 132.209 181.209 134 179 134H88C85.7909 134 84 132.209 84 130V90Z" fill="var(--rir-amelie)"/>
            <path d="M84 142C84 139.791 85.7909 138 88 138H179C181.209 138 183 139.791 183 142V176H84V142Z" fill="var(--rir-amelie)"/>
          </svg>
        </div>
      </r-col>
    </r-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Breakpoints extends Vue {}
</script>

<style scoped lang="scss">
.breakpoint{
  &__block{
    display: flex;
    flex-direction: column;
  }
}
</style>
