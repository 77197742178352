export function generateCodeToString(name: string, props: any, slots?: any): string {
  let isActiveSlot = '';
  if (slots) {
    for (const key in slots) {
      if (slots[key]) {
        isActiveSlot += `<template #${key} />\r\n`;
      }
    }
  }
  // @ts-ignore
  const propsString: string = Object
    .keys(props)
    .reduce((acc: string, el: string): string => {
      // eslint-disable-next-line no-param-reassign
      acc += `\n      ${typeof props[el] !== 'string' ? ':' : ''}${el}="${Array.isArray(props[el]) ? `[${props[el]}]` : props[el]}"`;
      return acc;
    }, '');
  return `<r-${name} ${propsString}
${isActiveSlot ? '>' : '/>'}
  ${isActiveSlot || ''}${isActiveSlot ? `</r-${name}>` : ''}`;
}
