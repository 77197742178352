<template>
  <examples-block
    gitUrl="src/components/examples/Modal/modalView/SettingModalContent.vue"
    title="Несколько модальных окон c настройками отключение закрытия и состоянием возврата назад">
    <r-button
      title="Отрыть модальное окно"
      @click="openModal"
    />
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';
import SettingModalContent from './modalView/SettingModalContent.vue';

export default {
  name: 'SettingModal',
  components: { ExamplesBlock },
  methods: {
    openModal() {
      this.$rir.modal.open(SettingModalContent, {});
    },
  },
};
</script>
