<template>
  <examples-block
    gitUrl="src/components/examples/BreadCrumbs/BreadCrumbsDisabledElement.vue"
    title="Хлебные крошки с неактивными элементами">
    <div style="width: 300px;">
      <r-bread-crumbs
        :items="items"
      />
    </div>
  </examples-block>
</template>

<script>
import ExamplesBlock from '@/layouts/ExamplesBlock.vue';

export default {
  name: 'BreadCrumbsDisabledElement',
  components: { ExamplesBlock },
  data: () => ({
    items: [
      {
        title: 'title1',
      },
      {
        title: 'title2',
      },
      {
        title: 'title3',
        active: false,
      },
      {
        title: 'title4',
      },
      {
        title: 'title5',
        active: false,
      },
      {
        title: 'title6',
        active: false,
      },
      {
        title: 'title7',
      },
      {
        title: 'title8',
      },
    ],
  }),
};
</script>
